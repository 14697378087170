import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import {
  TradingDealDefinitionCustomNewPage,
  TradingDealDefinitionCustomDetailPage,
} from '../components/pages/Trading/DealDefinition/ViewPage.custom';
import { TradingMasterSpendCustomPage } from '../components/pages/Trading/MasterSpend/IndexPage.custom';
import { TradingAnnualTargetCustomPage } from '../components/pages/Trading/AnnualTarget/IndexPage.custom';
import { BulkPanelPage } from '../components/pages/custom/BulkPanelPage';
import { OpenDealBookPage, OpenDealBookMeetingPage } from '../components/pages/custom/OpenDealBookPage';
import { ReporterPage } from '../components/pages/custom/ReporterPage';
import { IntegrationAuditMessagePage } from '../components/pages/custom/IntegrationAuditMessagePage';
import { SecurityUserDetailCustomPage } from '../components/pages/admin/User/ViewPage.custom';
import { TradingDealDefinitionInstanceCustomNewPage, TradingDealDefinitionInstanceCustomDuplicatePage, TradingDealDefinitionInstanceCustomDetailPage } from '../components/pages/Trading/DealDefinitionInstance/ViewPage.custom';
import { IndexForecastPage } from '../components/pages/custom/IndexForecast';
import { IndexRevenuePeriodPage } from '../components/pages/custom/IndexRevenuePeriod';
export const createRoutesCustom = (isAuthenticated) => (
  <>
    <Route
      path="/Trading/DealDefinition/NewDeal"
      element={isAuthenticated ? <TradingDealDefinitionCustomNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DealDefinition/:id"
      element={isAuthenticated ? <TradingDealDefinitionCustomDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/MasterSpend/Index"
      element={isAuthenticated ? <TradingMasterSpendCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualTarget/Index"
      element={isAuthenticated ? <TradingAnnualTargetCustomPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/DealDefinitionInstance/BulkPanel" element={isAuthenticated ? <BulkPanelPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealBook/OpenDealBook" element={isAuthenticated ? <OpenDealBookPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealBook/OpenDealBookMeeting" element={isAuthenticated ? <OpenDealBookMeetingPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealDefinitionInstance/New" element={isAuthenticated ? <TradingDealDefinitionInstanceCustomNewPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealDefinitionInstance/DuplicateDeal" element={isAuthenticated ? <TradingDealDefinitionInstanceCustomDuplicatePage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealDefinitionInstance/:id" element={isAuthenticated ? <TradingDealDefinitionInstanceCustomDetailPage /> : <Navigate to="/" />} />
    <Route path="/Trading/DealBook/Reporter" element={isAuthenticated ? <ReporterPage /> : <Navigate to="/" />} />
    <Route path="/Integration/AuditMessage/Index" element={isAuthenticated ? <IntegrationAuditMessagePage /> : <Navigate to="/" />} />
    <Route path="/Framework/User/:id" element={isAuthenticated ? <SecurityUserDetailCustomPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/RevenueForecastStream/IndexForecast"
      element={isAuthenticated ? <IndexForecastPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/IndexForecast/:id"
      element={isAuthenticated ? <IndexForecastPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/IndexRevenuePeriod"
      element={isAuthenticated ? <IndexRevenuePeriodPage /> : <Navigate to="/" />}
    />
  </>
);
