import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingMasterClientSetNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingMasterClientSet>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterClientSet');
  let updatedState: ITradingMasterClientSet = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.FiscalYear = new Date().getFullYear().toString();
            getPreferredDetails("Finance", "ManagementType").then((result) => {
              const resultmanagementtype: any[] = result.Value;
              if (resultmanagementtype && resultmanagementtype.length > 0)
                updatedState = { ...updatedState, ManagementTypeId: resultmanagementtype[0].Id, ManagementTypeDisplayValue: resultmanagementtype[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterClientSet.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterClientSet/View.js"], "initNewTradingMasterClientSet", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MasterClientSet" className="controls-container default block">
              <h3>Create a New <b>SMBC Client Sets</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterClientSetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterClientSetLabelBlockedCode">Blocked</label>
				<input id="MasterClientSetCheckBlockedCode" defaultChecked name="MasterClientSetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MasterClientSetEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MasterClientSetEnglishName','MasterClientSetNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MasterClientSetNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MasterClientSetEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MasterClientSetEnglishShortName','MasterClientSetNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MasterClientSetNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ManagementTypeId">SMBC</label><select className="" id="MasterClientSetManagementTypeId" name="ManagementTypeId" defaultValue={state.ManagementTypeId}   required data-required-msg="SMBC is required"  >{state.ManagementTypeId && <option value={state.ManagementTypeId}>{state.ManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="MasterClientSetSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId}   required data-required-msg="Sales House is required"  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="MasterClientSetFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterClientSetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMasterClientSet">
                <div className="left">
                  <button id="cancelMasterClientSetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterClientSetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingMasterClientSet {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  ManagementTypeId?: string,
  ManagementTypeDisplayValue?: string,
  SalesHouseId?: string,
  SalesHouseDisplayValue?: string,
  FiscalYear?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function TradingMasterClientSetDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingMasterClientSet>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterClientSet');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "MasterClientSet", id).then(result => {
        setState(result.Value[0] as ITradingMasterClientSet);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterClientSet.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterClientSet/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterClientSetClient.en.js"
        ,"Areas/Trading/Scripts/MasterClientSetClient/View.js"
        ], "initDetailTradingMasterClientSet", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingMasterClientSetState'] = (data) => {
    setState(data.Value[0] as ITradingMasterClientSet);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterClientSet" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>SMBC Client Sets: <span>{state.Display}</span><span id="MasterClientSetToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.ManagementTypeId && <span className='element'><label>SMBC: <label className="strong">{state.ManagementTypeDisplayValue}</label></label></span>}
                  {state.SalesHouseId && <span className='element'><label>Sales House: <label className="strong">{state.SalesHouseDisplayValue}</label></label></span>}
                  <span className='element'><label>Fiscal Year: <label className="strong">{state.FiscalYear}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterClientSetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterClientSetLabelBlockedCode">Blocked</label>
				<input id="MasterClientSetCheckBlockedCode" defaultChecked name="MasterClientSetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MasterClientSetEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MasterClientSetEnglishName','MasterClientSetNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MasterClientSetNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MasterClientSetEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MasterClientSetEnglishShortName','MasterClientSetNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MasterClientSetNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ManagementTypeId">SMBC</label><select className="" id="MasterClientSetManagementTypeId" name="ManagementTypeId" defaultValue={state.ManagementTypeId}   required data-required-msg="SMBC is required"  >{state.ManagementTypeId && <option value={state.ManagementTypeId}>{state.ManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="MasterClientSetSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId}   required data-required-msg="Sales House is required"  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="MasterClientSetFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterClientSetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MasterClientSetSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MasterClientSetSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMasterClientSet">
                <div className="left">
                  <button id="cancelMasterClientSetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterClientSetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationMasterClientSetMasterClientSetClient">
              <div className="k-block grid-details">
                <h2>Clients</h2>
                <div id="MasterClientSetClientGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


