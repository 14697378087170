import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ClientsClientSetPage } from '../components/pages/Clients/ClientSet/IndexPage';
import { ClientsClientSetNewPage, ClientsClientSetDetailPage } from '../components/pages/Clients/ClientSet/ViewPage';
import { ClientsClientPage } from '../components/pages/Clients/Client/IndexPage';
import { ClientsClientNewPage, ClientsClientDetailPage } from '../components/pages/Clients/Client/ViewPage';
import { GeneralCountryPage } from '../components/pages/General/Country/IndexPage';
import { GeneralCountryNewPage, GeneralCountryDetailPage } from '../components/pages/General/Country/ViewPage';
import { CompaniesCompanyPage } from '../components/pages/Companies/Company/IndexPage';
import { CompaniesCompanyNewPage, CompaniesCompanyDetailPage } from '../components/pages/Companies/Company/ViewPage';
import { MediaTypesMediaTypePage } from '../components/pages/MediaTypes/MediaType/IndexPage';
import { MediaTypesMediaTypeNewPage, MediaTypesMediaTypeDetailPage } from '../components/pages/MediaTypes/MediaType/ViewPage';
import { GeneralRegionPage } from '../components/pages/General/Region/IndexPage';
import { GeneralRegionNewPage, GeneralRegionDetailPage } from '../components/pages/General/Region/ViewPage';
import { MediaVehiclesMediaVehiclePage } from '../components/pages/MediaVehicles/MediaVehicle/IndexPage';
import { MediaVehiclesMediaVehicleNewPage, MediaVehiclesMediaVehicleDetailPage } from '../components/pages/MediaVehicles/MediaVehicle/ViewPage';
import { MediaVehiclesSalesHousePage } from '../components/pages/MediaVehicles/SalesHouse/IndexPage';
import { MediaVehiclesSalesHouseNewPage, MediaVehiclesSalesHouseDetailPage } from '../components/pages/MediaVehicles/SalesHouse/ViewPage';
import { PricingCostModelPage } from '../components/pages/Pricing/CostModel/IndexPage';
import { PricingCostModelNewPage, PricingCostModelDetailPage } from '../components/pages/Pricing/CostModel/ViewPage';
import { TradingMasterMediaCategoryPage } from '../components/pages/Trading/MasterMediaCategory/IndexPage';
import { TradingMasterMediaCategoryNewPage, TradingMasterMediaCategoryDetailPage } from '../components/pages/Trading/MasterMediaCategory/ViewPage';
import { TradingMasterClientSetPage } from '../components/pages/Trading/MasterClientSet/IndexPage';
import { TradingMasterClientSetNewPage, TradingMasterClientSetDetailPage } from '../components/pages/Trading/MasterClientSet/ViewPage';
import { TradingMasterSpendPage } from '../components/pages/Trading/MasterSpend/IndexPage';
import { TradingMasterSpendNewPage, TradingMasterSpendDetailPage } from '../components/pages/Trading/MasterSpend/ViewPage';
import { TradingCampaignPage } from '../components/pages/Trading/Campaign/IndexPage';
import { TradingCampaignNewPage, TradingCampaignDetailPage } from '../components/pages/Trading/Campaign/ViewPage';
import { TradingDealDefinitionInstancePage } from '../components/pages/Trading/DealDefinitionInstance/IndexPage';
//import { TradingDealDefinitionInstanceNewPage, TradingDealDefinitionInstanceDetailPage } from '../components/pages/Trading/DealDefinitionInstance/ViewPage';
import { TradingDealBookPage } from '../components/pages/Trading/DealBook/IndexPage';
import { TradingDealBookNewPage, TradingDealBookDetailPage } from '../components/pages/Trading/DealBook/ViewPage';
import { TradingDealBookMeetingPage } from '../components/pages/Trading/DealBookMeeting/IndexPage';
//import { TradingDealBookMeetingNewPage, TradingDealBookMeetingDetailPage } from '../components/pages/Trading/DealBookMeeting/ViewPage';
import { TradingDealDefinitionPage } from '../components/pages/Trading/DealDefinition/IndexPage';
import { TradingDealDefinitionNewPage, TradingDealDefinitionDetailPage } from '../components/pages/Trading/DealDefinition/ViewPage';
import { TradingDealForecastPage } from '../components/pages/Trading/DealForecast/IndexPage';
import { TradingDealForecastNewPage, TradingDealForecastDetailPage } from '../components/pages/Trading/DealForecast/ViewPage';
import { TradingAgencyProfilePeriodPage } from '../components/pages/Trading/AgencyProfilePeriod/IndexPage';
import { TradingAgencyProfilePeriodNewPage, TradingAgencyProfilePeriodDetailPage } from '../components/pages/Trading/AgencyProfilePeriod/ViewPage';
import { TradingRevenueForecastStreamPage } from '../components/pages/Trading/RevenueForecastStream/IndexPage';
import { TradingRevenueForecastStreamNewPage, TradingRevenueForecastStreamDetailPage } from '../components/pages/Trading/RevenueForecastStream/ViewPage';
import { ClientsMasterClientPage } from '../components/pages/Clients/MasterClient/IndexPage';
import { ClientsMasterClientNewPage, ClientsMasterClientDetailPage } from '../components/pages/Clients/MasterClient/ViewPage';
import { GeneralLanguagePage } from '../components/pages/General/Language/IndexPage';
import { GeneralLanguageNewPage, GeneralLanguageDetailPage } from '../components/pages/General/Language/ViewPage';
import { FinanceManagementTypePage } from '../components/pages/Finance/ManagementType/IndexPage';
import { FinanceManagementTypeNewPage, FinanceManagementTypeDetailPage } from '../components/pages/Finance/ManagementType/ViewPage';
import { GeneralCurrencyPage } from '../components/pages/General/Currency/IndexPage';
import { GeneralCurrencyNewPage, GeneralCurrencyDetailPage } from '../components/pages/General/Currency/ViewPage';
import { GeneralNationalityPage } from '../components/pages/General/Nationality/IndexPage';
import { GeneralNationalityNewPage, GeneralNationalityDetailPage } from '../components/pages/General/Nationality/ViewPage';
import { GeneralLocationPage } from '../components/pages/General/Location/IndexPage';
import { GeneralLocationNewPage, GeneralLocationDetailPage } from '../components/pages/General/Location/ViewPage';
import { MediaTypesBroadcastTypePage } from '../components/pages/MediaTypes/BroadcastType/IndexPage';
import { MediaTypesBroadcastTypeNewPage, MediaTypesBroadcastTypeDetailPage } from '../components/pages/MediaTypes/BroadcastType/ViewPage';
import { MediaTypesCampaignTypePage } from '../components/pages/MediaTypes/CampaignType/IndexPage';
import { MediaTypesCampaignTypeNewPage, MediaTypesCampaignTypeDetailPage } from '../components/pages/MediaTypes/CampaignType/ViewPage';
import { MediaTypesTargetPage } from '../components/pages/MediaTypes/Target/IndexPage';
import { MediaTypesTargetNewPage, MediaTypesTargetDetailPage } from '../components/pages/MediaTypes/Target/ViewPage';
import { MediaVehiclesBuyingRoutePage } from '../components/pages/MediaVehicles/BuyingRoute/IndexPage';
import { MediaVehiclesBuyingRouteNewPage, MediaVehiclesBuyingRouteDetailPage } from '../components/pages/MediaVehicles/BuyingRoute/ViewPage';
import { MediaVehiclesChannelPage } from '../components/pages/MediaVehicles/Channel/IndexPage';
import { MediaVehiclesChannelNewPage, MediaVehiclesChannelDetailPage } from '../components/pages/MediaVehicles/Channel/ViewPage';
import { MediaVehiclesChannelTypePage } from '../components/pages/MediaVehicles/ChannelType/IndexPage';
import { MediaVehiclesChannelTypeNewPage, MediaVehiclesChannelTypeDetailPage } from '../components/pages/MediaVehicles/ChannelType/ViewPage';
import { MediaVehiclesMediaVehicleTypePage } from '../components/pages/MediaVehicles/MediaVehicleType/IndexPage';
import { MediaVehiclesMediaVehicleTypeNewPage, MediaVehiclesMediaVehicleTypeDetailPage } from '../components/pages/MediaVehicles/MediaVehicleType/ViewPage';
import { MediaVehiclesMediaVehicleGroupPage } from '../components/pages/MediaVehicles/MediaVehicleGroup/IndexPage';
import { MediaVehiclesMediaVehicleGroupNewPage, MediaVehiclesMediaVehicleGroupDetailPage } from '../components/pages/MediaVehicles/MediaVehicleGroup/ViewPage';
import { TradingMasterBreakPage } from '../components/pages/Trading/MasterBreak/IndexPage';
import { TradingMasterBreakNewPage, TradingMasterBreakDetailPage } from '../components/pages/Trading/MasterBreak/ViewPage';
import { TradingDealActivityPage } from '../components/pages/Trading/DealActivity/IndexPage';
import { TradingDealActivityNewPage, TradingDealActivityDetailPage } from '../components/pages/Trading/DealActivity/ViewPage';
import { TradingDealCandidatePage } from '../components/pages/Trading/DealCandidate/IndexPage';
//import { TradingDealCandidateNewPage, TradingDealCandidateDetailPage } from '../components/pages/Trading/DealCandidate/ViewPage';
import { TradingAnnualTargetPage } from '../components/pages/Trading/AnnualTarget/IndexPage';
import { TradingAnnualTargetNewPage, TradingAnnualTargetDetailPage } from '../components/pages/Trading/AnnualTarget/ViewPage';
import { TradingRevenueStreamPage } from '../components/pages/Trading/RevenueStream/IndexPage';
import { TradingRevenueStreamNewPage, TradingRevenueStreamDetailPage } from '../components/pages/Trading/RevenueStream/ViewPage';

export const createRoutes = (isAuthenticated) => (
  <>
	  <Route path="/Clients/ClientSet/Index" element={isAuthenticated ? <ClientsClientSetPage /> : <Navigate to="/" />} />
	  <Route path="/Clients/ClientSet/New" element={isAuthenticated ? <ClientsClientSetNewPage /> : <Navigate to="/" />} />
		<Route path="/Clients/ClientSet/:id" element={isAuthenticated ? <ClientsClientSetDetailPage /> : <Navigate to="/" />} />
		<Route path="/Clients/Client/Index" element={isAuthenticated ? <ClientsClientPage /> : <Navigate to="/" />} />
		<Route path="/Clients/Client/New" element={isAuthenticated ? <ClientsClientNewPage /> : <Navigate to="/" />} />
		<Route path="/Clients/Client/:id" element={isAuthenticated ? <ClientsClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Country/Index" element={isAuthenticated ? <GeneralCountryPage /> : <Navigate to="/" />} />
	  <Route path="/General/Country/New" element={isAuthenticated ? <GeneralCountryNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Country/:id" element={isAuthenticated ? <GeneralCountryDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Companies/Company/Index" element={isAuthenticated ? <CompaniesCompanyPage /> : <Navigate to="/" />} />
	  <Route path="/Companies/Company/New" element={isAuthenticated ? <CompaniesCompanyNewPage /> : <Navigate to="/" />} />
	  <Route path="/Companies/Company/:id" element={isAuthenticated ? <CompaniesCompanyDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/MediaType/Index" element={isAuthenticated ? <MediaTypesMediaTypePage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/MediaType/New" element={isAuthenticated ? <MediaTypesMediaTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/MediaType/:id" element={isAuthenticated ? <MediaTypesMediaTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Region/Index" element={isAuthenticated ? <GeneralRegionPage /> : <Navigate to="/" />} />
	  <Route path="/General/Region/New" element={isAuthenticated ? <GeneralRegionNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Region/:id" element={isAuthenticated ? <GeneralRegionDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicle/Index" element={isAuthenticated ? <MediaVehiclesMediaVehiclePage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicle/New" element={isAuthenticated ? <MediaVehiclesMediaVehicleNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicle/:id" element={isAuthenticated ? <MediaVehiclesMediaVehicleDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/SalesHouse/Index" element={isAuthenticated ? <MediaVehiclesSalesHousePage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/SalesHouse/New" element={isAuthenticated ? <MediaVehiclesSalesHouseNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/SalesHouse/:id" element={isAuthenticated ? <MediaVehiclesSalesHouseDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Pricing/CostModel/Index" element={isAuthenticated ? <PricingCostModelPage /> : <Navigate to="/" />} />
	  <Route path="/Pricing/CostModel/New" element={isAuthenticated ? <PricingCostModelNewPage /> : <Navigate to="/" />} />
	  <Route path="/Pricing/CostModel/:id" element={isAuthenticated ? <PricingCostModelDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterMediaCategory/Index" element={isAuthenticated ? <TradingMasterMediaCategoryPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterMediaCategory/New" element={isAuthenticated ? <TradingMasterMediaCategoryNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterMediaCategory/:id" element={isAuthenticated ? <TradingMasterMediaCategoryDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientSet/Index" element={isAuthenticated ? <TradingMasterClientSetPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientSet/New" element={isAuthenticated ? <TradingMasterClientSetNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterClientSet/:id" element={isAuthenticated ? <TradingMasterClientSetDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterSpend/Index" element={isAuthenticated ? <TradingMasterSpendPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterSpend/New" element={isAuthenticated ? <TradingMasterSpendNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterSpend/:id" element={isAuthenticated ? <TradingMasterSpendDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/Campaign/Index" element={isAuthenticated ? <TradingCampaignPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/Campaign/New" element={isAuthenticated ? <TradingCampaignNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/Campaign/:id" element={isAuthenticated ? <TradingCampaignDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealDefinitionInstance/Index" element={isAuthenticated ? <TradingDealDefinitionInstancePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealBook/Index" element={isAuthenticated ? <TradingDealBookPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealBook/New" element={isAuthenticated ? <TradingDealBookNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealBook/:id" element={isAuthenticated ? <TradingDealBookDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealBookMeeting/Index" element={isAuthenticated ? <TradingDealBookMeetingPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealDefinition/Index" element={isAuthenticated ? <TradingDealDefinitionPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealDefinition/New" element={isAuthenticated ? <TradingDealDefinitionNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealDefinition/:id" element={isAuthenticated ? <TradingDealDefinitionDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/Index" element={isAuthenticated ? <TradingDealForecastPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/New" element={isAuthenticated ? <TradingDealForecastNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealForecast/:id" element={isAuthenticated ? <TradingDealForecastDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfilePeriod/Index" element={isAuthenticated ? <TradingAgencyProfilePeriodPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfilePeriod/New" element={isAuthenticated ? <TradingAgencyProfilePeriodNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfilePeriod/:id" element={isAuthenticated ? <TradingAgencyProfilePeriodDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecastStream/Index" element={isAuthenticated ? <TradingRevenueForecastStreamPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecastStream/New" element={isAuthenticated ? <TradingRevenueForecastStreamNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueForecastStream/:id" element={isAuthenticated ? <TradingRevenueForecastStreamDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Clients/MasterClient/Index" element={isAuthenticated ? <ClientsMasterClientPage /> : <Navigate to="/" />} />
	  <Route path="/Clients/MasterClient/New" element={isAuthenticated ? <ClientsMasterClientNewPage /> : <Navigate to="/" />} />
	  <Route path="/Clients/MasterClient/:id" element={isAuthenticated ? <ClientsMasterClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Language/Index" element={isAuthenticated ? <GeneralLanguagePage /> : <Navigate to="/" />} />
	  <Route path="/General/Language/New" element={isAuthenticated ? <GeneralLanguageNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Language/:id" element={isAuthenticated ? <GeneralLanguageDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Finance/ManagementType/Index" element={isAuthenticated ? <FinanceManagementTypePage /> : <Navigate to="/" />} />
	  <Route path="/Finance/ManagementType/New" element={isAuthenticated ? <FinanceManagementTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Finance/ManagementType/:id" element={isAuthenticated ? <FinanceManagementTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Currency/Index" element={isAuthenticated ? <GeneralCurrencyPage /> : <Navigate to="/" />} />
	  <Route path="/General/Currency/New" element={isAuthenticated ? <GeneralCurrencyNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Currency/:id" element={isAuthenticated ? <GeneralCurrencyDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Nationality/Index" element={isAuthenticated ? <GeneralNationalityPage /> : <Navigate to="/" />} />
	  <Route path="/General/Nationality/New" element={isAuthenticated ? <GeneralNationalityNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Nationality/:id" element={isAuthenticated ? <GeneralNationalityDetailPage /> : <Navigate to="/" />} />
	  <Route path="/General/Location/Index" element={isAuthenticated ? <GeneralLocationPage /> : <Navigate to="/" />} />
	  <Route path="/General/Location/New" element={isAuthenticated ? <GeneralLocationNewPage /> : <Navigate to="/" />} />
	  <Route path="/General/Location/:id" element={isAuthenticated ? <GeneralLocationDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/BroadcastType/Index" element={isAuthenticated ? <MediaTypesBroadcastTypePage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/BroadcastType/New" element={isAuthenticated ? <MediaTypesBroadcastTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/BroadcastType/:id" element={isAuthenticated ? <MediaTypesBroadcastTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/CampaignType/Index" element={isAuthenticated ? <MediaTypesCampaignTypePage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/CampaignType/New" element={isAuthenticated ? <MediaTypesCampaignTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/CampaignType/:id" element={isAuthenticated ? <MediaTypesCampaignTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/Target/Index" element={isAuthenticated ? <MediaTypesTargetPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/Target/New" element={isAuthenticated ? <MediaTypesTargetNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaTypes/Target/:id" element={isAuthenticated ? <MediaTypesTargetDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/BuyingRoute/Index" element={isAuthenticated ? <MediaVehiclesBuyingRoutePage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/BuyingRoute/New" element={isAuthenticated ? <MediaVehiclesBuyingRouteNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/BuyingRoute/:id" element={isAuthenticated ? <MediaVehiclesBuyingRouteDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/Channel/Index" element={isAuthenticated ? <MediaVehiclesChannelPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/Channel/New" element={isAuthenticated ? <MediaVehiclesChannelNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/Channel/:id" element={isAuthenticated ? <MediaVehiclesChannelDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/ChannelType/Index" element={isAuthenticated ? <MediaVehiclesChannelTypePage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/ChannelType/New" element={isAuthenticated ? <MediaVehiclesChannelTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/ChannelType/:id" element={isAuthenticated ? <MediaVehiclesChannelTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleType/Index" element={isAuthenticated ? <MediaVehiclesMediaVehicleTypePage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleType/New" element={isAuthenticated ? <MediaVehiclesMediaVehicleTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleType/:id" element={isAuthenticated ? <MediaVehiclesMediaVehicleTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleGroup/Index" element={isAuthenticated ? <MediaVehiclesMediaVehicleGroupPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleGroup/New" element={isAuthenticated ? <MediaVehiclesMediaVehicleGroupNewPage /> : <Navigate to="/" />} />
	  <Route path="/MediaVehicles/MediaVehicleGroup/:id" element={isAuthenticated ? <MediaVehiclesMediaVehicleGroupDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterBreak/Index" element={isAuthenticated ? <TradingMasterBreakPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterBreak/New" element={isAuthenticated ? <TradingMasterBreakNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MasterBreak/:id" element={isAuthenticated ? <TradingMasterBreakDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealActivity/Index" element={isAuthenticated ? <TradingDealActivityPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealActivity/New" element={isAuthenticated ? <TradingDealActivityNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealActivity/:id" element={isAuthenticated ? <TradingDealActivityDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCandidate/Index" element={isAuthenticated ? <TradingDealCandidatePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualTarget/Index" element={isAuthenticated ? <TradingAnnualTargetPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualTarget/New" element={isAuthenticated ? <TradingAnnualTargetNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualTarget/:id" element={isAuthenticated ? <TradingAnnualTargetDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/Index" element={isAuthenticated ? <TradingRevenueStreamPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/New" element={isAuthenticated ? <TradingRevenueStreamNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/RevenueStream/:id" element={isAuthenticated ? <TradingRevenueStreamDetailPage /> : <Navigate to="/" />} />
  </>
);
