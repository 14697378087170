import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function GeneralLocationPage(): React.ReactElement {
  const currentSecurity = useSecurity('General', 'Location');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(["Areas/General/Scripts/Resources/en/res.Location.en.js",
        "Areas/General/Scripts/Resources/en/Types.en.js",
        "Areas/General/Scripts/Enums.js",
        "Areas/General/Scripts/Location/Index.js"], "initGeneralLocation", {});
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer"></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
