import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function CompaniesCompanyNewPage(): React.ReactElement {
  const [state, setState] = useState<ICompaniesCompany>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Companies', 'Company');
  let updatedState: ICompaniesCompany = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.ControllerId = currentSecurity.profile.UserId;
            updatedState.ControllerDisplayValue = currentSecurity.profile.LoginName;
            updatedState.Type = 0;
            getPreferredDetails("General", "Country").then((result) => {
              const resultcountry: any[] = result.Value;
              if (resultcountry && resultcountry.length > 0)
                updatedState = { ...updatedState, CountryId: resultcountry[0].Id, CountryDisplayValue: resultcountry[0].Display };
            getPreferredDetails("General", "Language").then((result) => {
              const resultlanguage: any[] = result.Value;
              if (resultlanguage && resultlanguage.length > 0)
                updatedState = { ...updatedState, LanguageId: resultlanguage[0].Id, LanguageDisplayValue: resultlanguage[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Companies/Scripts/Resources/en/res.Company.en.js",
        "Areas/Companies/Scripts/Resources/en/Types.en.js",
        "Areas/Companies/Scripts/Enums.js",
        "Areas/Companies/Scripts/Company/View.js"], "initNewCompaniesCompany", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Company" className="controls-container default block">
              <h3>Create a New <b>Companies/ Agencies</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CompanyCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CompanyEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CompanyEnglishName','CompanyNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CompanyNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CompanyEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CompanyEnglishShortName','CompanyNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CompanyNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CompanyGroupId">Company Group</label><select className="" id="CompanyCompanyGroupId" name="CompanyGroupId" defaultValue={state.CompanyGroupId}  >{state.CompanyGroupId && <option value={state.CompanyGroupId}>{state.CompanyGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="CompanyLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="CompanyCountryId" name="CountryId" defaultValue={state.CountryId}   required data-required-msg="Country is required"  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="CorporateColor">Corporate Color</label><input  required data-required-msg="Corporate Color is required"  id="CompanyCorporateColor" name="CorporateColor"  type="text" defaultValue={state.CorporateColor} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="FinanceID">Finance ID</label><input  required data-required-msg="Finance ID is required"  id="CompanyFinanceID" name="FinanceID" type="text" defaultValue={state.FinanceID} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="CompanyType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="5">Back Office</option>
        <option value="4">Media Specialist</option>
        <option value="3">Creative Agency</option>
        <option value="2">Product Incubation</option>
        <option value="1">Buying Central</option>
        <option value="0">Media Agency</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="ControllerId">Company Controller</label><select className="" id="CompanyControllerId" name="ControllerId" defaultValue={state.ControllerId}  >{state.ControllerId && <option value={state.ControllerId}>{state.ControllerDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CompanySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarCompany">
                <div className="left">
                  <button id="cancelCompanyButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCompanyButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ICompaniesCompany {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  CompanyGroupId?: string,
  CompanyGroupDisplayValue?: string,
  LanguageId?: string,
  LanguageDisplayValue?: string,
  CountryId?: string,
  CountryDisplayValue?: string,
  CorporateColor?: string,
  FinanceID?: string,
  Type?: number,
  ControllerId?: string,
  ControllerDisplayValue?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum CompanyTypeEnum { 'Back Office' = 5, 'Media Specialist' = 4, 'Creative Agency' = 3, 'Product Incubation' = 2, 'Buying Central' = 1, 'Media Agency' = 0 }

export function CompaniesCompanyDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ICompaniesCompany>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Companies', 'Company');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Companies", "Company", id).then(result => {
        setState(result.Value[0] as ICompaniesCompany);
        appendScriptsCallback(["Areas/Companies/Scripts/Resources/en/res.Company.en.js",
        "Areas/Companies/Scripts/Resources/en/Types.en.js",
        "Areas/Companies/Scripts/Enums.js",
        "Areas/Companies/Scripts/Company/View.js",
        "Areas/Companies/Scripts/Company/GroupCompaniesView.js"
        ,"Areas/Companies/Scripts/Resources/en/res.Office.en.js"
        ,"Areas/Companies/Scripts/Office/View.js"
        ], "initDetailCompaniesCompany", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadCompaniesCompanyState'] = (data) => {
    setState(data.Value[0] as ICompaniesCompany);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Company" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Companies/ Agencies: <span>{state.Display}</span><span id="CompanyToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.CompanyGroupId && <span className='element'><label>Company Group: <label className="strong">{state.CompanyGroupDisplayValue}</label></label></span>}
                  {state.LanguageId && <span className='element'><label>Language: <label className="strong">{state.LanguageDisplayValue}</label></label></span>}
                  {state.CountryId && <span className='element'><label>Country: <label className="strong">{state.CountryDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.ControllerId && <span className='element'><label>Company Controller: <label className="strong">{state.ControllerDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CompanyCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CompanyEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CompanyEnglishName','CompanyNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CompanyNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CompanyEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CompanyEnglishShortName','CompanyNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CompanyNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CompanyGroupId">Company Group</label><select className="" id="CompanyCompanyGroupId" name="CompanyGroupId" defaultValue={state.CompanyGroupId}  >{state.CompanyGroupId && <option value={state.CompanyGroupId}>{state.CompanyGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="CompanyLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="CompanyCountryId" name="CountryId" defaultValue={state.CountryId}   required data-required-msg="Country is required"  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="CorporateColor">Corporate Color</label><input  required data-required-msg="Corporate Color is required"  id="CompanyCorporateColor" name="CorporateColor"  type="text" defaultValue={state.CorporateColor} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="FinanceID">Finance ID</label><input  required data-required-msg="Finance ID is required"  id="CompanyFinanceID" name="FinanceID" type="text" defaultValue={state.FinanceID} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="CompanyType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="5">Back Office</option>
        <option value="4">Media Specialist</option>
        <option value="3">Creative Agency</option>
        <option value="2">Product Incubation</option>
        <option value="1">Buying Central</option>
        <option value="0">Media Agency</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="ControllerId">Company Controller</label><select className="" id="CompanyControllerId" name="ControllerId" defaultValue={state.ControllerId}  >{state.ControllerId && <option value={state.ControllerId}>{state.ControllerDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CompanySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="CompanySourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="CompanySource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarCompany">
                <div className="left">
                  <button id="cancelCompanyButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCompanyButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="CompanyTabstrip">
                <ul>
                    <li id="CompanyOffice" className="k-active"><i className="fas fa-building"></i><span className="labeltext">Buying Agencies</span></li>
                    <li id="GroupCompanies">GROUP ASSOCIATED COMPANIES</li>
                </ul>
                <div>
                    <div id="associationCompanyOffice">
                      <div className="k-block grid-details">
                        <h2>Buying Agencies</h2>
                        <div id="OfficeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div><div id="associationCompanyGroupCompany">
                      <div className="k-block grid-details">
                        <h2>GROUP ASSOCIATED COMPANIES</h2>
                        <div id="CompanyGroupGrid" className="grid-control"></div>
                      </div>
                    </div></div>
            </div>
        </section>
      </section>
    </div>
  );
}


