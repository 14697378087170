import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MediaVehiclesMediaVehicleGroupNewPage(): React.ReactElement {
  const [state, setState] = useState<IMediaVehiclesMediaVehicleGroup>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('MediaVehicles', 'MediaVehicleGroup');
  const updatedState: IMediaVehiclesMediaVehicleGroup = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(["Areas/MediaVehicles/Scripts/Resources/en/res.MediaVehicleGroup.en.js",
        "Areas/MediaVehicles/Scripts/Resources/en/Types.en.js",
        "Areas/MediaVehicles/Scripts/Enums.js",
        "Areas/MediaVehicles/Scripts/MediaVehicleGroup/View.js"], "initNewMediaVehiclesMediaVehicleGroup", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MediaVehicleGroup" className="controls-container default block">
              <h3>Create a New <b>Media Vehicle Group</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaVehicleGroupCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaVehicleGroupLabelBlockedCode">Blocked</label>
				<input id="MediaVehicleGroupCheckBlockedCode" defaultChecked name="MediaVehicleGroupCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaVehicleGroupEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaVehicleGroupEnglishName','MediaVehicleGroupNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaVehicleGroupNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaVehicleGroupEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaVehicleGroupEnglishShortName','MediaVehicleGroupNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaVehicleGroupNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MediaVehicleGroupMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="MediaVehicleGroupMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="MediaVehicleGroupType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="0">Group</option>
        <option value="1">Sub-Group</option>
        <option value="2">SuperGroup</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaVehicleGroupSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMediaVehicleGroup">
                <div className="left">
                  <button id="cancelMediaVehicleGroupButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaVehicleGroupButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMediaVehiclesMediaVehicleGroup {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  MediaSubtypeId?: string,
  MediaSubtypeDisplayValue?: string,
  Type?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MediaGroupTypeEnum { 'Group' = 0, 'Sub-Group' = 1, 'SuperGroup' = 2 }

export function MediaVehiclesMediaVehicleGroupDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMediaVehiclesMediaVehicleGroup>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('MediaVehicles', 'MediaVehicleGroup');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("MediaVehicles", "MediaVehicleGroup", id).then(result => {
        setState(result.Value[0] as IMediaVehiclesMediaVehicleGroup);
        appendScriptsCallback(["Areas/MediaVehicles/Scripts/Resources/en/res.MediaVehicleGroup.en.js",
        "Areas/MediaVehicles/Scripts/Resources/en/Types.en.js",
        "Areas/MediaVehicles/Scripts/Enums.js",
        "Areas/MediaVehicles/Scripts/MediaVehicleGroup/View.js"
        ], "initDetailMediaVehiclesMediaVehicleGroup", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMediaVehiclesMediaVehicleGroupState'] = (data) => {
    setState(data.Value[0] as IMediaVehiclesMediaVehicleGroup);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MediaVehicleGroup" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Media Vehicle Group: <span>{state.Display}</span><span id="MediaVehicleGroupToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  {state.MediaSubtypeId && <span className='element'><label>Media Subtype: <label className="strong">{state.MediaSubtypeDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaVehicleGroupCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaVehicleGroupLabelBlockedCode">Blocked</label>
				<input id="MediaVehicleGroupCheckBlockedCode" defaultChecked name="MediaVehicleGroupCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaVehicleGroupEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaVehicleGroupEnglishName','MediaVehicleGroupNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaVehicleGroupNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaVehicleGroupEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaVehicleGroupEnglishShortName','MediaVehicleGroupNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaVehicleGroupNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MediaVehicleGroupMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="MediaVehicleGroupMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="MediaVehicleGroupType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="0">Group</option>
        <option value="1">Sub-Group</option>
        <option value="2">SuperGroup</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaVehicleGroupSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MediaVehicleGroupSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MediaVehicleGroupSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMediaVehicleGroup">
                <div className="left">
                  <button id="cancelMediaVehicleGroupButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaVehicleGroupButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


