import { IMenuItemProps } from '../components/services/GraphQLShared';

export const sidebar_menu: IMenuItemProps[] = [
  { id: 0, title: 'Home', icon: 'home', route: '/', key: '' },
  {
    title: 'Deals',
    key: 'Trading',
    subkey: 'TradingDealMenu',
    icon: 'handshake',
    route: '#',
    id: 1,
  },
  {
    title: 'Deal Templates',
    key: 'DealDefinitionMenu',
    icon: 'handshake',
    route: '/Trading/DealDefinition/Index',
    id: 2,
    parentId: 1,
  },
  {
    title: 'Deal Instances',
    key: 'DealDefinitionInstanceMenu',
    icon: 'business-time',
    route: '/Trading/DealDefinitionInstance/Index',
    id: 3,
    parentId: 1,
  },
  {
    title: 'Deal Books',
    key: 'DealBook',
    icon: 'chalkboard-teacher',
    route: '/Trading/DealBook/OpenDealBook',
    id: 4,
    parentId: 1,
  },
  {
    title: 'Deal Book Meetings',
    key: 'DealBookMeeting',
    icon: 'user-tie',
    route: '/Trading/DealBook/OpenDealBookMeeting',
    id: 5,
    parentId: 1,
  },
  {
    title: 'Campaigns',
    key: 'Campaign',
    icon: 'shopping-cart',
    route: '/Trading/Campaign/Index',
    id: 7,
    parentId: 1,
  },
  {
    title: 'Snapshots',
    key: 'DealActivity',
    icon: 'images',
    route: '/Trading/DealActivity/Index',
    id: 8,
    parentId: 1,
  },
  {
    title: 'Forecast',
    key: 'Forecast',
    icon: 'calculator',
    route: '#',
    id: 10,
  },
  {
    title: 'Revenue Forecasts',
    key: 'RevenueForecast',
    icon: 'tablet-alt',
    route: '/Trading/RevenueForecastStream/IndexForecast',
    id: 11,
    parentId: 10,
  },
  {
    title: 'Period Management',
    key: 'PeriodManagement',
    icon: 'folder-tree',
    route: '/Trading/AgencyProfile/IndexRevenuePeriod',
    id: 12,
    parentId: 10,
  },
  {
    title: 'Revenue Streams',
    key: 'RevenueStreams',
    icon: 'tablet-alt',
    route: '/Trading/RevenueStream/Index',
    id: 13,
    parentId: 10,
  },
  {
    title: 'Reporting',
    key: 'Trading',
    subkey: 'TradingReportingMenu',
    icon: 'print',
    route: '#',
    id: 20,
  },
  {
    title: 'Reporter',
    key: 'TradingReporter',
    icon: 'print',
    route: '/Trading/DealBook/Reporter',
    id: 21,
    parentId: 20,
  },
  {
    title: 'SMBC Spend',
    key: 'MasterSpend',
    icon: 'chart-area',
    route: '/Trading/MasterSpend/Index?Type=outcome',
    id: 22,
    parentId: 20,
  },
  {
    title: 'Trading Spend Targets',
    key: 'AnnualTarget',
    icon: 'chart-line',
    route: '/Trading/AnnualTarget/Index',
    id: 23,
    parentId: 20,
  },
  {
    title: 'Configuration',
    key: 'Trading',
    subkey: 'TradingSetupMenu',
    icon: 'compass',
    route: '#',
    id: 30,
  },
  {
    title: 'Deal Setup for Media Types',
    key: 'DealMasterMediaCategory',
    icon: 'laptop',
    route: '/Trading/MasterMediaCategory/Index',
    id: 31,
    parentId: 30,
  },
  {
    title: 'SMBC Client Sets',
    key: 'MasterClientSet',
    icon: 'users',
    route: '/Trading/MasterClientSet/Index',
    id: 32,
    parentId: 30,
  },
  {
    title: 'SMBC Indexes',
    key: 'MasterSpendIndex',
    icon: 'chart-area',
    route: '/Trading/MasterSpend/Index?Type=setup',
    id: 33,
    parentId: 30,
  },
  {
    title: 'Bulk Operations',
    key: 'BulkControlPanel',
    icon: 'universal-access',
    route: '/Trading/DealDefinitionInstance/BulkPanel',
    id: 34,
    parentId: 30,
  },
  {
    title: 'Agencies and Clients',
    key: 'MDM',
    subkey: 'ClientsMenu',
    icon: 'city',
    route: '#',
    id: 40,
  },
  {
    title: 'Companies and Agencies',
    key: 'Company',
    icon: 'sitemap',
    route: '/Companies/Company/Index',
    id: 41,
    parentId: 40,
  },
  {
    title: 'Clients',
    key: 'Client',
    icon: 'user',
    route: '/Clients/Client/Index',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Client Sets',
    key: 'ClientSet',
    icon: 'folder',
    route: '/Clients/ClientSet/Index',
    id: 43,
    parentId: 40,
  },
  {
    title: 'Master Clients',
    key: 'MasterClient',
    icon: 'cubes',
    route: '/Clients/MasterClient/Index',
    id: 44,
    parentId: 40,
  },
  {
    title: 'SMBCs',
    key: 'ManagementType',
    icon: 'asterisk',
    route: '/Finance/ManagementType/Index',
    id: 45,
    parentId: 40,
  },
  {
    title: 'Cost Models',
    key: 'CostModel',
    icon: 'shopping-cart',
    route: '/Pricing/CostModel/Index',
    id: 46,
    parentId: 40,
  },
  {
    title: 'Media',
    key: 'MDM',
    subkey: 'MediaTypesMenu',
    icon: 'warehouse',
    route: '#',
    id: 50,
  },
  {
    title: 'Media Types',
    key: 'MediaType',
    icon: 'life-ring',
    route: '/MediaTypes/MediaType/Index',
    id: 51,
    parentId: 50,
  },
  {
    title: 'Sales Houses',
    key: 'SalesHouse',
    icon: 'shopping-cart',
    route: '/MediaVehicles/SalesHouse/Index',
    id: 52,
    parentId: 50,
  },
  {
    title: 'Media Vehicles',
    key: 'MediaVehicle',
    icon: 'car',
    route: '/MediaVehicles/MediaVehicle/Index',
    id: 53,
    parentId: 50,
  },
  {
    title: 'Media Groups',
    key: 'MediaVehicleGroup',
    icon: 'briefcase',
    route: '/MediaVehicles/MediaVehicleGroup/Index',
    id: 54,
    parentId: 50,
  },
  {
    title: 'Media Channels',
    key: 'Channel',
    icon: 'bullhorn',
    route: '/MediaVehicles/Channel/Index',
    id: 55,
    parentId: 50,
  },
  {
    title: 'Media Vehicle Types',
    key: 'MediaVehicleType',
    icon: 'info',
    route: '/MediaVehicles/MediaVehicleType/Index',
    id: 56,
    parentId: 50,
  },
  {
    title: 'Buying Routes',
    key: 'BuyingRoute',
    icon: 'map-signs',
    route: '/MediaVehicles/BuyingRoute/Index',
    id: 57,
    parentId: 50,
  },
  {
    title: 'Campaign Types',
    key: 'CampaignType',
    icon: 'road',
    route: '/MediaTypes/CampaignType/Index',
    id: 58,
    parentId: 50,
  },
  {
    title: 'Broadcast Types',
    key: 'BroadcastType',
    icon: 'rss',
    route: '/MediaTypes/BroadcastType/Index',
    id: 59,
    parentId: 50,
  },
  {
    title: 'Audiences',
    key: 'Target',
    icon: 'street-view',
    route: '/MediaTypes/Target/Index',
    id: 591,
    parentId: 50,
  },
  {
    title: 'General',
    key: 'MDM',
    subkey: 'GeneralMenu',
    icon: 'archive',
    route: '#',
    id: 60,
  },
  {
    title: 'Country',
    key: 'Country',
    icon: 'globe',
    route: '/General/Country/Index',
    id: 61,
    parentId: 60,
  },
  {
    title: 'Region',
    key: 'Region',
    icon: 'map-pin',
    route: '/General/Region/Index',
    id: 62,
    parentId: 60,
  },
  {
    title: 'Language',
    key: 'Language',
    icon: 'sign-language',
    route: '/General/Language/Index',
    id: 63,
    parentId: 60,
  },
  {
    title: 'Location',
    key: 'Location',
    icon: 'map',
    route: '/General/Location/Index',
    id: 64,
    parentId: 60,
  },
  {
    title: 'Currency',
    key: 'Currency',
    icon: 'euro-sign',
    route: '/General/Currency/Index',
    id: 65,
    parentId: 60,
  },
  {
    title: 'Nationality',
    key: 'Nationality',
    icon: 'passport',
    route: '/General/Nationality/Index',
    id: 66,
    parentId: 60,
  },
  {
    title: 'Security',
    key: 'SecurityMenu',
    icon: 'users',
    route: '#',
    id: 100,
  },
  {
    title: 'Roles',
    key: 'Roles',
    icon: 'tablet-alt',
    route: '/Framework/Role/Index',
    id: 101,
    parentId: 100,
  },
  {
    title: 'Security Groups',
    key: 'SecurityGroups',
    icon: 'tablet-alt',
    route: '/Framework/Group/Index',
    id: 102,
    parentId: 100,
  },
  {
    title: 'Users',
    key: 'Users',
    icon: 'tablet-alt',
    route: '/Framework/User/Index',
    id: 103,
    parentId: 100,
  },
  {
    title: 'Users and Groups',
    key: 'UsersandGroups',
    icon: 'tablet-alt',
    route: '/Framework/User/IndexTree',
    id: 104,
    parentId: 100,
  },
  {
    title: 'Administration',
    key: 'AdminMenu',
    icon: 'wrench',
    route: '#',
    id: 200,
  },
  {
    title: 'Documents',
    key: 'Documents',
    icon: 'tablet-alt',
    route: '/Framework/DocumentFile/Index',
    id: 201,
    parentId: 200,
  },
  {
    title: 'Application Settings',
    key: 'ApplicationSettings',
    icon: 'tablet-alt',
    route: '/Framework/AppSettings/Index',
    id: 202,
    parentId: 200,
  },
  {
    title: 'Audit Messages',
    key: 'AuditMessage',
    icon: 'desktop',
    route: '/Integration/AuditMessage/Index',
    id: 205,
    parentId: 200,
  },
  {
    title: 'Data Administration',
    key: 'DataAdministration',
    icon: 'tablet-alt',
    route: '/Framework/DataAdmin/Index',
    id: 203,
    parentId: 200,
  },
  {
    title: 'Insights Viewer',
    key: 'InsightsViewer',
    icon: 'tablet-alt',
    route: '/Framework/InsightsViewer/Index',
    id: 204,
    parentId: 200,
  },
];
export const app_themes = [
  { id: 'light', text: 'Light' },
  { id: 'dark', text: 'Dark' },
];
