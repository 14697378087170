import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function OpenDealBookPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'DealBook');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Trading/Scripts/Resources/en/res.DealActivity.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBook.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookMeeting.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookMeetingMove.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookMeetingShare.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookTarget.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookMakeGood.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookAdjustmentFactor.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/jquery.snapshot.custom.js',
          'Areas/Trading/Scripts/DealBook/Index.custom.js',
        'Areas/Trading/Scripts/DealBookTarget/View.custom.js',
        'Areas/Trading/Scripts/DealBookAdjustmentFactor/View.js',
        'Areas/Trading/Scripts/DealBookMeeting/Calculate.custom.js',
        'Areas/Trading/Scripts/DealBookMeeting/ViewMeeting.custom.js',
        'Areas/Trading/Scripts/DealBookMeeting/InnerView.custom.js',
      ],
        'initNewOpenDealBook', { appSettings: currentSecurity.profile.AppSettings.Values, IsMeetingView: 0 });
    }
  }, []);

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Period Assumptions</h3>
          <div className="field yearcontrol-container">
            <label className="required">Fiscal Year</label>
            <input id="inputYear" />
          </div>
          <div className="field middlecontrol-container">
            <label className="required">Media Type</label>
            <input id="inputMasterMediaCategory" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Period</label>
            <input id="inputPeriod" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">DealBook Meeting</label>
            <input id="inputMeeting" />
          </div>
          <section className="button-toolbar">
            <div className="left"><button id="addFilterButton" type="button" className="k-button" title="New">New</button></div>
            <div className="right">
              <button id="applyFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button>
            </div>
          </section>
          <h3>Meetings</h3>
          <p>Please select all the mandatory fields above plus the ones on this section.</p>
          <div className="field fullwidth-container">
            <label className="required">Previous Meeting</label>
            <input id="inputMeetingPrevious" />
          </div>
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          <section className="button-toolbar">
            <div className="left"><button id="snapshotFilterButton" type="button" className="k-button" title="Snapshot">Snapshot</button></div>
            <div className="right"><button id="loadFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
          <h3>Deal Book Difference Report</h3>
          <div className="field fullwidth-container">
            <label>Agencies/Clients</label>
            <select id="inputAgencies" />
          </div>
          <section className="button-toolbar">
            <div className="right"><button id="reportFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="DealBookContainer" className="k-content"></div>
      </div>
    </div>
  );
}

export function OpenDealBookMeetingPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'DealBook');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
        'Areas/Trading/Scripts/Resources/en/res.DealActivity.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBook.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookMeeting.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookMeetingMove.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookMeetingShare.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookTarget.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookMakeGood.en.js',
        'Areas/Trading/Scripts/Resources/en/res.DealBookAdjustmentFactor.en.js',
        'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
        'Areas/Trading/Scripts/Resources/en/Types.en.js',
        'Areas/Trading/Scripts/Enums.js',
        'Areas/Trading/Scripts/jquery.shared.custom.js',
        'Areas/Trading/Scripts/jquery.snapshot.custom.js',
        'Areas/Trading/Scripts/DealBook/Index.custom.js',
        'Areas/Trading/Scripts/DealBookTarget/View.custom.js',
        'Areas/Trading/Scripts/DealBookAdjustmentFactor/View.js',
        'Areas/Trading/Scripts/DealBookMeeting/Calculate.custom.js',
        'Areas/Trading/Scripts/DealBookMeeting/ViewMeeting.custom.js',
        'Areas/Trading/Scripts/DealBookMeeting/InnerView.custom.js',
      ],
        'initNewOpenDealBook', { appSettings: currentSecurity.profile.AppSettings.Values, IsMeetingView: 1 });
    }
  }, []);

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Meetings</h3>
          <div className="field yearcontrol-container">
            <label className="required">Fiscal Year</label>
            <input id="inputYear" />
          </div>
          <div className="field middlecontrol-container">
            <label className="required">Media Type</label>
            <input id="inputMasterMediaCategory" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Period</label>
            <input id="inputPeriod" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">DealBook Meeting</label>
            <input id="inputMeeting" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Previous Meeting</label>
            <input id="inputMeetingPrevious" />
          </div>
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          <section className="button-toolbar">
            <div className="left"><button id="snapshotFilterButton" type="button" className="k-button" title="Snapshot">Snapshot</button></div>
            <div className="right"><button id="loadFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
          <h3>Deal Book Difference Report</h3>
          <div className="field fullwidth-container">
            <label>Agencies/Clients</label>
            <select id="inputAgencies" />
          </div>
          <section className="button-toolbar">
            <div className="right"><button id="reportFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="DealBookContainer" className="k-content"></div>
      </div>
    </div>
  );
}
