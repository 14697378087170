import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function MediaVehiclesMediaVehicleNewPage(): React.ReactElement {
  const [state, setState] = useState<IMediaVehiclesMediaVehicle>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('MediaVehicles', 'MediaVehicle');
  let updatedState: IMediaVehiclesMediaVehicle = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Ranking = 0;
            updatedState.Class = 0;
            getPreferredDetails("General", "Language").then((result) => {
              const resultlanguage: any[] = result.Value;
              if (resultlanguage && resultlanguage.length > 0)
                updatedState = { ...updatedState, LanguageId: resultlanguage[0].Id, LanguageDisplayValue: resultlanguage[0].Display };
            getPreferredDetails("General", "Currency").then((result) => {
              const resultcurrency: any[] = result.Value;
              if (resultcurrency && resultcurrency.length > 0)
                updatedState = { ...updatedState, CurrencyId: resultcurrency[0].Id, CurrencyDisplayValue: resultcurrency[0].Display };
            getPreferredDetails("MediaTypes", "BroadcastType").then((result) => {
              const resultbroadcasttype: any[] = result.Value;
              if (resultbroadcasttype && resultbroadcasttype.length > 0)
                updatedState = { ...updatedState, BroadcastTypeId: resultbroadcasttype[0].Id, BroadcastTypeDisplayValue: resultbroadcasttype[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/MediaVehicles/Scripts/Resources/en/res.MediaVehicle.en.js",
        "Areas/MediaVehicles/Scripts/Resources/en/Types.en.js",
        "Areas/MediaVehicles/Scripts/Enums.js",
        "Areas/MediaVehicles/Scripts/MediaVehicle/View.js"], "initNewMediaVehiclesMediaVehicle", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
            });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MediaVehicle" className="controls-container default block">
              <h3>Create a New <b>Media Vehicle</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaVehicleCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaVehicleLabelBlockedCode">Blocked</label>
				<input id="MediaVehicleCheckBlockedCode" defaultChecked name="MediaVehicleCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaVehicleEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaVehicleEnglishName','MediaVehicleNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaVehicleNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaVehicleEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaVehicleEnglishShortName','MediaVehicleNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaVehicleNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="MediaVehicleLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="GroupId">Group</label><select className="" id="MediaVehicleGroupId" name="GroupId" defaultValue={state.GroupId}  >{state.GroupId && <option value={state.GroupId}>{state.GroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaChannelId">Media Channel</label><select className="" id="MediaVehicleMediaChannelId" name="MediaChannelId" defaultValue={state.MediaChannelId}  >{state.MediaChannelId && <option value={state.MediaChannelId}>{state.MediaChannelDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaVehicleTypeId">Media Vehicle Type</label><select className="" id="MediaVehicleMediaVehicleTypeId" name="MediaVehicleTypeId" defaultValue={state.MediaVehicleTypeId}  >{state.MediaVehicleTypeId && <option value={state.MediaVehicleTypeId}>{state.MediaVehicleTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="MediaVehicleSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId}  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SubGroupId">Subgroup</label><select className="" id="MediaVehicleSubGroupId" name="SubGroupId" defaultValue={state.SubGroupId}  >{state.SubGroupId && <option value={state.SubGroupId}>{state.SubGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SuperGroupId">SuperGroup</label><select className="" id="MediaVehicleSuperGroupId" name="SuperGroupId" defaultValue={state.SuperGroupId}  >{state.SuperGroupId && <option value={state.SuperGroupId}>{state.SuperGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MediaVehicleMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="MediaVehicleMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BroadcastTypeId">Broadcast Type</label><select className="" id="MediaVehicleBroadcastTypeId" name="BroadcastTypeId" defaultValue={state.BroadcastTypeId}  >{state.BroadcastTypeId && <option value={state.BroadcastTypeId}>{state.BroadcastTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BroadcastEntityMediaVehicleId">Major Station</label><select className="" id="MediaVehicleBroadcastEntityMediaVehicleId" name="BroadcastEntityMediaVehicleId" defaultValue={state.BroadcastEntityMediaVehicleId}  >{state.BroadcastEntityMediaVehicleId && <option value={state.BroadcastEntityMediaVehicleId}>{state.BroadcastEntityMediaVehicleDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="TopMediaVehicleId">Core Media-Vehicle</label><select className="" id="MediaVehicleTopMediaVehicleId" name="TopMediaVehicleId" defaultValue={state.TopMediaVehicleId}  >{state.TopMediaVehicleId && <option value={state.TopMediaVehicleId}>{state.TopMediaVehicleDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="MediaVehicleNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Class" className="required">Class</label>
      <select className="" id="MediaVehicleClass" name="Class" value={state.Class} required data-required-msg="Class is required" >
        <option value="4">None</option>
        <option value="3">Ruby</option>
        <option value="2">Sapphire</option>
        <option value="1">Emerald</option>
        <option value="0">Diammond</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Ranking" className="required">Ranking</label>
      <select className="" id="MediaVehicleRanking" name="Ranking" value={state.Ranking} required data-required-msg="Ranking is required" >
        <option value="3">None</option>
        <option value="2">Bronze</option>
        <option value="1">Silver</option>
        <option value="0">Golden</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="MediaVehicleCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaVehicleSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMediaVehicle">
                <div className="left">
                  <button id="cancelMediaVehicleButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaVehicleButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMediaVehiclesMediaVehicle {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  LanguageId?: string,
  LanguageDisplayValue?: string,
  GroupId?: string,
  GroupDisplayValue?: string,
  MediaChannelId?: string,
  MediaChannelDisplayValue?: string,
  MediaVehicleTypeId?: string,
  MediaVehicleTypeDisplayValue?: string,
  SalesHouseId?: string,
  SalesHouseDisplayValue?: string,
  SubGroupId?: string,
  SubGroupDisplayValue?: string,
  SuperGroupId?: string,
  SuperGroupDisplayValue?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  MediaSubtypeId?: string,
  MediaSubtypeDisplayValue?: string,
  BroadcastTypeId?: string,
  BroadcastTypeDisplayValue?: string,
  BroadcastEntityMediaVehicleId?: string,
  BroadcastEntityMediaVehicleDisplayValue?: string,
  TopMediaVehicleId?: string,
  TopMediaVehicleDisplayValue?: string,
  Notes?: string,
  Class?: number,
  Ranking?: number,
  CurrencyId?: string,
  CurrencyDisplayValue?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MediaTradeClassEnum { 'None' = 4, 'Ruby' = 3, 'Sapphire' = 2, 'Emerald' = 1, 'Diammond' = 0 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MediaRankingEnum { 'None' = 3, 'Bronze' = 2, 'Silver' = 1, 'Golden' = 0 }

export function MediaVehiclesMediaVehicleDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMediaVehiclesMediaVehicle>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('MediaVehicles', 'MediaVehicle');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("MediaVehicles", "MediaVehicle", id).then(result => {
        setState(result.Value[0] as IMediaVehiclesMediaVehicle);
        appendScriptsCallback(["Areas/MediaVehicles/Scripts/Resources/en/res.MediaVehicle.en.js",
        "Areas/MediaVehicles/Scripts/Resources/en/Types.en.js",
        "Areas/MediaVehicles/Scripts/Enums.js",
        "Areas/MediaVehicles/Scripts/MediaVehicle/View.js"
        ,"Areas/MediaVehicles/Scripts/MediaVehicle/BroadcasterMediaVehiclesView.custom.js"
        ,"Areas/MediaVehicles/Scripts/MediaVehicle/SuplementsView.custom.js"
        ,"Areas/MediaVehicles/Scripts/Resources/en/res.MediaVehicleManagementType.en.js"
        ,"Areas/MediaVehicles/Scripts/MediaVehicleManagementType/View.js"
        ], "initDetailMediaVehiclesMediaVehicle", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadMediaVehiclesMediaVehicleState'] = (data) => {
    setState(data.Value[0] as IMediaVehiclesMediaVehicle);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MediaVehicle" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Media Vehicle: <span>{state.Display}</span><span id="MediaVehicleToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.LanguageId && <span className='element'><label>Language: <label className="strong">{state.LanguageDisplayValue}</label></label></span>}
                  {state.GroupId && <span className='element'><label>Group: <label className="strong">{state.GroupDisplayValue}</label></label></span>}
                  {state.MediaChannelId && <span className='element'><label>Media Channel: <label className="strong">{state.MediaChannelDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.MediaVehicleTypeId && <span className='element'><label>Media Vehicle Type: <label className="strong">{state.MediaVehicleTypeDisplayValue}</label></label></span>}
                  {state.SalesHouseId && <span className='element'><label>Sales House: <label className="strong">{state.SalesHouseDisplayValue}</label></label></span>}
                  {state.SubGroupId && <span className='element'><label>Subgroup: <label className="strong">{state.SubGroupDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.SuperGroupId && <span className='element'><label>SuperGroup: <label className="strong">{state.SuperGroupDisplayValue}</label></label></span>}
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  {state.MediaSubtypeId && <span className='element'><label>Media Subtype: <label className="strong">{state.MediaSubtypeDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.BroadcastTypeId && <span className='element'><label>Broadcast Type: <label className="strong">{state.BroadcastTypeDisplayValue}</label></label></span>}
                  {state.BroadcastEntityMediaVehicleId && <span className='element'><label>Major Station: <label className="strong">{state.BroadcastEntityMediaVehicleDisplayValue}</label></label></span>}
                  {state.TopMediaVehicleId && <span className='element'><label>Core Media-Vehicle: <label className="strong">{state.TopMediaVehicleDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  <span className='element'><label>Class: <label className="strong">{state.Class != undefined ? MediaTradeClassEnum[state.Class] : ''}</label></label></span>
                  {state.CurrencyId && <span className='element'><label>Currency: <label className="strong">{state.CurrencyDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MediaVehicleCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MediaVehicleLabelBlockedCode">Blocked</label>
				<input id="MediaVehicleCheckBlockedCode" defaultChecked name="MediaVehicleCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="MediaVehicleEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('MediaVehicleEnglishName','MediaVehicleNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="MediaVehicleNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="MediaVehicleEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('MediaVehicleEnglishShortName','MediaVehicleNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="MediaVehicleNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="LanguageId">Language</label><select className="" id="MediaVehicleLanguageId" name="LanguageId" defaultValue={state.LanguageId}   required data-required-msg="Language is required"  >{state.LanguageId && <option value={state.LanguageId}>{state.LanguageDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="GroupId">Group</label><select className="" id="MediaVehicleGroupId" name="GroupId" defaultValue={state.GroupId}  >{state.GroupId && <option value={state.GroupId}>{state.GroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaChannelId">Media Channel</label><select className="" id="MediaVehicleMediaChannelId" name="MediaChannelId" defaultValue={state.MediaChannelId}  >{state.MediaChannelId && <option value={state.MediaChannelId}>{state.MediaChannelDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaVehicleTypeId">Media Vehicle Type</label><select className="" id="MediaVehicleMediaVehicleTypeId" name="MediaVehicleTypeId" defaultValue={state.MediaVehicleTypeId}  >{state.MediaVehicleTypeId && <option value={state.MediaVehicleTypeId}>{state.MediaVehicleTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="MediaVehicleSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId}  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SubGroupId">Subgroup</label><select className="" id="MediaVehicleSubGroupId" name="SubGroupId" defaultValue={state.SubGroupId}  >{state.SubGroupId && <option value={state.SubGroupId}>{state.SubGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="SuperGroupId">SuperGroup</label><select className="" id="MediaVehicleSuperGroupId" name="SuperGroupId" defaultValue={state.SuperGroupId}  >{state.SuperGroupId && <option value={state.SuperGroupId}>{state.SuperGroupDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MediaVehicleMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="MediaVehicleMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BroadcastTypeId">Broadcast Type</label><select className="" id="MediaVehicleBroadcastTypeId" name="BroadcastTypeId" defaultValue={state.BroadcastTypeId}  >{state.BroadcastTypeId && <option value={state.BroadcastTypeId}>{state.BroadcastTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BroadcastEntityMediaVehicleId">Major Station</label><select className="" id="MediaVehicleBroadcastEntityMediaVehicleId" name="BroadcastEntityMediaVehicleId" defaultValue={state.BroadcastEntityMediaVehicleId}  >{state.BroadcastEntityMediaVehicleId && <option value={state.BroadcastEntityMediaVehicleId}>{state.BroadcastEntityMediaVehicleDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="TopMediaVehicleId">Core Media-Vehicle</label><select className="" id="MediaVehicleTopMediaVehicleId" name="TopMediaVehicleId" defaultValue={state.TopMediaVehicleId}  >{state.TopMediaVehicleId && <option value={state.TopMediaVehicleId}>{state.TopMediaVehicleDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="MediaVehicleNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Class" className="required">Class</label>
      <select className="" id="MediaVehicleClass" name="Class" value={state.Class} required data-required-msg="Class is required" >
        <option value="4">None</option>
        <option value="3">Ruby</option>
        <option value="2">Sapphire</option>
        <option value="1">Emerald</option>
        <option value="0">Diammond</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Ranking" className="required">Ranking</label>
      <select className="" id="MediaVehicleRanking" name="Ranking" value={state.Ranking} required data-required-msg="Ranking is required" >
        <option value="3">None</option>
        <option value="2">Bronze</option>
        <option value="1">Silver</option>
        <option value="0">Golden</option>
      </select>
    </div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="MediaVehicleCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MediaVehicleSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MediaVehicleSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MediaVehicleSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMediaVehicle">
                <div className="left">
                  <button id="cancelMediaVehicleButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMediaVehicleButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="MediaVehicleTabstrip">
                <ul>
                    <li id="MediaVehicleMediaVehicleManagementType" className="k-active"><i className="fas fa-asterisk"></i><span className="labeltext">Media Vehicle SMBCs</span></li>
                    <li id="Suplements">Suplements</li>
                    <li id="BroadcasterMediaVehicles">Stations</li>
                </ul>
                <div>
                    <div id="associationMediaVehicleMediaVehicleManagementType">
                      <div className="k-block grid-details">
                        <h2>Media Vehicle SMBCs</h2>
                        <div id="MediaVehicleManagementTypeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            <div><div id="associationSuplements" className="block entity-details">
              <div className="k-block grid-details">
                <h2>Suplements</h2>
                <div id="TopMediaVehicleGrid" className="grid-control"></div>
              </div>
            </div>
            </div>
            <div><div id="associationBroadcasterMediaVehicles" className="block entity-details">
              <div className="k-block grid-details">
                    <h2>Stations</h2>
                <div id="BroadcastEntityMediaVehicleGrid" className="grid-control"></div>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}


