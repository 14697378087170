import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingDealDefinitionCustomNewPage(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type")
  const [state, setState] = useState<ITradingDealDefinition>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealDefinition');
  let updatedState: ITradingDealDefinition = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.SubmitterId = currentSecurity.profile.UserId;
            updatedState.SubmitterDisplayValue = currentSecurity.profile.LoginName;
            updatedState.DealType = Number(type);
            getPreferredDetails("General", "Currency").then((result) => {
              const resultcurrency: any[] = result.Value;
              if (resultcurrency && resultcurrency.length > 0)
                updatedState = { ...updatedState, CurrencyId: resultcurrency[0].Id, CurrencyDisplayValue: resultcurrency[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealDefinition.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealDefinition/View.custom.js"], "initNewTradingDealDefinition", { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true, appSettings: currentSecurity.profile.AppSettings.Values });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="DealDefinition" className="controls-container default block">
              <h3>Create a New <b>Deal Template</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealDefinitionCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
                <div className="content-header">Type of Deal</div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="DealType" className="required">Type Of Deal</label>
                  <select className="" id="DealDefinitionDealType" name="DealType" value={state.DealType} required data-required-msg="Type Of Deal is required" >
                    <option value="0">Group Deal</option>
                    <option value="1">Agency Deal</option>
                    <option value="2">Client Deal</option>
                    <option value="3">Client-Set Deal</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="DealDefinitionMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId} required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
                <div className="content-header">Contracted Parties</div>
                <div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="DealDefinitionAgencyId" name="AgencyId" defaultValue={state.AgencyId} required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
                {state.DealType != 0 && <div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="DealDefinitionBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>}
                {state.DealType == 2 && <div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="DealDefinitionMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>}
                {state.DealType == 2 && <div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="DealDefinitionClientId" name="ClientId" defaultValue={state.ClientId}  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>}
                {state.DealType == 3 && <div className="col-md-4 combobox  " ><label htmlFor="ClientSetId">Client Set</label><select className="" id="DealDefinitionClientSetId" name="ClientSetId" defaultValue={state.ClientSetId}  >{state.ClientSetId && <option value={state.ClientSetId}>{state.ClientSetDisplayValue}</option>}</select></div>}
                <div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="DealDefinitionSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId} required data-required-msg="Sales House is required"  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
                <div className="col-md-2 dropdownlist rd-input" >
                  <label htmlFor="AgreementType" className="required">Agreement Type</label>
                  <select className="" id="DealDefinitionAgreementType" name="AgreementType" value={state.AgreementType} required data-required-msg="Agreement Type is required" >
                    <option value="0">Pricing Agreement</option>
                    <option value="1">Share Agreement</option>
                  </select>
                </div>
                <div className="content-header">Deal Information</div>

		
		<div className="col-md-4" ><label htmlFor="DealName">Template Name</label><input  required data-required-msg="Template Name is required"  id="DealDefinitionDealName" name="DealName" type="text" defaultValue={state.DealName} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="DealReference">#Reference</label><input  id="DealDefinitionDealReference" name="DealReference" type="text" defaultValue={state.DealReference} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="DealDefinitionCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId} required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>

                <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="DescriptionNotes">Deal Description</label><div><textarea className="k-input k-textbox" cols={20} id="DealDefinitionDescriptionNotes" name="DescriptionNotes" maxLength={2147483647} rows={5} defaultValue={state.DescriptionNotes}></textarea></div></div>
                <div className="content-header">Deal OwnerShip & Management</div>

    
		
		<div className="col-md-4 combobox  " ><label htmlFor="SubmitterId">Submitter</label><select className="" id="DealDefinitionSubmitterId" name="SubmitterId" defaultValue={state.SubmitterId}   required data-required-msg="Submitter is required"  >{state.SubmitterId && <option value={state.SubmitterId}>{state.SubmitterDisplayValue}</option>}</select></div>
		          </form>
              <section className="button-toolbar" id="ToolbarDealDefinition">
                <div className="left">
                  <button id="cancelDealDefinitionButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealDefinitionButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDealDefinition {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  ClientSetId?: string,
  ClientSetDisplayValue?: string,
  BuyingAgencyId?: string,
  BuyingAgencyDisplayValue?: string,
  AgencyId?: string,
  AgencyDisplayValue?: string,
  ClientId?: string,
  ClientDisplayValue?: string,
  MasterClientId?: string,
  MasterClientDisplayValue?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  SalesHouseId?: string,
  SalesHouseDisplayValue?: string,
  DealName?: string,
  DealReference?: string,
  DescriptionNotes?: string,
  AgreementType?: number,
  DealType?: number,
  CurrencyId?: string,
  CurrencyDisplayValue?: string,
  SubmitterId?: string,
  SubmitterDisplayValue?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealLevelEnum { 'Group Deal' = 0, 'Agency Deal' = 1, 'Client Deal' = 2, 'Client-Set Deal' = 3 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealAgreementTypeEnum { 'Pricing Agreement' = 0, 'Share Agreement' = 1 }

export function TradingDealDefinitionCustomDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDealDefinition>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealDefinition');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "DealDefinition", id).then(result => {
        setState(result.Value[0] as ITradingDealDefinition);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealDefinition.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealDefinition/View.custom.js",
        "Areas/Trading/Scripts/DealDefinition/ClientList.custom.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealDefinitionInstance.en.js"
        ,"Areas/Trading/Scripts/DealDefinitionInstance/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealDefinitionCondition.en.js"
        ,"Areas/Trading/Scripts/DealDefinitionCondition/View.custom.js"
        ], "initDetailTradingDealDefinition", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id, appSettings: currentSecurity.profile.AppSettings.Values });
      });
    }
  }, []);
  window['reloadTradingDealDefinitionState'] = (data) => {
    setState(data.Value[0] as ITradingDealDefinition);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealDefinition" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Deal Template: <span>{state.Display}</span><span id="DealDefinitionToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.ClientSetId && <span className='element'><label>Client Set: <label className="strong">{state.ClientSetDisplayValue}</label></label></span>}
                  {state.BuyingAgencyId && <span className='element'><label>Buying Agency: <label className="strong">{state.BuyingAgencyDisplayValue}</label></label></span>}
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.ClientId && <span className='element'><label>Client: <label className="strong">{state.ClientDisplayValue}</label></label></span>}
                  {state.MasterClientId && <span className='element'><label>Master Client: <label className="strong">{state.MasterClientDisplayValue}</label></label></span>}
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.SalesHouseId && <span className='element'><label>Sales House: <label className="strong">{state.SalesHouseDisplayValue}</label></label></span>}
                  {state.CurrencyId && <span className='element'><label>Currency: <label className="strong">{state.CurrencyDisplayValue}</label></label></span>}
                  {state.SubmitterId && <span className='element'><label>Submitter: <label className="strong">{state.SubmitterDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealDefinitionCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
                <div className="content-header">Type of Deal</div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="DealType" className="required">Type Of Deal</label>
                  <select className="" id="DealDefinitionDealType" name="DealType" value={state.DealType} required data-required-msg="Type Of Deal is required" >
                    <option value="0">Group Deal</option>
                    <option value="1">Agency Deal</option>
                    <option value="2">Client Deal</option>
                    <option value="3">Client-Set Deal</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="DealDefinitionMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId} required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
                <div className="content-header">Contracted Parties</div>
                <div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="DealDefinitionAgencyId" name="AgencyId" defaultValue={state.AgencyId} required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
                {state.DealType != 0 && <div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="DealDefinitionBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>}
                {state.DealType == 2 && <div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="DealDefinitionMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>}
                {state.DealType == 2 && <div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="DealDefinitionClientId" name="ClientId" defaultValue={state.ClientId}  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>}
                {state.DealType == 3 && <div className="col-md-4 combobox  " ><label htmlFor="ClientSetId">Client Set</label><select className="" id="DealDefinitionClientSetId" name="ClientSetId" defaultValue={state.ClientSetId}  >{state.ClientSetId && <option value={state.ClientSetId}>{state.ClientSetDisplayValue}</option>}</select></div>}
                <div className="col-md-4 combobox  " ><label htmlFor="SalesHouseId">Sales House</label><select className="" id="DealDefinitionSalesHouseId" name="SalesHouseId" defaultValue={state.SalesHouseId} required data-required-msg="Sales House is required"  >{state.SalesHouseId && <option value={state.SalesHouseId}>{state.SalesHouseDisplayValue}</option>}</select></div>
                <div className="col-md-2 dropdownlist rd-input" >
                  <label htmlFor="AgreementType" className="required">Agreement Type</label>
                  <select className="" id="DealDefinitionAgreementType" name="AgreementType" value={state.AgreementType} required data-required-msg="Agreement Type is required" >
                    <option value="0">Pricing Agreement</option>
                    <option value="1">Share Agreement</option>
                  </select>
                </div>
                <div className="content-header">Deal Information</div>


                <div className="col-md-4" ><label htmlFor="DealName">Template Name</label><input required data-required-msg="Template Name is required" id="DealDefinitionDealName" name="DealName" type="text" defaultValue={state.DealName} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-4" ><label htmlFor="DealReference">#Reference</label><input id="DealDefinitionDealReference" name="DealReference" type="text" defaultValue={state.DealReference} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="DealDefinitionCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId} required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>

                <div className="clear"></div>
                <div className="field textareafield"><label htmlFor="DescriptionNotes">Deal Description</label><div><textarea className="k-input k-textbox" cols={20} id="DealDefinitionDescriptionNotes" name="DescriptionNotes" maxLength={2147483647} rows={5} defaultValue={state.DescriptionNotes}></textarea></div></div>
                <div className="content-header">Deal OwnerShip & Management</div>



                <div className="col-md-4 combobox  " ><label htmlFor="SubmitterId">Submitter</label><select className="" id="DealDefinitionSubmitterId" name="SubmitterId" defaultValue={state.SubmitterId} required data-required-msg="Submitter is required"  >{state.SubmitterId && <option value={state.SubmitterId}>{state.SubmitterDisplayValue}</option>}</select></div>
		           <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="DealDefinitionSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="DealDefinitionSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarDealDefinition">
                <div className="left">
                  <button id="cancelDealDefinitionButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealDefinitionButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="DealDefinitionTabstrip">
                <ul>
                    <li id="DealDefinitionDealDefinitionInstance" className="k-active"><i className="fas fa-business-time"></i><span className="labeltext">Deal</span></li>
                    <li id="DealDefinitionDealDefinitionCondition"><i className="fas fa-sitemap"></i><span className="labeltext">Deal Definition Conditions</span></li>
                    <li id="DealDefinitionClientList"><i className="fas fa-user-friends"></i><span className="labeltext">Client List</span></li>
                </ul>
                <div>
                    <div id="associationDealDefinitionDealDefinitionInstance">
                      <div className="k-block grid-details">
                        <h2>Deal</h2>
                        <div id="DealDefinitionInstanceGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationDealDefinitionDealDefinitionCondition">
                      <div className="k-block grid-details">
                        <h2>Deal Definition Conditions <span style={{ color: "red", fontSize: "1rem", textTransform: "none" }}>(If an item below has been included, all other items are exlucded, and vice versa)</span></h2>
                        <div id="DealDefinitionConditionGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationDealDefinitionClientList">
                      <div className="k-block grid-details">
                        <h2>Client List</h2>
                        <div id="DealDefaultClientListGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </section>
    </div>
  );
}


