import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';
import { useSearchParams } from 'react-router-dom';
export function TradingMasterSpendCustomPage(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("Type")
  const currentSecurity = useSecurity('Trading', 'MasterSpend');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.MasterSpend.en.js',
          'Areas/Trading/Scripts/Resources/en/res.SpendIndex.en.js',
          'Areas/Trading/Scripts/Resources/en/res.SpendOutcome.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBook.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookTarget.en.js',
          'Areas/Trading/Scripts/Resources/en/res.DealBookMakeGood.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/MasterSpend/Index.custom.js',
          'Areas/Trading/Scripts/SpendIndex/View.custom.js',
          'Areas/Trading/Scripts/SpendOutcome/View.custom.js',
          'Areas/Trading/Scripts/SpendOutcome/AnnualTarget.custom.js',
          'Areas/Trading/Scripts/SpendOutcome/ViewSpread.custom.js',
        ],
        'initMasterSpend',
        {
          area: type,
          canEdit: true,
          canCreate: true,
          canRemove: true,
          appSettings: currentSecurity.profile.AppSettings.Values
        }
      );
    }
  }, []);
  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>SMBC Spend</h3>
          <div className="field yearcontrol-container">
            <label className="required">Fiscal Year</label>
            <input id="inputYear" />
          </div>
          <div className="field middlecontrol-container">
            <label className="required">Media Type</label>
            <input id="inputMediaType" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">SMBC</label>
            <input id="inputManagementType" />
          </div>
          {type == 'outcome' &&
            <div className="field shortcontrol-container">
              <label className="required">Reporting Period</label>
              <input id="inputDataType" />
            </div>
          }
          {type == 'outcome' && 
                <div className="field shortcontrol-container">
                    <label id="labelDealActivity">Snapshot</label>
                    <input id="inputDealActivity" />
                </div>
          }
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          <div className="field fullwidth-container">
            <label>Buying Agency</label>
            <input id="inputBuyingAgency" />
          </div>
          <div className="field fullwidth-container">
            <label id="labelSalesHouse">Sales House</label>
            <input id="inputSalesHouse" />
          </div>
          <div className="field fullwidth-container">
            <label>Client</label>
            <input id="inputClients" />
          </div>
          {type == 'setup' && 
            <div className="field shortcontrol-container">
              <label>Opt-In</label>
              <input id="inputOptIn" />
            </div>
          }
          {type == 'outcome' &&
            <div className="field checkbox-container">
              <label className="required">Calendar View</label>
              <input type="checkbox" id="inputSwitchView" />
            </div>
          }
          {type == 'outcome' && 
                <div className="field checkbox-container">
                    <label>Opt-In</label>
                    <input id="inputOptIn" />
                </div>
          }
          {type == 'outcome' && 
            <div style={{ display: 'block' }}></div>
          }
          {type == 'outcome' && 
                <div className="field datecontrol-container">
                    <label id="labelStartDate">Start Date</label>
                    <input id="inputStartDate" />
                </div>
          }
          {type == 'outcome' && 
                <div className="field datecontrol-container">
                    <label id="labelEndDate">End Date</label>
                    <input id="inputEndDate" />
                </div>
          }
          <section className="button-toolbar">
            <div className="left">
              {type == 'outcome' && 
                <button id="exportFilterButton" type="button" className="k-button" title="Report">Report</button>
              }
            </div>
            <div className="right"><button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">Go!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="MasterSpendContainer" className="k-content">
        </div>
      </div>
    </div>
  );
}