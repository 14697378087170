import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function PricingCostModelNewPage(): React.ReactElement {
  const [state, setState] = useState<IPricingCostModel>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Pricing', 'CostModel');
  const updatedState: IPricingCostModel = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.ValueFormat = 1;
            updatedState.CostBehaviour = 0;
            updatedState.Preferred = true;
      setState(updatedState);
      appendScriptsCallback(["Areas/Pricing/Scripts/Resources/en/res.CostModel.en.js",
        "Areas/Pricing/Scripts/Resources/en/Types.en.js",
        "Areas/Pricing/Scripts/Enums.js",
        "Areas/Pricing/Scripts/CostModel/View.js"], "initNewPricingCostModel", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="CostModel" className="controls-container default block">
              <h3>Create a New <b>Cost Models</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CostModelCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CostModelLabelBlockedCode">Blocked</label>
				<input id="CostModelCheckBlockedCode" defaultChecked name="CostModelCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CostModelEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CostModelEnglishName','CostModelNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CostModelNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CostModelEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CostModelEnglishShortName','CostModelNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CostModelNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="CostModelMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="CostModelMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="CostBehaviour" className="required">Cost behaviour</label>
      <select className="" id="CostModelCostBehaviour" name="CostBehaviour" value={state.CostBehaviour} required data-required-msg="Cost behaviour is required" >
        <option value="0">None</option>
        <option value="1">Lock prices</option>
        <option value="2">Lock discounts</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ValueFormat" className="required">Value Type</label>
      <select className="" id="CostModelValueFormat" name="ValueFormat" value={state.ValueFormat} required data-required-msg="Value Type is required" >
        <option value="3">Decimal Value</option>
        <option value="2">Integer Value</option>
        <option value="1">Currency Value</option>
        <option value="0">Percentage</option>
      </select>
    </div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="CostModelPreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CostModelSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarCostModel">
                <div className="left">
                  <button id="cancelCostModelButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCostModelButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IPricingCostModel {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  MediaSubtypeId?: string,
  MediaSubtypeDisplayValue?: string,
  CostBehaviour?: number,
  ValueFormat?: number,
  Preferred?: boolean,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum PricingValueTypeEnum { 'Decimal Value' = 3, 'Integer Value' = 2, 'Currency Value' = 1, 'Percentage' = 0 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum CostBehaviourTypeEnum { 'None' = 0, 'Lock prices' = 1, 'Lock discounts' = 2 }

export function PricingCostModelDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IPricingCostModel>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Pricing', 'CostModel');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Pricing", "CostModel", id).then(result => {
        setState(result.Value[0] as IPricingCostModel);
        appendScriptsCallback(["Areas/Pricing/Scripts/Resources/en/res.CostModel.en.js",
        "Areas/Pricing/Scripts/Resources/en/Types.en.js",
        "Areas/Pricing/Scripts/Enums.js",
        "Areas/Pricing/Scripts/CostModel/View.js"
        ,"Areas/Pricing/Scripts/Resources/en/res.CostUnit.en.js"
        ,"Areas/Pricing/Scripts/CostUnit/View.js"
        ], "initDetailPricingCostModel", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadPricingCostModelState'] = (data) => {
    setState(data.Value[0] as IPricingCostModel);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="CostModel" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Cost Models: <span>{state.Display}</span><span id="CostModelToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  {state.MediaSubtypeId && <span className='element'><label>Media Subtype: <label className="strong">{state.MediaSubtypeDisplayValue}</label></label></span>}
                  <span className='element'><label>Preferred: <label className="strong">{state.Preferred}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CostModelCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CostModelLabelBlockedCode">Blocked</label>
				<input id="CostModelCheckBlockedCode" defaultChecked name="CostModelCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="CostModelEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('CostModelEnglishName','CostModelNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="CostModelNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="CostModelEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('CostModelEnglishShortName','CostModelNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="CostModelNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="CostModelMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="CostModelMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="CostBehaviour" className="required">Cost behaviour</label>
      <select className="" id="CostModelCostBehaviour" name="CostBehaviour" value={state.CostBehaviour} required data-required-msg="Cost behaviour is required" >
        <option value="0">None</option>
        <option value="1">Lock prices</option>
        <option value="2">Lock discounts</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ValueFormat" className="required">Value Type</label>
      <select className="" id="CostModelValueFormat" name="ValueFormat" value={state.ValueFormat} required data-required-msg="Value Type is required" >
        <option value="3">Decimal Value</option>
        <option value="2">Integer Value</option>
        <option value="1">Currency Value</option>
        <option value="0">Percentage</option>
      </select>
    </div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="CostModelPreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CostModelSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="CostModelSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="CostModelSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarCostModel">
                <div className="left">
                  <button id="cancelCostModelButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCostModelButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationCostModelCostUnit">
              <div className="k-block grid-details">
                <h2>Cost Units</h2>
                <div id="CostUnitGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


