import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function GeneralLocationNewPage(): React.ReactElement {
  const [state, setState] = useState<IGeneralLocation>({ Id: ""  });
  const currentSecurity = useSecurity('General', 'Location');
  let updatedState: IGeneralLocation = { Id: ""  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.ZIPCode = "00000";
            getPreferredDetails("General", "Country").then((result) => {
              const resultcountry: any[] = result.Value;
              if (resultcountry && resultcountry.length > 0)
                updatedState = { ...updatedState, CountryId: resultcountry[0].Id, CountryDisplayValue: resultcountry[0].Display };
            getPreferredDetails("General", "City").then((result) => {
              const resultcity: any[] = result.Value;
              if (resultcity && resultcity.length > 0)
                updatedState = { ...updatedState, CityId: resultcity[0].Id, CityDisplayValue: resultcity[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/General/Scripts/Resources/en/res.Location.en.js",
        "Areas/General/Scripts/Resources/en/Types.en.js",
        "Areas/General/Scripts/Enums.js",
        "Areas/General/Scripts/Location/View.js"], "initNewGeneralLocation", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Location" className="controls-container default block">
              <h3>Create a New <b>Location</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="LocationCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="LocationLabelBlockedCode">Blocked</label>
				<input id="LocationCheckBlockedCode" defaultChecked name="LocationCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="LocationCountryId" name="CountryId" defaultValue={state.CountryId}   required data-required-msg="Country is required"  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="StateId">State</label><select className="" id="LocationStateId" name="StateId" defaultValue={state.StateId}   required data-required-msg="State is required"  >{state.StateId && <option value={state.StateId}>{state.StateDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProvinceId">Province</label><select className="" id="LocationProvinceId" name="ProvinceId" defaultValue={state.ProvinceId}   required data-required-msg="Province is required"  >{state.ProvinceId && <option value={state.ProvinceId}>{state.ProvinceDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CityId">City</label><select className="" id="LocationCityId" name="CityId" defaultValue={state.CityId}   required data-required-msg="City is required"  >{state.CityId && <option value={state.CityId}>{state.CityDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="LocationNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-4" ><label htmlFor="StreetAddress">Street Address</label><input  required data-required-msg="Street Address is required"  id="LocationStreetAddress" name="StreetAddress" type="text" defaultValue={state.StreetAddress} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="UTM">UTM</label><input  id="LocationUTM" name="UTM" type="text" defaultValue={state.UTM} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="ZIPCode">ZIP Code</label><input  id="LocationZIPCode" name="ZIPCode" type="text" defaultValue={state.ZIPCode} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="LocationSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarLocation">
                <div className="left">
                  <button id="cancelLocationButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveLocationButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IGeneralLocation {
  CountryId?: string,
  CountryDisplayValue?: string,
  StateId?: string,
  StateDisplayValue?: string,
  ProvinceId?: string,
  ProvinceDisplayValue?: string,
  CityId?: string,
  CityDisplayValue?: string,
  Notes?: string,
  StreetAddress?: string,
  UTM?: string,
  ZIPCode?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function GeneralLocationDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IGeneralLocation>({ Id: ""  });
  const currentSecurity = useSecurity('General', 'Location');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("General", "Location", id).then(result => {
        setState(result.Value[0] as IGeneralLocation);
        appendScriptsCallback(["Areas/General/Scripts/Resources/en/res.Location.en.js",
        "Areas/General/Scripts/Resources/en/Types.en.js",
        "Areas/General/Scripts/Enums.js",
        "Areas/General/Scripts/Location/View.js"
        ], "initDetailGeneralLocation", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadGeneralLocationState'] = (data) => {
    setState(data.Value[0] as IGeneralLocation);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Location" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Location: <span>{state.Display}</span><span id="LocationToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.CountryId && <span className='element'><label>Country: <label className="strong">{state.CountryDisplayValue}</label></label></span>}
                  {state.StateId && <span className='element'><label>State: <label className="strong">{state.StateDisplayValue}</label></label></span>}
                  {state.ProvinceId && <span className='element'><label>Province: <label className="strong">{state.ProvinceDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.CityId && <span className='element'><label>City: <label className="strong">{state.CityDisplayValue}</label></label></span>}
                  <span className='element'><label>Street Address: <label className="strong">{state.StreetAddress}</label></label></span>
                  <span className='element'><label>ZIP Code: <label className="strong">{state.ZIPCode}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="LocationCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="LocationLabelBlockedCode">Blocked</label>
				<input id="LocationCheckBlockedCode" defaultChecked name="LocationCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CountryId">Country</label><select className="" id="LocationCountryId" name="CountryId" defaultValue={state.CountryId}   required data-required-msg="Country is required"  >{state.CountryId && <option value={state.CountryId}>{state.CountryDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="StateId">State</label><select className="" id="LocationStateId" name="StateId" defaultValue={state.StateId}   required data-required-msg="State is required"  >{state.StateId && <option value={state.StateId}>{state.StateDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProvinceId">Province</label><select className="" id="LocationProvinceId" name="ProvinceId" defaultValue={state.ProvinceId}   required data-required-msg="Province is required"  >{state.ProvinceId && <option value={state.ProvinceId}>{state.ProvinceDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CityId">City</label><select className="" id="LocationCityId" name="CityId" defaultValue={state.CityId}   required data-required-msg="City is required"  >{state.CityId && <option value={state.CityId}>{state.CityDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="LocationNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-4" ><label htmlFor="StreetAddress">Street Address</label><input  required data-required-msg="Street Address is required"  id="LocationStreetAddress" name="StreetAddress" type="text" defaultValue={state.StreetAddress} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="UTM">UTM</label><input  id="LocationUTM" name="UTM" type="text" defaultValue={state.UTM} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="ZIPCode">ZIP Code</label><input  id="LocationZIPCode" name="ZIPCode" type="text" defaultValue={state.ZIPCode} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="LocationSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarLocation">
                <div className="left">
                  <button id="cancelLocationButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveLocationButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


