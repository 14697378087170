import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails, getEntity } from '../../../../services/ApiServices';

export interface ISecurityUser {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: 1;
    Source?: 0;
  };
  LoginName?: string;
  FirstName?: string;
  Description?: string;
  Email?: string;
  MiddleName?: string;
  LastName?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
  MediaList: ITradingMasterMediaCategory[];
}
interface ITradingMasterMediaCategory {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  Notes?: string,
  ActivityLevel?: number,
  BreakCriteria?: number,
  ForecastLevel?: number,
  TargetLevel?: number,
  Sequence?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

export function SecurityUserDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  let mediaList: ITradingMasterMediaCategory[] = [];
  const [state, setState] = useState<ISecurityUser>({ Id: '', LoadingInfo: {}, MediaList: [] });
  const currentSecurity = useSecurity('Master', 'User');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Security', 'User', id).then((result) => {
        getEntity('Trading', 'MasterMediaCategory').then((currentMedias) => {
          mediaList = currentMedias.Value as ITradingMasterMediaCategory[];
          result.Value[0].MediaList = mediaList;
          setState(result.Value[0] as ISecurityUser);
          appendScriptsCallback(
            [
              'Areas/Admin/Scripts/Resources/en/res.User.en.js',
              'Areas/Admin/Scripts/Resources/en/Types.en.js',
              'Areas/Admin/Scripts/Enums.js',
              'Areas/Admin/Scripts/AdminShare.js',
              'Areas/Admin/Scripts/User/View.custom.js',
              'Areas/Admin/Scripts/Resources/en/res.GroupUser.en.js',
              'Areas/Admin/Scripts/GroupUser/ViewUser.custom.js',
            ],
            'initDetailSecurityUser',
            {
              viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id,
              appSettings: currentSecurity.profile.AppSettings.Values,
              mediaList: mediaList
            },
          );
        });
      });
    }
  }, []);
  window['reloadSecurityUserState'] = (data) => {
    data.Value[0].MediaList = mediaList;
    setState(data.Value[0] as ISecurityUser);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="User" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      User: <span>{state.Display}</span>
                      <span id="UserToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }}></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line"></div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="UserCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="UserLabelBlockedCode">Blocked</label>
                  <input id="UserCheckBlockedCode" defaultChecked name="UserCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="field ">
                  <label htmlFor="LoginName">Login</label>
                  <input
                    data-required-msg="Login is required"
                    id="UserLoginName"
                    max="128"
                    min="1"
                    name="LoginName"
                    required
                    type="text"
                    defaultValue={state.LoginName}
                    maxLength={128}
                    pattern="^\S+"
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <h4>Name</h4>
                <div className="col-md-4">
                  <label htmlFor="FirstName">First Name</label>
                  <input
                    data-required-msg="First Name is required"
                    id="UserFirstName"
                    min="1"
                    name="FirstName"
                    required
                    type="text"
                    defaultValue={state.FirstName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="MiddleName">Middle Name</label>
                  <input
                    id="UserMiddleName"
                    name="MiddleName"
                    type="text"
                    defaultValue={state.MiddleName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="LastName">Last Name</label>
                  <input
                    id="UserLastName"
                    name="LastName"
                    type="text"
                    defaultValue={state.LastName}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="Email">Email</label>
                  <input
                    id="UserEmail"
                    name="Email"
                    type="text"
                    defaultValue={state.Email}
                    maxLength={250}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Description">Description</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="UserDescription"
                      name="Description"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Description}
                    ></textarea>
                  </div>
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="UserSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select className="" id="UserSourceStatus" name="SourceStatus" value={state.LoadingInfo.SourceStatus}>
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="UserSource" name="Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarUser">
                <div className="left">
                  <button id="cancelUserButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveUserButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationGroupGroupUser">
            <div className="k-content default block" style={{ margin: '1em' }} >
              <p>Welcome to the new user wizard, here you could choose the different permissions and data this user could see. Take in care, none of your changes will be save until you click "Save" button. If you want to discard your current changes only reload or close this window.</p>
              <div className="separator"></div>
              <div className="content-header">Administrator:</div>
              <div className="field fullwidth-container">
                <label>Set this switch to grant this user as Administrator</label>
                <input type="checkbox" id="UserIsAdmin" name="IsAdmin" />
              </div>
            </div>
            <div className="k-block" id="NonAdminPerm" style={{ margin: '1em' }}>
              <div className="content-header">Default Groups</div>
              <div className="field fullwidth-container">
                <label>Add here the list of operation profiles this user has</label>
                <input id="UserDefaultGroups" type="text" style={{ height: 'auto' }} />
              </div>
              <div className="separator"></div>
              <div className="k-content">
                <div className="content-header">Data Permissions</div>
                <p>Set here which medias this user can see. Take care, if you don't select at least one of them, this user could will see all of this kind.</p>
                <div style={{ marginLeft: '15px' }}>
                  {state.MediaList.map((item: ITradingMasterMediaCategory, i: number) => (
                    <div key={i}>
                      <div className="field checkbox-container">
                          <div className="content-header">{item.MediaTypeDisplayValue}</div>
                        <input type="checkbox" id={'UserHas-' + item.MediaTypeId} name={'Has-' + item.MediaTypeId}/>
                      </div>
                      <div className="clear"></div>
                      <div id={'Detail-'+item.MediaTypeId} style={{ display: 'none' }} className="two-column-layout">
                          <p>This are the list of companies and clients this user could see related with {item.MediaTypeDisplayValue}. Take in care, if you don't select at least one of them, this user could see all of this kind</p>
                        <div className="hierarchy-content-panel" style={{ margin: '0' }} >
                          <div className="content-header">Companies <div style={{ display: 'inline' }} onClick={() => window['GroupUserViewShowOnModal'](item.MediaTypeId, item.MediaTypeDisplayValue, 'Companies', 'Company')}><i className="fas fa-plus" style={{ cursor: 'pointer', marginLeft: '10px' }}></i></div></div>
                          <div id={'treeview-Company-'+item.MediaTypeId} style={{ height: '200px' }} className="hierarchy-tree-panel"></div>
                          </div>
                          <div className="hierarchy-content-panel" style={{ margin: '0' }}>
                          <div className="content-header">Clients
                            <div style={{ display: 'inline' }} onClick={() => window['GroupUserViewShowOnModal'](item.MediaTypeId, item.MediaTypeDisplayValue, 'Clients', 'Client')}><i className="fas fa-plus" style={{ cursor: 'pointer', marginLeft: '10px' }}></i></div>
                            <div style={{ display: 'inline' }} onClick={() => window['GroupUserViewDownloadClientList'](item.MediaTypeId, item.MediaTypeDisplayValue)}><i className="fas fa-download" style={{ cursor: 'pointer', marginLeft: '10px' }}></i></div>
                            <div style={{ display: 'inline' }} onClick={() => window['GroupUserViewUploadClientList'](item.MediaTypeId, item.MediaTypeDisplayValue)}><i className="fas fa-upload" style={{ cursor: 'pointer', marginLeft: '10px' }}></i></div></div>
                            <div id={'treeview-Client-' + item.MediaTypeId} style={{ height: '200px' }} className="hierarchy-tree-panel"></div>
                          </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="separator"></div>
              <div className="k-content ">
                <div className="content-header">Mapped Entities</div>
                <p>If you select this switch, the application hide restricted SMBC's for all clients but the selected ones</p>
                <div className="field checkbox-container">
                  <input type="checkbox" id="UserHasMapped" name="UserHasMapped"/>
                </div>
                <div id="MappedPerm" style={{ display: 'none' }} >
                  <div className="k-block grid-details">
                    <p>Select which SMBC this user could see for each client. Any client without SMBC selection will be remove of this list automatically when you save</p>
                    <div id="MappedGrid" className="grid-control"></div>
                  </div>
                </div>
              </div>
            </div>
            <section className="button-toolbar" id="ToolbarUser">
              <div className="right">
                <button id="saveUserGroupsButton" type="button" className="k-button button k-button-md k-rounded-md k-button-solid k-button-solid-base" title="Save" data-role="button" role="button" aria-disabled="false" tabIndex={0}><span className="k-icon k-i-save"></span>Save</button>
              </div>
            </section>
            <div id="UserDialogContainer">
              <div className="field shortcontrol-container combobox" style={{ width: '100%' }} id="AddCompanydiv">
                <input id="AddCompany" type="text"/>
              </div>
              <div className="field shortcontrol-container combobox" style={{ width: '100%' }} id="AddOfficediv">
                <input id="AddOffice" type="text"/>
              </div>
              <div className="field shortcontrol-container combobox" style={{ width: '100%' }} id="AddClientdiv">
                <input id="AddClient" type="text"/>
              </div>
              <div className="field shortcontrol-container combobox" style={{ width: '100%' }} id="AddClient2div">
                <input id="AddClient2" type="text"/>
              </div>
              <section className="button-toolbar">
                <div className="right">
                  <button id="addNewItem" type="button" className="k-button button k-button-md k-rounded-md k-button-solid k-button-solid-base" title="Save" data-role="button" role="button" aria-disabled="false" tabIndex={0}><span className="k-icon k-i-save"></span>Save</button>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>
      <div id="CheckDataContainer">
        <h1>Check Data Permissions</h1>
        <div className="field shortcontrol-container">
          <label className="required">Select Role</label>
          <input id="inputRole" />
        </div>
        <div className="field textareafield">
          <label>Resolved Data Permissions</label>
          <textarea className="k-input k-textbox" style={{ height: '250px' }} id="DataPermissionList"></textarea>
        </div>
        <div className="field textareafield">
          <label>Applied Data Permissions</label>
          <textarea className="k-input k-textbox" style={{ height: '350px' }} id="DataPermissionResolve"></textarea>
        </div>
      </div>
    </div>
  );
}
