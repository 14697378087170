import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function FinanceManagementTypeNewPage(): React.ReactElement {
  const [state, setState] = useState<IFinanceManagementType>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Finance', 'ManagementType');
  const updatedState: IFinanceManagementType = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Type = 0;
            updatedState.RelatedOptIn = 6;
            updatedState.Preferred = true;
      setState(updatedState);
      appendScriptsCallback(["Areas/Finance/Scripts/Resources/en/res.ManagementType.en.js",
        "Areas/Finance/Scripts/Resources/en/Types.en.js",
        "Areas/Finance/Scripts/Enums.js",
        "Areas/Finance/Scripts/ManagementType/View.js"], "initNewFinanceManagementType", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="ManagementType" className="controls-container default block">
              <h3>Create a New <b>SMBCs</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ManagementTypeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ManagementTypeLabelBlockedCode">Blocked</label>
				<input id="ManagementTypeCheckBlockedCode" defaultChecked name="ManagementTypeCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ManagementTypeEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ManagementTypeEnglishName','ManagementTypeNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ManagementTypeNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ManagementTypeEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ManagementTypeEnglishShortName','ManagementTypeNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ManagementTypeNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Restricted to</label><select className="" id="ManagementTypeMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="ManagementTypeMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="RelatedOptIn" className="required">Opt-In</label>
      <select className="" id="ManagementTypeRelatedOptIn" name="RelatedOptIn" value={state.RelatedOptIn} required data-required-msg="Opt-In is required" >
        <option value="0">Opt-In Xaxis</option>
        <option value="1">Opt-In Performance</option>
        <option value="2">Opt-In Content</option>
        <option value="3">Opt-In Inventory</option>
        <option value="4">Opt-In OOH</option>
        <option value="5">ALL Opt-Ins</option>
        <option value="6">None (without Opt-Ins)</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Typology</label>
      <select className="" id="ManagementTypeType" name="Type" value={state.Type} required data-required-msg="Typology is required" >
        <option value="0">Standard</option>
        <option value="1">Inventory</option>
        <option value="2">Performance</option>
        <option value="3">Content</option>
        <option value="4">Others</option>
      </select>
    </div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="ManagementTypePreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ManagementTypeSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarManagementType">
                <div className="left">
                  <button id="cancelManagementTypeButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveManagementTypeButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IFinanceManagementType {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  MediaSubtypeId?: string,
  MediaSubtypeDisplayValue?: string,
  RelatedOptIn?: number,
  Type?: number,
  Preferred?: boolean,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum OptInProductEnum { 'Opt-In Xaxis' = 0, 'Opt-In Performance' = 1, 'Opt-In Content' = 2, 'Opt-In Inventory' = 3, 'Opt-In OOH' = 4, 'ALL Opt-Ins' = 5, 'None (without Opt-Ins)' = 6 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ManagementEnum { 'Standard' = 0, 'Inventory' = 1, 'Performance' = 2, 'Content' = 3, 'Others' = 4 }

export function FinanceManagementTypeDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IFinanceManagementType>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Finance', 'ManagementType');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Finance", "ManagementType", id).then(result => {
        setState(result.Value[0] as IFinanceManagementType);
        appendScriptsCallback(["Areas/Finance/Scripts/Resources/en/res.ManagementType.en.js",
        "Areas/Finance/Scripts/Resources/en/Types.en.js",
        "Areas/Finance/Scripts/Enums.js",
        "Areas/Finance/Scripts/ManagementType/View.js"
        ], "initDetailFinanceManagementType", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadFinanceManagementTypeState'] = (data) => {
    setState(data.Value[0] as IFinanceManagementType);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ManagementType" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>SMBCs: <span>{state.Display}</span><span id="ManagementTypeToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MediaTypeId && <span className='element'><label>Restricted to: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  {state.MediaSubtypeId && <span className='element'><label>Media Subtype: <label className="strong">{state.MediaSubtypeDisplayValue}</label></label></span>}
                  <span className='element'><label>Typology: <label className="strong">{state.Type != undefined ? ManagementEnum[state.Type] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ManagementTypeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ManagementTypeLabelBlockedCode">Blocked</label>
				<input id="ManagementTypeCheckBlockedCode" defaultChecked name="ManagementTypeCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ManagementTypeEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ManagementTypeEnglishName','ManagementTypeNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ManagementTypeNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ManagementTypeEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ManagementTypeEnglishShortName','ManagementTypeNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ManagementTypeNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Restricted to</label><select className="" id="ManagementTypeMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaSubtypeId">Media Subtype</label><select className="" id="ManagementTypeMediaSubtypeId" name="MediaSubtypeId" defaultValue={state.MediaSubtypeId}  >{state.MediaSubtypeId && <option value={state.MediaSubtypeId}>{state.MediaSubtypeDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="RelatedOptIn" className="required">Opt-In</label>
      <select className="" id="ManagementTypeRelatedOptIn" name="RelatedOptIn" value={state.RelatedOptIn} required data-required-msg="Opt-In is required" >
        <option value="0">Opt-In Xaxis</option>
        <option value="1">Opt-In Performance</option>
        <option value="2">Opt-In Content</option>
        <option value="3">Opt-In Inventory</option>
        <option value="4">Opt-In OOH</option>
        <option value="5">ALL Opt-Ins</option>
        <option value="6">None (without Opt-Ins)</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Typology</label>
      <select className="" id="ManagementTypeType" name="Type" value={state.Type} required data-required-msg="Typology is required" >
        <option value="0">Standard</option>
        <option value="1">Inventory</option>
        <option value="2">Performance</option>
        <option value="3">Content</option>
        <option value="4">Others</option>
      </select>
    </div>
		<div className="col-md-2"><label htmlFor="Preferred">Preferred</label><input type="checkbox" id="ManagementTypePreferred" name="Preferred" defaultChecked={state.Preferred} /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ManagementTypeSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ManagementTypeSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ManagementTypeSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarManagementType">
                <div className="left">
                  <button id="cancelManagementTypeButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveManagementTypeButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


