import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingMasterBreakNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingMasterBreak>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterBreak');
  const updatedState: ITradingMasterBreak = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Year = new Date().getFullYear().toString();
            updatedState.StartDate = new Date().toString();
            updatedState.EndDate = new Date().toString();
            const date = new Date();
            const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
            const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
            updatedState.StartDate = startDate.toISOString();
            updatedState.EndDate = endDate.toISOString();
            const difference = startDate.getTime() - endDate.getTime();
            const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            updatedState.BreakNo = TotalDays / 7;
            updatedState.BreakCriteria = 2;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterBreak.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterBreak/View.js"], "initNewTradingMasterBreak", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MasterBreak" className="controls-container default block">
              <h3>Create a New <b>Master Breaks</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterBreakCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterBreakLabelBlockedCode">Blocked</label>
				<input id="MasterBreakCheckBlockedCode" defaultChecked name="MasterBreakCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="MasterBreakAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="MasterBreakBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="MasterBreakStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="MasterBreakEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="BreakNo">#</label><input  required data-required-msg="# is required"  data-val-number="The field # must be a number." id="MasterBreakBreakNo" name="BreakNo" type="text" defaultValue={state.BreakNo} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Month">Month</label><input  required data-required-msg="Month is required"  data-val-number="The field Month must be a number." id="MasterBreakMonth" name="Month" type="text" defaultValue={state.Month} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Quarter">Quarter</label><input  required data-required-msg="Quarter is required"  data-val-number="The field Quarter must be a number." id="MasterBreakQuarter" name="Quarter" type="text" defaultValue={state.Quarter} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Year">Year</label><input  required data-required-msg="Year is required"  data-val-number="The field Year must be a number." id="MasterBreakYear" name="Year" type="text" defaultValue={state.Year} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterBreakSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMasterBreak">
                <div className="left">
                  <button id="cancelMasterBreakButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterBreakButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingMasterBreak {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  Alias?: string,
  BreakCriteria?: number,
  StartDate?: string,
  EndDate?: string,
  BreakNo?: number,
  Month?: number,
  Quarter?: number,
  Year?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }

export function TradingMasterBreakDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingMasterBreak>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterBreak');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "MasterBreak", id).then(result => {
        setState(result.Value[0] as ITradingMasterBreak);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterBreak.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterBreak/View.js"
        ], "initDetailTradingMasterBreak", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingMasterBreakState'] = (data) => {
    setState(data.Value[0] as ITradingMasterBreak);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterBreak" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Master Breaks: <span>{state.Display}</span><span id="MasterBreakToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  <span className='element'><label>Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                  <span className='element'><label>Start Date: <label className="strong">{formatDate(state.StartDate)}</label></label></span>
                  <span className='element'><label>End Date: <label className="strong">{formatDate(state.EndDate)}</label></label></span>
                </div>
                <div className='content-line'>
                  <span className='element'><label>#: <label className="strong">{state.BreakNo}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterBreakCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterBreakLabelBlockedCode">Blocked</label>
				<input id="MasterBreakCheckBlockedCode" defaultChecked name="MasterBreakCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="Alias">Alias</label><input  required data-required-msg="Alias is required"  id="MasterBreakAlias" name="Alias" type="text" defaultValue={state.Alias} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="MasterBreakBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="MasterBreakStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="MasterBreakEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="BreakNo">#</label><input  required data-required-msg="# is required"  data-val-number="The field # must be a number." id="MasterBreakBreakNo" name="BreakNo" type="text" defaultValue={state.BreakNo} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Month">Month</label><input  required data-required-msg="Month is required"  data-val-number="The field Month must be a number." id="MasterBreakMonth" name="Month" type="text" defaultValue={state.Month} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Quarter">Quarter</label><input  required data-required-msg="Quarter is required"  data-val-number="The field Quarter must be a number." id="MasterBreakQuarter" name="Quarter" type="text" defaultValue={state.Quarter} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Year">Year</label><input  required data-required-msg="Year is required"  data-val-number="The field Year must be a number." id="MasterBreakYear" name="Year" type="text" defaultValue={state.Year} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterBreakSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MasterBreakSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MasterBreakSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMasterBreak">
                <div className="left">
                  <button id="cancelMasterBreakButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterBreakButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


