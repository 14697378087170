import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingAnnualTargetNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingAnnualTarget>({ Id: ""  });
  const currentSecurity = useSecurity('Trading', 'AnnualTarget');
  const updatedState: ITradingAnnualTarget = { Id: ""  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualTarget.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/AnnualTarget/View.js"], "initNewTradingAnnualTarget", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="AnnualTarget" className="controls-container default block">
              <h3>Create a New <b>Trading Spend Targets</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="AnnualTargetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="AnnualTargetLabelBlockedCode">Blocked</label>
				<input id="AnnualTargetCheckBlockedCode" defaultChecked name="AnnualTargetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="AnnualTargetAgencyId" name="AgencyId" defaultValue={state.AgencyId}  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterBreakId">#Break</label><select className="" id="AnnualTargetMasterBreakId" name="MasterBreakId" defaultValue={state.MasterBreakId}   required data-required-msg="#Break is required"  >{state.MasterBreakId && <option value={state.MasterBreakId}>{state.MasterBreakDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterAnnualTargetId">Targets Setup</label><select className="" id="AnnualTargetMasterAnnualTargetId" name="MasterAnnualTargetId" defaultValue={state.MasterAnnualTargetId}   required data-required-msg="Targets Setup is required"  >{state.MasterAnnualTargetId && <option value={state.MasterAnnualTargetId}>{state.MasterAnnualTargetDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="Target">Target</label><input  required data-required-msg="Target is required"  data-val-number="The field Target must be a number." id="AnnualTargetTarget" name="Target" type="text" defaultValue={state.Target} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="AnnualTargetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarAnnualTarget">
                <div className="left">
                  <button id="cancelAnnualTargetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveAnnualTargetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingAnnualTarget {
  AgencyId?: string,
  AgencyDisplayValue?: string,
  MasterBreakId?: string,
  MasterBreakDisplayValue?: string,
  MasterAnnualTargetId?: string,
  MasterAnnualTargetDisplayValue?: string,
  Target?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function TradingAnnualTargetDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingAnnualTarget>({ Id: ""  });
  const currentSecurity = useSecurity('Trading', 'AnnualTarget');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "AnnualTarget", id).then(result => {
        setState(result.Value[0] as ITradingAnnualTarget);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualTarget.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/AnnualTarget/View.js"
        ], "initDetailTradingAnnualTarget", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingAnnualTargetState'] = (data) => {
    setState(data.Value[0] as ITradingAnnualTarget);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AnnualTarget" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Trading Spend Targets: <span>{state.Display}</span><span id="AnnualTargetToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                  {state.MasterBreakId && <span className='element'><label>#Break: <label className="strong">{state.MasterBreakDisplayValue}</label></label></span>}
                  {state.MasterAnnualTargetId && <span className='element'><label>Targets Setup: <label className="strong">{state.MasterAnnualTargetDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="AnnualTargetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="AnnualTargetLabelBlockedCode">Blocked</label>
				<input id="AnnualTargetCheckBlockedCode" defaultChecked name="AnnualTargetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="AnnualTargetAgencyId" name="AgencyId" defaultValue={state.AgencyId}  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterBreakId">#Break</label><select className="" id="AnnualTargetMasterBreakId" name="MasterBreakId" defaultValue={state.MasterBreakId}   required data-required-msg="#Break is required"  >{state.MasterBreakId && <option value={state.MasterBreakId}>{state.MasterBreakDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterAnnualTargetId">Targets Setup</label><select className="" id="AnnualTargetMasterAnnualTargetId" name="MasterAnnualTargetId" defaultValue={state.MasterAnnualTargetId}   required data-required-msg="Targets Setup is required"  >{state.MasterAnnualTargetId && <option value={state.MasterAnnualTargetId}>{state.MasterAnnualTargetDisplayValue}</option>}</select></div>
		<div className="col-md-2" ><label htmlFor="Target">Target</label><input  required data-required-msg="Target is required"  data-val-number="The field Target must be a number." id="AnnualTargetTarget" name="Target" type="text" defaultValue={state.Target} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="AnnualTargetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarAnnualTarget">
                <div className="left">
                  <button id="cancelAnnualTargetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveAnnualTargetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}


