import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';
const random = Math.floor(Math.random() * 100000);
export function IndexRevenuePeriodPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AgencyProfilePeriod');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.RevenueForecastStream.en.js',
          'Areas/Trading/Scripts/Resources/en/res.ClientProfilePeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfilePeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.RevenueStream.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/IndexTree.custom.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
        ],
        'initSearchAgencyProfileTreeView',
        { random: random },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <h2 style={{ paddingTop: '1em' }}>Forecast Periods Hierarchy</h2>
      <div
        id="main-section-content"
        className="row"
        style={{ paddingTop: '0 !important', backgroundColor: 'inherit', height: 'calc(100vh - 11rem)' }}
      >
        <div id="agencyprofile-details" className="col-md-4" style={{ height: '100%' }}>
          <div
            className="k-content"
            style={{ paddingTop: '0', height: '100%', marginTop: '0', paddingBottom: '0', marginBottom: '0' }}
          >
            <div
              id="treeview-container"
              style={{ backgroundColor: 'rgba(10, 39, 86, 0.05)', height: '100%', overflow: 'auto', padding: '1em' }}
            >
              <div id="treeview-left"></div>
            </div>
          </div>
        </div>
        <div id="agencyprofile-content" className="col-md-8" style={{ height: '100%', overflow: 'auto' }}>
          <div id="manageperiod-Content">
            <div id="manageperiod">
              <div id="mode1" style={{ display: 'none' }}>
                <h3>Creation Of Forecast Periods</h3>
                <div id="manageperiod-addperiod" className="k-content row">
                  <div>
                    <p>Create ALL periods for an specific year with a default break criteria</p>
                  </div>
                  <div className="col-md-4 input-forecast-year">
                    <label>Forecast Year</label>
                    <input id="inputForecastYear" />
                    <i
                      className="fas fa-info-circle"
                      id="iAvailableYear"
                      style={{ display: 'none', color: '#dc3545' }}
                      title="There are already PERIODS on the select year with spend data. Please validate the information and delete all of them prior to create new configuration."
                    ></i>
                  </div>

                  <div className="col-md-4">
                    <label>Status</label>
                    <input id="inputStatus" />
                  </div>
                  <div className="clear"></div>
                  <ul id="pWarnings" style={{ display: 'none' }}>
                    <li>
                      <i className="fas fa-info-circle"></i>There are already PERIODS on the select year with spend
                      data. Please validate the information and delete all of them prior to create new configuration.
                    </li>
                    <li>
                      <div className="field checkbox-container" style={{ width: '500px' }}>
                        <label>This action may cause data loss; do you want to continue anyway? </label>
                        <input
                          id="inputContinueAnyway"
                          aria-label="This action may cause data loss; do you want to continue anyway?"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="clear"></div>
                <section className="button-toolbar">
                  <div className="left">
                    <button id="cancelManageperiod" type="button" className="k-button button">
                      Cancel
                    </button>
                  </div>
                  <div className="right">
                    <button id="acceptManagePeriod" type="button" className="k-button button k-primary">
                      Save
                    </button>
                  </div>
                </section>
              </div>
              <div id="mode02" style={{ display: 'none' }}>
                <h3>Current Status of the Period</h3>
                <div id="manageperiod-closingperiod">
                  <div className="dialog-content">
                    <div id="inputAgencyProfileStatus" className="dialog-status"></div>
                    <div className="dialog-item">
                      <div id="inputAgencyProfilePeriod" className="dialog-title"></div>
                    </div>
                    <div className="clear"></div>
                    <div className="dialog-item">
                      <label>Total</label>
                      <div id="inputTotalClientProfile" className="dialog-kpi"></div>
                      <i className="fas fa-thermometer-empty" id="iNotOK" style={{ display: 'none' }}></i>
                      <i className="fas fa-thermometer-full" id="iOK" style={{ display: 'none' }}></i>
                      <div id="inputTotalClientProfilePercent" className="dialog-progress"></div>
                    </div>
                    <div className="dialog-item">
                      <label>Total Forecast</label>
                      <div id="inputTotalForecast" className="dialog-spend"></div>
                    </div>

                   

                    <div className="dialog-item">
                      <label>Total Billed</label>
                      <div id="inputTotalBilled" className="dialog-spend"></div>
                    </div>
                    

                    <div className="dialog-item">
                      <label>YTD Billed + Forecast</label>
                      <div id="inputTotalSpend" className="dialog-spend"></div>
                    </div>
                    
                    <div className="agencyProfilePeriodId">
                      <div style={{ marginTop: '10px' }}>
                        <p>
                          <i className="fas fa-life-ring"></i> Remember that ANY action performed at this level will be
                          applied to ALL the related Client Forecasts. We strongly recommend that you carefully review
                          any action before changing the status of the forecast periods.
                        </p>
                      </div>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ marginTop: '10px' }}></div>
                    </div>
                    <div id="periodWorflow" className="button-toolbar" style={{ float: 'none', display: 'flex' }}>
                      <button id="openManageperiod" type="button" className="k-button button">
                        Open
                      </button>
                      <button id="errorManageperiod" type="button" className="k-button button">
                        Reopen
                      </button>
                      <button id="submitManageperiod" type="button" className="k-button button">
                        Submit
                      </button>
                      <button id="approvedManageperiod" type="button" className="k-button button">
                        Approve
                      </button>
                      <button id="closedManageperiod" type="button" className="k-button button">
                        Close
                      </button>
                      <div className="clientProfilePeriodId">
                        <button id="overwriteManageperiod" type="button" className="k-button button">
                          Copy
                        </button>
                        <button id="cleanManageperiod" type="button" className="k-button button">
                          Clean
                        </button>
                      </div>
                      <div className="agencyProfilePeriodId">
                        <button id="overwriteManageperiod2" type="button" className="k-button button">
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="ClosingStatusChart" style={{ display: 'none' }}>
                    <div className="agencyProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="criteria-chart"></div>
                      </div>
                      <h3>&nbsp;</h3>
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="secondcriteria-chart"></div>
                      </div>
                    </div>
                    <div className="clientProfilePeriodId">
                      <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <div className="clientcriteria-chart"></div>
                      </div>
                    </div>
                  </div>
                  <div id="NoData" style={{ display: 'none' }}>
                    <p>
                      <i className="fas fa-bug"></i>The selected PERIOD does not have any spend. It is completely empty.
                      For further information please contact your Forecast Administrator.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id={'dialogSelect-' + random}>
              <div className="dialogSelectContent">
                <input id="filterTextselect" type="text" placeholder="Search" />
                <div className="selectAll">
                  <input type="checkbox" id="chbAll" className="k-checkbox k-checkbox-md k-rounded-md" />
                  <label className="k-checkbox-label" htmlFor="chbAll">
                    Select All
                  </label>
                  <span id="result">0 elements selected</span>
                </div>
                <div id={'treeviewSelect_' + random} className="treeviewSelect" style={{ minHeight: '400px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
