import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function ReporterPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'DealBook');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/jquery.reporter.custom.js'],
        'initNewReporter', { appSettings: currentSecurity.profile.AppSettings.Values });
    }
  }, []);

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content" id="Reportslist">
          <h3>Choose Your Report</h3>
          <p>Click a report&#39;s name to select it. Using the reports tabs you can access all functionalities and settings for this report.</p>
        </div>
      </div>
      <div id="content-section" className="collapsed" style={{display:'none'}}>
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div className="k-content row" style={{ margin: '2em' }} >
          <h3><span id="criteriaSelectedReport"></span></h3>
          <p>We have almost finished, please verify that all the information is correct.  If so, click on the DOWNLOAD button to proceed with the generation of your report.</p>
          <div className="col-md-4 show-rt show-rt-1 show-rt-4 show-rt-10 show-rt-12 show-rt-13 show-rt-15 show-rt-16" id="containerReporterDataType">
            <label id="labelReporterDataType" className="required">Reporting Period</label>
            <input id="inputReporterDataType" />
          </div>
          <div className="col-md-4 show-rt show-rt-10" id="containerReporterSpendType">
            <label id="labelReporterSpendType" className="required">Report Type</label>
            <input id="inputReporterSpendType" />
          </div>
          <div className="clear"></div>
          <div className="col-md-2 show-rt show-rt-1 show-rt-4 show-rt-5 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-12 show-rt-14 show-live show-all" id="containerReporterStartDate">
            <label id="labelReporterStartDate" className="required">Start Date</label>
            <input id="inputReporterStartDate" />
          </div>
          <div className="col-md-2 show-rt show-rt-1 show-rt-4 show-rt-5 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-12 show-rt-14 show-live show-all" id="containerReporterEndDate">
            <label id="labelReporterEndDate" className="required">End Date</label>
            <input id="inputReporterEndDate" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-11 show-rt-12 show-rt-13 show-rt-15 show-rt-16 show-all show-net" id="containerReporterMedia">
            <label id="labelReporterMediaType" className="required">Media Type</label>
            <input id="inputReporterMediaType" />
          </div>
          <div className="col-md-2 show-rt show-rt-2 show-rt-3 show-rt-6 show-rt-10 show-rt-12 show-rt-13 show-rt-15 show-rt-16 show-rt-17 show-snapshot" id="containerReporterYear">
            <label id="labelReporterYear" className="required">Fiscal Year</label>
            <input id="inputReporterYear" />
          </div>
          <div className="col-md-4 show-rt show-rt-13" id="containerReporterDealBookPeriod">
            <label id="labelReporterDealBookPeriod" className="required">DealBook Period</label>
            <input id="inputReporterDealBookPeriod" />
          </div>
          <div className="field middlecontrol-container show-rt show-rt-13" id="containerReporterDealBookMeeting">
            <label id="labelReporterDealBookMeeting" className="required">DealBook Meeting</label>
            <input id="inputReporterDealBookMeeting"/>
          </div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-9 show-rt-10 show-rt-12 show-rt-15 show-snapshot" id="containerReporterDealActivity">
            <label id="labelReporterDealActivity" className="required">Version Date <span id="snapshotDateWarning" style={{ color: 'red', display: 'none' }} >Warning - The snapshot you have selected will contain LIVE data for out-of-deal SMBC's</span></label>
            <input id="inputReporterDealActivity" />
          </div>
          <div className="col-md-4 show-rt show-rt-13 show-snapshot" id="containerReporterDealActivityMeeting">
            <label id="labelReporterDealActivityMeeting" className="required">Version Date</label>
            <input id="inputReporterDealActivityMeeting" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-15" id="containerReporterRevenueStream">
            <label>Revenue Stream</label>
            <input id="inputReporterRevenueStream" />
          </div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-12 show-md-rd" id="containerReporterSpendKind">
            <label id="labelReporterSpendKind" className="required">Spend Type</label>
            <input id="inputReporterSpendKind" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-12 show-rt-14 show-rt-15 show-all show-net" id="containerReporterAgency">
            <label id="labelReporterAgency">Agency</label>
            <input id="inputReporterAgency" />
          </div>
          <div className="col-md-4 show-rt show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-12 show-all show-net" id="containerReporterBuyingAgency">
            <label id="labelReporterBuyingAgency">Buying Agency</label>
            <input id="inputReporterBuyingAgency" />
          </div>
          <div className="field largecontrol-container show-rt show-rt-13 show-rt-16 show-rt-17" id="containerReporterAgencies">
            <label id="labelReporterAgencies">Agency</label>
            <input id="inputReporterAgencies" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-15" id="containerReporterForecastYear">
            <label className="required">Forecast Year</label>
            <input id="inputReporterForecastYear" />
          </div>

          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-10 show-rt-12 show-all" id="containerReporterClientSet">
            <label>Client Set</label>
            <input id="inputReporterClientSet" />
          </div>
          <div className="col-md-4 show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-10 show-rt-12 show-rt-14 show-rt-15 show-all" id="containerReporterMasterClient">
            <label id="labelReporterMasterClient">Master Client</label>
            <input id="inputReporterMasterClient" />
          </div>
          <div className="col-md-4  show-rt show-rt-1 show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-10 show-rt-12 show-rt-14 show-rt-15 show-all" id="containerReporterClient">
            <label id="labelReporterClient">Client</label>
            <input id="inputReporterClient" />
          </div>
          <div className="col-md-4 show-rt show-rt-2 show-rt-5 show-rt-7 show-rt-10 show-rt-12 show-all" id="containerReporterProduct">
            <label>Product</label>
            <input id="inputReporterProduct" />
          </div>
          <div className="clear"></div>
          <div className="field middlecontrol-container show-rt show-rt-2 show-rt-5 show-rt-7 show-rt-10 show-rt-12 show-all" id="containerReporterCampaign">
            <label>Campaign</label>
            <input id="inputReporterCampaign" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-2 show-rt-3 show-rt-4 show-rt-5 show-rt-6 show-rt-7 show-rt-8 show-rt-9 show-rt-10 show-rt-12 show-all show-net" id="containerReporterSales">
            <label id="labelReporterSalesHouse">Sales House</label>
            <input id="inputReporterSalesHouse" />
          </div>
          <div className="col-md-4  show-rt show-rt-2 show-rt-5 show-rt-10 show-rt-12 show-all" id="containerReporterMediaVehicle">
            <label>Media Vehicle</label>
            <input id="inputReporterMediaVehicle" />
          </div>
          <div className="col-md-4 show-rt show-rt-2 show-rt-7 show-rt-9 show-rt-10 show-rt-12 show-all" id="containerReporterManagementType">
            <label>SMBC</label>
            <input id="inputReporterManagementType" />
          </div>
          <div className="clear"></div>
          <div className="col-md-4 show-rt show-rt-2 show-rt-3 show-rt-4 show-rt-10 show-rt-12 show-all show-net" id="containerReporterDeal">
            <label id="labelReporterDealDefinitionInstance">Deal</label>
            <input id="inputReporterDealDefinitionInstance" />
          </div>
          <section className="button-toolbar">
            <div className="left"></div>
            <div className="right"><button id="ReporterPrintButton" type="button" className="k-button k-primary" title="Download">Download</button></div>
          </section>
        </div>
      </div>
    </div>
  );
}
