import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingMasterMediaCategoryNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingMasterMediaCategory>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterMediaCategory');
  const updatedState: ITradingMasterMediaCategory = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.TargetLevel = 0;
            updatedState.ForecastLevel = 1;
            updatedState.ActivityLevel = 1;
            updatedState.BreakCriteria = 3;
            //updatedState.Source = 1;
            updatedState.Sequence = 1;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterMediaCategory.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterMediaCategory/View.js"], "initNewTradingMasterMediaCategory", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MasterMediaCategory" className="controls-container default block">
              <h3>Create a New <b>Master Media Category</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterMediaCategoryCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterMediaCategoryLabelBlockedCode">Blocked</label>
				<input id="MasterMediaCategoryCheckBlockedCode" defaultChecked name="MasterMediaCategoryCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MasterMediaCategoryMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="MasterMediaCategoryNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ActivityLevel" className="required">Activity Level</label>
      <select className="" id="MasterMediaCategoryActivityLevel" name="ActivityLevel" value={state.ActivityLevel} required data-required-msg="Activity Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Default Break Criteria</label>
      <select className="" id="MasterMediaCategoryBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Default Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ForecastLevel" className="required">Forecast Level</label>
      <select className="" id="MasterMediaCategoryForecastLevel" name="ForecastLevel" value={state.ForecastLevel} required data-required-msg="Forecast Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="TargetLevel" className="required">Target Level</label>
      <select className="" id="MasterMediaCategoryTargetLevel" name="TargetLevel" value={state.TargetLevel} required data-required-msg="Target Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="Sequence">Sequence</label><input  required data-required-msg="Sequence is required"  data-val-number="The field Sequence must be a number." id="MasterMediaCategorySequence" name="Sequence" type="text" defaultValue={state.Sequence} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterMediaCategorySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMasterMediaCategory">
                <div className="left">
                  <button id="cancelMasterMediaCategoryButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterMediaCategoryButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingMasterMediaCategory {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  Notes?: string,
  ActivityLevel?: number,
  BreakCriteria?: number,
  ForecastLevel?: number,
  TargetLevel?: number,
  Sequence?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum TargetLevelEnum { 'Major Sales House' = 0, 'Sales House' = 1, 'Channel' = 3, 'Media Vehicle Group' = 4, 'Media Vehicle' = 5 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum SpendSourceEnum { 'Campaigns' = 0, 'Forecast' = 1 }

export function TradingMasterMediaCategoryDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingMasterMediaCategory>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterMediaCategory');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "MasterMediaCategory", id).then(result => {
        setState(result.Value[0] as ITradingMasterMediaCategory);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterMediaCategory.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterMediaCategory/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterSalesHouse.en.js"
        ,"Areas/Trading/Scripts/MasterSalesHouse/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterRebate.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.RebateSetup.en.js"
        ,"Areas/Trading/Scripts/MasterRebate/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterMediaVehicle.en.js"
        ,"Areas/Trading/Scripts/MasterMediaVehicle/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterChannelType.en.js"
        ,"Areas/Trading/Scripts/MasterChannelType/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterManagementType.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.MasterAnnualTarget.en.js"
        ,"Areas/Trading/Scripts/MasterManagementType/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DefaultAgencyShare.en.js"
        ,"Areas/Trading/Scripts/DefaultAgencyShare/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.AdjustmentFactor.en.js"
        ,"Areas/Trading/Scripts/AdjustmentFactor/View.js"
        ], "initDetailTradingMasterMediaCategory", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingMasterMediaCategoryState'] = (data) => {
    setState(data.Value[0] as ITradingMasterMediaCategory);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterMediaCategory" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Master Media Category: <span>{state.Display}</span><span id="MasterMediaCategoryToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  <span className='element'><label>Default Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterMediaCategoryCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterMediaCategoryLabelBlockedCode">Blocked</label>
				<input id="MasterMediaCategoryCheckBlockedCode" defaultChecked name="MasterMediaCategoryCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MasterMediaCategoryMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="MasterMediaCategoryNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ActivityLevel" className="required">Activity Level</label>
      <select className="" id="MasterMediaCategoryActivityLevel" name="ActivityLevel" value={state.ActivityLevel} required data-required-msg="Activity Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Default Break Criteria</label>
      <select className="" id="MasterMediaCategoryBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Default Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ForecastLevel" className="required">Forecast Level</label>
      <select className="" id="MasterMediaCategoryForecastLevel" name="ForecastLevel" value={state.ForecastLevel} required data-required-msg="Forecast Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="TargetLevel" className="required">Target Level</label>
      <select className="" id="MasterMediaCategoryTargetLevel" name="TargetLevel" value={state.TargetLevel} required data-required-msg="Target Level is required" >
        <option value="0">Major Sales House</option>
        <option value="1">Sales House</option>
        <option value="3">Channel</option>
        <option value="4">Media Vehicle Group</option>
        <option value="5">Media Vehicle</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="Sequence">Sequence</label><input  required data-required-msg="Sequence is required"  data-val-number="The field Sequence must be a number." id="MasterMediaCategorySequence" name="Sequence" type="text" defaultValue={state.Sequence} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterMediaCategorySourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MasterMediaCategorySourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MasterMediaCategorySource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMasterMediaCategory">
                <div className="left">
                  <button id="cancelMasterMediaCategoryButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterMediaCategoryButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="MasterMediaCategoryTabstrip">
                <ul>
                    <li id="MasterMediaCategoryMasterSalesHouse" className="k-active"><i className="fas fa-laptop"></i><span className="labeltext">Sales Houses Setup</span></li>
                    <li id="MasterMediaCategoryMasterRebate"><i className="fas fa-clipboard-check"></i><span className="labeltext">Rebates Setup</span></li>
                    <li id="MasterMediaCategoryMasterMediaVehicle"><i className="fas fa-tv"></i><span className="labeltext">Media Vehicle Setup</span></li>
                    <li id="MasterMediaCategoryMasterChannelType"><i className="fas fa-align-justify"></i><span className="labeltext">Channel Type Setup</span></li>
                    <li id="MasterMediaCategoryMasterManagementType"><i className="fas fa-asterisk"></i><span className="labeltext">SMBC Setup</span></li>
                    <li id="MasterMediaCategoryDefaultAgencyShare"><i className="fas fa-percent  "></i><span className="labeltext">Default Agency Share</span></li>
                    <li id="MasterMediaCategoryAdjustmentFactor"><i className="fas fa-cut"></i><span className="labeltext">Default Adjustment Factors</span></li>
                </ul>
                <div>
                    <div id="associationMasterMediaCategoryMasterSalesHouse">
                      <div className="k-block grid-details">
                        <h2>Sales Houses Setup</h2>
                        <div id="MasterSalesHouseGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryMasterRebate">
                      <div className="k-block grid-details">
                        <h2>Rebates Setup</h2>
                        <div id="MasterRebateGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryMasterMediaVehicle">
                      <div className="k-block grid-details">
                        <h2>Media Vehicle Setup</h2>
                        <div id="MasterMediaVehicleGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryMasterChannelType">
                      <div className="k-block grid-details">
                        <h2>Channel Type Setup</h2>
                        <div id="MasterChannelTypeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryMasterManagementType">
                      <div className="k-block grid-details">
                        <h2>SMBC Setup</h2>
                        <div id="MasterManagementTypeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryDefaultAgencyShare">
                      <div className="k-block grid-details">
                        <h2>Default Agency Share</h2>
                        <div id="DefaultAgencyShareGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterMediaCategoryAdjustmentFactor">
                      <div className="k-block grid-details">
                        <h2>Default Adjustment Factors</h2>
                        <div id="AdjustmentFactorGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </section>
    </div>
  );
}


