import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingRevenueForecastStreamNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingRevenueForecastStream>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'RevenueForecastStream');
  const updatedState: ITradingRevenueForecastStream = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Approved = 0;
            updatedState.Proposed = 0;
            updatedState.Pending = 100;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.RevenueForecastStream.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/RevenueForecastStream/View.js"], "initNewTradingRevenueForecastStream", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="RevenueForecastStream" className="controls-container default block">
              <h3>Create a New <b>Revenue Streams</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="RevenueForecastStreamCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="RevenueForecastStreamLabelBlockedCode">Blocked</label>
				<input id="RevenueForecastStreamCheckBlockedCode" defaultChecked name="RevenueForecastStreamCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientProfilePeriodId">Forecast Period</label><select className="" id="RevenueForecastStreamClientProfilePeriodId" name="ClientProfilePeriodId" defaultValue={state.ClientProfilePeriodId}   required data-required-msg="Forecast Period is required"  >{state.ClientProfilePeriodId && <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="RevenueStreamId">Revenue Stream</label><select className="" id="RevenueForecastStreamRevenueStreamId" name="RevenueStreamId" defaultValue={state.RevenueStreamId}   required data-required-msg="Revenue Stream is required"  >{state.RevenueStreamId && <option value={state.RevenueStreamId}>{state.RevenueStreamDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Comments">Comments</label><div><textarea className="k-input k-textbox" cols={20} id="RevenueForecastStreamComments" name="Comments" maxLength={2147483647} rows={5} defaultValue={state.Comments}></textarea></div></div>
		<div className="col-md-2" ><label htmlFor="Approved">%Approved</label><input  required data-required-msg="%Approved is required"  data-val-number="The field %Approved must be a number." id="RevenueForecastStreamApproved" name="Approved" type="text" defaultValue={state.Approved} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Pending">%Pending</label><input  required data-required-msg="%Pending is required"  data-val-number="The field %Pending must be a number." id="RevenueForecastStreamPending" name="Pending" type="text" defaultValue={state.Pending} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Proposed">%Proposed</label><input  required data-required-msg="%Proposed is required"  data-val-number="The field %Proposed must be a number." id="RevenueForecastStreamProposed" name="Proposed" type="text" defaultValue={state.Proposed} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="RevenueForecastStreamSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarRevenueForecastStream">
                <div className="left">
                  <button id="cancelRevenueForecastStreamButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveRevenueForecastStreamButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingRevenueForecastStream {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  ClientProfilePeriodId?: string,
  ClientProfilePeriodDisplayValue?: string,
  RevenueStreamId?: string,
  RevenueStreamDisplayValue?: string,
  Comments?: string,
  Approved?: number,
  Pending?: number,
  Proposed?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}


export function TradingRevenueForecastStreamDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingRevenueForecastStream>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'RevenueForecastStream');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "RevenueForecastStream", id).then(result => {
        setState(result.Value[0] as ITradingRevenueForecastStream);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.RevenueForecastStream.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/RevenueForecastStream/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.RevenueForecastSpend.en.js"
        ,"Areas/Trading/Scripts/RevenueForecastSpend/View.js"
        ], "initDetailTradingRevenueForecastStream", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingRevenueForecastStreamState'] = (data) => {
    setState(data.Value[0] as ITradingRevenueForecastStream);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="RevenueForecastStream" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Revenue Streams: <span>{state.Display}</span><span id="RevenueForecastStreamToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.ClientProfilePeriodId && <span className='element'><label>Forecast Period: <label className="strong">{state.ClientProfilePeriodDisplayValue}</label></label></span>}
                  {state.RevenueStreamId && <span className='element'><label>Revenue Stream: <label className="strong">{state.RevenueStreamDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="RevenueForecastStreamCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="RevenueForecastStreamLabelBlockedCode">Blocked</label>
				<input id="RevenueForecastStreamCheckBlockedCode" defaultChecked name="RevenueForecastStreamCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientProfilePeriodId">Forecast Period</label><select className="" id="RevenueForecastStreamClientProfilePeriodId" name="ClientProfilePeriodId" defaultValue={state.ClientProfilePeriodId}   required data-required-msg="Forecast Period is required"  >{state.ClientProfilePeriodId && <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="RevenueStreamId">Revenue Stream</label><select className="" id="RevenueForecastStreamRevenueStreamId" name="RevenueStreamId" defaultValue={state.RevenueStreamId}   required data-required-msg="Revenue Stream is required"  >{state.RevenueStreamId && <option value={state.RevenueStreamId}>{state.RevenueStreamDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Comments">Comments</label><div><textarea className="k-input k-textbox" cols={20} id="RevenueForecastStreamComments" name="Comments" maxLength={2147483647} rows={5} defaultValue={state.Comments}></textarea></div></div>
		<div className="col-md-2" ><label htmlFor="Approved">%Approved</label><input  required data-required-msg="%Approved is required"  data-val-number="The field %Approved must be a number." id="RevenueForecastStreamApproved" name="Approved" type="text" defaultValue={state.Approved} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Pending">%Pending</label><input  required data-required-msg="%Pending is required"  data-val-number="The field %Pending must be a number." id="RevenueForecastStreamPending" name="Pending" type="text" defaultValue={state.Pending} className="k-input k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="Proposed">%Proposed</label><input  required data-required-msg="%Proposed is required"  data-val-number="The field %Proposed must be a number." id="RevenueForecastStreamProposed" name="Proposed" type="text" defaultValue={state.Proposed} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="RevenueForecastStreamSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="RevenueForecastStreamSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="RevenueForecastStreamSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarRevenueForecastStream">
                <div className="left">
                  <button id="cancelRevenueForecastStreamButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveRevenueForecastStreamButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationRevenueForecastStreamRevenueForecastSpend">
              <div className="k-block grid-details">
                <h2>Revenue & Spends</h2>
                <div id="RevenueForecastSpendGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


