import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function TradingAnnualTargetCustomPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AnnualTarget');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          "Areas/Trading/Scripts/Resources/en/res.AnnualTarget.en.js",
          "Areas/Trading/Scripts/Resources/en/res.DealActivity.en.js",
          "Areas/Trading/Scripts/Resources/en/res.MasterManagementType.en.js",
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          "Areas/Trading/Scripts/Resources/en/Types.en.js",
          "Areas/Trading/Scripts/Enums.js",
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          "Areas/Trading/Scripts/AnnualTarget/Index.custom.js",
          "Areas/Trading/Scripts/AnnualTarget/Target.custom.js"],
        "initTradingAnnualTargetCustom", { appSettings: currentSecurity.profile.AppSettings.Values });
    }
  }, []);
  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Trading Spend Targets</h3>
          <div className="field yearcontrol-container">
            <label className="required">Year</label>
            <input id="inputYear" />
          </div>
          <div className="field middlecontrol-container">
            <label className="required">Media Type</label>
            <input id="inputMediaType" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">SMBC</label>
            <input id="inputManagementType" />
          </div>
          <div className="field shortcontrol-container">
            <label className="required">Reporting Period</label>
            <input id="inputDataType" />
          </div>
          <div className="field shortcontrol-container">
            <label id="labelDealActivity">Snapshot</label>
            <input id="inputDealActivity" />
          </div>
          <section className="button-toolbar">
            <div className="left"></div>
            <div className="right"><button id="applyFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="AnnualTargetContainer" className="k-content">
          <div id="AnnualTargetToolbar"></div>
          <div id="AnnualTargetSpreadsheetContainer" className="spreadsheet-container">
            <div id="AnnualTargetSpreadsheet" style={{ width: '100%' }} ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
