import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../../helpers/Utils';

export function ClientsMasterClientPage(): React.ReactElement {
  const currentSecurity = useSecurity('Clients', 'MasterClient');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(["Areas/Clients/Scripts/Resources/en/res.MasterClient.en.js",
        "Areas/Clients/Scripts/Resources/en/Types.en.js",
        "Areas/Clients/Scripts/Enums.js",
        "Areas/Clients/Scripts/MasterClient/Index.js"], "initClientsMasterClient", {});
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer"></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
