import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingDealBookNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDealBook>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealBook');
  const updatedState: ITradingDealBook = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.FiscalYear = new Date().getFullYear().toString();
            updatedState.Period = 0;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealBook.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealBook/View.js"], "initNewTradingDealBook", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="DealBook" className="controls-container default block">
              <h3>Create a New <b>Deal Book</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealBookCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="DealBookLabelBlockedCode">Blocked</label>
				<input id="DealBookCheckBlockedCode" defaultChecked name="DealBookCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMediaCategoryId">Media Type</label><select className="" id="DealBookMasterMediaCategoryId" name="MasterMediaCategoryId" defaultValue={state.MasterMediaCategoryId}   required data-required-msg="Media Type is required"  >{state.MasterMediaCategoryId && <option value={state.MasterMediaCategoryId}>{state.MasterMediaCategoryDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="DealBookNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Period" className="required">Period</label>
      <select className="" id="DealBookPeriod" name="Period" value={state.Period} required data-required-msg="Period is required" >
        <option value="0">Full Year</option>
        <option value="1">Q1</option>
        <option value="2">Q2</option>
        <option value="3">Q3</option>
        <option value="4">Q4</option>
        <option value="5">Q1 + Q2</option>
        <option value="6">Q1 - Q3</option>
        <option value="7">Q2 + Q3</option>
        <option value="8">Q2 - Q4</option>
        <option value="9">Q3 + Q4</option>
        <option value="10">M1</option>
        <option value="11">M2</option>
        <option value="12">M3</option>
        <option value="13">M4</option>
        <option value="14">M5</option>
        <option value="15">M6</option>
        <option value="16">M7</option>
        <option value="17">M8</option>
        <option value="18">M9</option>
        <option value="19">M10</option>
        <option value="20">M11</option>
        <option value="21">M12</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="DealBookFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealBookSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarDealBook">
                <div className="left">
                  <button id="cancelDealBookButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealBookButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDealBook {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  MasterMediaCategoryId?: string,
  MasterMediaCategoryDisplayValue?: string,
  Notes?: string,
  Period?: number,
  FiscalYear?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBookPeriodEnum { 'Full Year' = 0, 'Q1' = 1, 'Q2' = 2, 'Q3' = 3, 'Q4' = 4, 'Q1 + Q2' = 5, 'Q1 - Q3' = 6, 'Q2 + Q3' = 7, 'Q2 - Q4' = 8, 'Q3 + Q4' = 9, 'M1' = 10, 'M2' = 11, 'M3' = 12, 'M4' = 13, 'M5' = 14, 'M6' = 15, 'M7' = 16, 'M8' = 17, 'M9' = 18, 'M10' = 19, 'M11' = 20, 'M12' = 21 }

export function TradingDealBookDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDealBook>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealBook');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "DealBook", id).then(result => {
        setState(result.Value[0] as ITradingDealBook);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealBook.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/DealBook/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookMeeting.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookAdjustmentFactor.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookSpend.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookTarget.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookMeetingMove.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBookMakeGood.en.js"
        ,"Areas/Trading/Scripts/DealBookMeeting/View.js"
        ], "initDetailTradingDealBook", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingDealBookState'] = (data) => {
    setState(data.Value[0] as ITradingDealBook);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealBook" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Deal Book: <span>{state.Display}</span><span id="DealBookToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MasterMediaCategoryId && <span className='element'><label>Media Type: <label className="strong">{state.MasterMediaCategoryDisplayValue}</label></label></span>}
                  <span className='element'><label>Fiscal Year: <label className="strong">{state.FiscalYear}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealBookCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="DealBookLabelBlockedCode">Blocked</label>
				<input id="DealBookCheckBlockedCode" defaultChecked name="DealBookCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterMediaCategoryId">Media Type</label><select className="" id="DealBookMasterMediaCategoryId" name="MasterMediaCategoryId" defaultValue={state.MasterMediaCategoryId}   required data-required-msg="Media Type is required"  >{state.MasterMediaCategoryId && <option value={state.MasterMediaCategoryId}>{state.MasterMediaCategoryDisplayValue}</option>}</select></div>
        <div className="clear"></div>
		<div className="field textareafield"><label htmlFor="Notes">Notes</label><div><textarea className="k-input k-textbox" cols={20} id="DealBookNotes" name="Notes" maxLength={2147483647} rows={5} defaultValue={state.Notes}></textarea></div></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Period" className="required">Period</label>
      <select className="" id="DealBookPeriod" name="Period" value={state.Period} required data-required-msg="Period is required" >
        <option value="0">Full Year</option>
        <option value="1">Q1</option>
        <option value="2">Q2</option>
        <option value="3">Q3</option>
        <option value="4">Q4</option>
        <option value="5">Q1 + Q2</option>
        <option value="6">Q1 - Q3</option>
        <option value="7">Q2 + Q3</option>
        <option value="8">Q2 - Q4</option>
        <option value="9">Q3 + Q4</option>
        <option value="10">M1</option>
        <option value="11">M2</option>
        <option value="12">M3</option>
        <option value="13">M4</option>
        <option value="14">M5</option>
        <option value="15">M6</option>
        <option value="16">M7</option>
        <option value="17">M8</option>
        <option value="18">M9</option>
        <option value="19">M10</option>
        <option value="20">M11</option>
        <option value="21">M12</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="DealBookFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealBookSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="DealBookSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="DealBookSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarDealBook">
                <div className="left">
                  <button id="cancelDealBookButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealBookButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationDealBookDealBookMeeting">
              <div className="k-block grid-details">
                <h2>Meetings</h2>
                <div id="DealBookMeetingGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


