import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function BulkPanelPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'DealDefinitionInstance');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(['Report.custom.js',
          'Areas/Trading/Scripts/Resources/en/res.DealDefinitionInstance.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Admin/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/DealDefinitionInstance/BulkPanel.custom.js'],
        'initNewBulkPanel', {});
    }
  }, []);

  return (
    <div className="panelcontent-section">
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Bulk Operations</h3>
          <p>Select Filter</p>
          <div className="field fullwidth-container">
            <label>Media Type</label>
            <input id="inputMediaType" />
          </div>
          <div className="field fullwidth-container">
            <label>Agency</label>
            <input id="inputAgency" />
          </div>
          <div className="field fullwidth-container">
            <label>Client</label>
            <input id="inputClient" />
          </div>
          <div className="field fullwidth-container">
            <label>Type</label>
            <input id="inputType" />
          </div>
          <div className="field fullwidth-container">
            <label id="labelSalesHouse">Sales House</label>
            <input id="inputSalesHouse" />
          </div>
          <div className="field datecontrol-container">
            <label id="labelStartDate">Start Date</label>
            <input id="inputStartDate" />
          </div>
          <div className="field datecontrol-container">
            <label id="labelEndDate">End Date</label>
            <input id="inputEndDate" />
          </div>
          <section className="button-toolbar">
            <div className="left">                                         </div>
            <div className="right"><button id="goFilterButton" type="button" className="k-button k-primary" title="GO!">GO!</button></div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="BulkOperationPanelContainer" className="k-content">
          <div className="kpicontainer-lite" id="kpicontainer">                                     </div>
          <h4>Deals</h4>
          <div style={{ width: '100%', display: 'inline-block' }} >
            <select id="optional" title="Available" style={{ width: '40%' }} ></select>
            <select id="selected" title="Selected" style={{width: '40%'}}></select>
          </div>
          <section className="button-toolbar">
            <div className="left">
              <button id="cancelBulkOperation" type="button" className="k-button" title="Cancel">Cancel</button>
              <button id="calculateBulkOperation" type="button" className="k-button k-primary" title="Calculate">Calculate</button>
            <button id="allocateBulkOperation" type="button" className="k-button k-primary" title="Allocate">Allocate</button>
        </div>
      </section>
      <div className="process-log">
        <h4>Console log</h4>
        <div className="console"></div>
      </div>
    </div>
    </div >
</div>
  );
}
