import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function ClientsClientSetNewPage(): React.ReactElement {
  const [state, setState] = useState<IClientsClientSet>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Clients', 'ClientSet');
  const updatedState: IClientsClientSet = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.StartDate = new Date().toString();
            updatedState.EndDate = new Date().toString();
            const date = new Date();
            const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
            const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
            updatedState.StartDate = startDate.toISOString();
            updatedState.EndDate = endDate.toISOString();
            updatedState.Type = 0;
      setState(updatedState);
      appendScriptsCallback(["Areas/Clients/Scripts/Resources/en/res.ClientSet.en.js",
        "Areas/Clients/Scripts/Resources/en/Types.en.js",
        "Areas/Clients/Scripts/Enums.js",
        "Areas/Clients/Scripts/ClientSet/View.js"], "initNewClientsClientSet", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="ClientSet" className="controls-container default block">
              <h3>Create a New <b>Client Sets</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientSetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientSetLabelBlockedCode">Blocked</label>
				<input id="ClientSetCheckBlockedCode" defaultChecked name="ClientSetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ClientSetEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ClientSetEnglishName','ClientSetNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ClientSetNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ClientSetEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ClientSetEnglishShortName','ClientSetNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ClientSetNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="ClientSetType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="0">Standard</option>
        <option value="1">Like For Like</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="ClientSetStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="ClientSetEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientSetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarClientSet">
                <div className="left">
                  <button id="cancelClientSetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientSetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IClientsClientSet {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  EnglishName?: string,
  EnglishShortName?: string,
  NativeName?: string,
  NativeShortName?: string,
  Type?: number,
  StartDate?: string,
  EndDate?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ClientSetTypeEnum { 'Standard' = 0, 'Like For Like' = 1 }

export function ClientsClientSetDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IClientsClientSet>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Clients', 'ClientSet');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Clients", "ClientSet", id).then(result => {
        setState(result.Value[0] as IClientsClientSet);
        appendScriptsCallback(["Areas/Clients/Scripts/Resources/en/res.ClientSet.en.js",
        "Areas/Clients/Scripts/Resources/en/Types.en.js",
        "Areas/Clients/Scripts/Enums.js",
        "Areas/Clients/Scripts/ClientSet/View.js"
        ,"Areas/Clients/Scripts/Resources/en/res.ClientSetClient.en.js"
        ,"Areas/Clients/Scripts/ClientSetClient/View.js"
        ], "initDetailClientsClientSet", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadClientsClientSetState'] = (data) => {
    setState(data.Value[0] as IClientsClientSet);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ClientSet" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Client Sets: <span>{state.Display}</span><span id="ClientSetToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  <span className='element'><label>Type: <label className="strong">{state.Type != undefined ? ClientSetTypeEnum[state.Type] : ''}</label></label></span>
                  <span className='element'><label>Start Date: <label className="strong">{formatDate(state.StartDate)}</label></label></span>
                  <span className='element'><label>End Date: <label className="strong">{formatDate(state.EndDate)}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="ClientSetCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="ClientSetLabelBlockedCode">Blocked</label>
				<input id="ClientSetCheckBlockedCode" defaultChecked name="ClientSetCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4" ><label htmlFor="EnglishName" className="required">Name (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (EN) is required" id="ClientSetEnglishName" max="255" min="1" name="EnglishName"  required type="text" defaultValue={state.EnglishName} onChange={() => window["OnChangeReply"]('ClientSetEnglishName','ClientSetNativeName') } /></div><div className="col-md-4  " ><label htmlFor="NativeName" className="required">Name (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="Name (NT) is required" id="ClientSetNativeName" max="255" min="1" name="NativeName" required type="text" defaultValue={state.NativeName} /></div>
		<div className="col-md-4" ><label htmlFor="EnglishShortName" className="required">ShortName (EN)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (EN) is required" id="ClientSetEnglishShortName" max="50" min="1" name="EnglishShortName" required type="text" defaultValue={state.EnglishShortName} onChange={() => window["OnChangeReply"]('ClientSetEnglishShortName','ClientSetNativeShortName') } /></div><div className="col-md-4  " ><label htmlFor="NativeShortName" className="required">ShortName (NT)</label><input className="k-input k-input-solid k-textbox" data-required-msg="ShortName (NT) is required" id="ClientSetNativeShortName" max="50" min="1" name="NativeShortName" required type="text" defaultValue={state.NativeShortName} /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Type" className="required">Type</label>
      <select className="" id="ClientSetType" name="Type" value={state.Type} required data-required-msg="Type is required" >
        <option value="0">Standard</option>
        <option value="1">Like For Like</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="ClientSetStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="ClientSetEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="ClientSetSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="ClientSetSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="ClientSetSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarClientSet">
                <div className="left">
                  <button id="cancelClientSetButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveClientSetButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationClientSetClientSetClient">
              <div className="k-block grid-details">
                <h2>Clients</h2>
                <div id="ClientSetClientGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


