import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingMasterSpendNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingMasterSpend>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterSpend');
  let updatedState: ITradingMasterSpend = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.FiscalYear = new Date().getFullYear().toString();
            updatedState.BreakCriteria = 3;
            updatedState.Mode = 0;
            getPreferredDetails("Finance", "ManagementType").then((result) => {
              const resultbookedmanagementtype: any[] = result.Value;
              if (resultbookedmanagementtype && resultbookedmanagementtype.length > 0)
                updatedState = { ...updatedState, BookedManagementTypeId: resultbookedmanagementtype[0].Id, BookedManagementTypeDisplayValue: resultbookedmanagementtype[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterSpend.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterSpend/View.js"], "initNewTradingMasterSpend", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="MasterSpend" className="controls-container default block">
              <h3>Create a New <b>SMBC Spend</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterSpendCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterSpendLabelBlockedCode">Blocked</label>
				<input id="MasterSpendCheckBlockedCode" defaultChecked name="MasterSpendCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterManagementTypeId">SMBC Setup</label><select className="" id="MasterSpendMasterManagementTypeId" name="MasterManagementTypeId" defaultValue={state.MasterManagementTypeId}   required data-required-msg="SMBC Setup is required"  >{state.MasterManagementTypeId && <option value={state.MasterManagementTypeId}>{state.MasterManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MasterSpendMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BookedManagementTypeId">SMBC Book</label><select className="" id="MasterSpendBookedManagementTypeId" name="BookedManagementTypeId" defaultValue={state.BookedManagementTypeId}   required data-required-msg="SMBC Book is required"  >{state.BookedManagementTypeId && <option value={state.BookedManagementTypeId}>{state.BookedManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="DealActivityId">Data Snapshot</label><select className="" id="MasterSpendDealActivityId" name="DealActivityId" defaultValue={state.DealActivityId}  >{state.DealActivityId && <option value={state.DealActivityId}>{state.DealActivityDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="MasterSpendBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ChannelType" className="required">Channel Type</label>
      <select className="" id="MasterSpendChannelType" name="ChannelType" value={state.ChannelType} required data-required-msg="Channel Type is required" >
        <option value="0">No</option>
        <option value="1">Broadcast</option>
        <option value="2">Vod</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Mode" className="required">Mode</label>
      <select className="" id="MasterSpendMode" name="Mode" value={state.Mode} required data-required-msg="Mode is required" >
        <option value="0">With Opt-In</option>
        <option value="1">Without Opt-In</option>
        <option value="2">All</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="MasterSpendFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterSpendSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarMasterSpend">
                <div className="left">
                  <button id="cancelMasterSpendButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterSpendButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingMasterSpend {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  MasterManagementTypeId?: string,
  MasterManagementTypeDisplayValue?: string,
  MediaTypeId?: string,
  MediaTypeDisplayValue?: string,
  BookedManagementTypeId?: string,
  BookedManagementTypeDisplayValue?: string,
  DealActivityId?: string,
  DealActivityDisplayValue?: string,
  BreakCriteria?: number,
  ChannelType?: number,
  Mode?: number,
  FiscalYear?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum OptInFilterEnum { 'With Opt-In' = 0, 'Without Opt-In' = 1, 'All' = 2 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ChannelKindEnum { 'No' = 0, 'Broadcast' = 1, 'Vod' = 2 }

export function TradingMasterSpendDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingMasterSpend>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'MasterSpend');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "MasterSpend", id).then(result => {
        setState(result.Value[0] as ITradingMasterSpend);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.MasterSpend.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/MasterSpend/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.SpendOutcome.en.js"
        ,"Areas/Trading/Scripts/SpendOutcome/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.SpendIndex.en.js"
        ,"Areas/Trading/Scripts/SpendIndex/View.js"
        ], "initDetailTradingMasterSpend", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingMasterSpendState'] = (data) => {
    setState(data.Value[0] as ITradingMasterSpend);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterSpend" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>SMBC Spend: <span>{state.Display}</span><span id="MasterSpendToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.MasterManagementTypeId && <span className='element'><label>SMBC Setup: <label className="strong">{state.MasterManagementTypeDisplayValue}</label></label></span>}
                  {state.MediaTypeId && <span className='element'><label>Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label></label></span>}
                  {state.BookedManagementTypeId && <span className='element'><label>SMBC Book: <label className="strong">{state.BookedManagementTypeDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.DealActivityId && <span className='element'><label>Data Snapshot: <label className="strong">{state.DealActivityDisplayValue}</label></label></span>}
                  <span className='element'><label>Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                  <span className='element'><label>Fiscal Year: <label className="strong">{state.FiscalYear}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="MasterSpendCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="MasterSpendLabelBlockedCode">Blocked</label>
				<input id="MasterSpendCheckBlockedCode" defaultChecked name="MasterSpendCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterManagementTypeId">SMBC Setup</label><select className="" id="MasterSpendMasterManagementTypeId" name="MasterManagementTypeId" defaultValue={state.MasterManagementTypeId}   required data-required-msg="SMBC Setup is required"  >{state.MasterManagementTypeId && <option value={state.MasterManagementTypeId}>{state.MasterManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MediaTypeId">Media Type</label><select className="" id="MasterSpendMediaTypeId" name="MediaTypeId" defaultValue={state.MediaTypeId}   required data-required-msg="Media Type is required"  >{state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BookedManagementTypeId">SMBC Book</label><select className="" id="MasterSpendBookedManagementTypeId" name="BookedManagementTypeId" defaultValue={state.BookedManagementTypeId}   required data-required-msg="SMBC Book is required"  >{state.BookedManagementTypeId && <option value={state.BookedManagementTypeId}>{state.BookedManagementTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="DealActivityId">Data Snapshot</label><select className="" id="MasterSpendDealActivityId" name="DealActivityId" defaultValue={state.DealActivityId}  >{state.DealActivityId && <option value={state.DealActivityId}>{state.DealActivityDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="MasterSpendBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="ChannelType" className="required">Channel Type</label>
      <select className="" id="MasterSpendChannelType" name="ChannelType" value={state.ChannelType} required data-required-msg="Channel Type is required" >
        <option value="0">No</option>
        <option value="1">Broadcast</option>
        <option value="2">Vod</option>
      </select>
    </div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Mode" className="required">Mode</label>
      <select className="" id="MasterSpendMode" name="Mode" value={state.Mode} required data-required-msg="Mode is required" >
        <option value="0">With Opt-In</option>
        <option value="1">Without Opt-In</option>
        <option value="2">All</option>
      </select>
    </div>
		<div className="col-md-2" ><label htmlFor="FiscalYear">Fiscal Year</label><input  required data-required-msg="Fiscal Year is required"  data-val-number="The field Fiscal Year must be a number." id="MasterSpendFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="MasterSpendSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="MasterSpendSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="MasterSpendSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarMasterSpend">
                <div className="left">
                  <button id="cancelMasterSpendButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveMasterSpendButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="MasterSpendTabstrip">
                <ul>
                    <li id="MasterSpendSpendOutcome" className="k-active"><i className="fas fa-map-signs "></i><span className="labeltext">SMBC Spends</span></li>
                    <li id="MasterSpendSpendIndex"><i className="fas fa-map-signs "></i><span className="labeltext">SMBC Spend Indexes</span></li>
                </ul>
                <div>
                    <div id="associationMasterSpendSpendOutcome">
                      <div className="k-block grid-details">
                        <h2>SMBC Spends</h2>
                        <div id="SpendOutcomeGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationMasterSpendSpendIndex">
                      <div className="k-block grid-details">
                        <h2>SMBC Spend Indexes</h2>
                        <div id="SpendIndexGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </section>
    </div>
  );
}


