import React from 'react';
import axios from 'axios';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AppNavBar from './components/layout/AppNavBar';
import Dashboard from './components/layout/Dashboard';
import Welcome from './components/layout/Welcome';

import NavSidebar from './components/layout/NavSidebar';
import Notifications from './components/layout/Notifications';
import Messages from './components/layout/Messages';
import Settings from './components/layout/Settings';
import { GenericLoadingPanel } from './components/layout/panels/Panels';

import useAuth from './components/helpers/AuthHook';
import { getSecurityProfile } from './store/actions/profileActions';
import { RootState } from './store/reducer/rootReducer';
import { asyncStates } from './config/Enums';
import { SecurityUserPage } from './components/pages/admin/User/IndexPage';
import { GraphPage } from './components/pages/shared/GraphPage';
import { ReportPage } from './components/pages/shared/ReportPage';
import { DocumentFilePage } from './components/pages/shared/DocumentFilePage';
import { AppSettingsPage } from './components/pages/shared/AppSettingsPage';
import { SecurityUserTreePage } from './components/pages/shared/SecurityUserTreePage';
import { DataAdminPage } from './components/pages/shared/DataAdminPage';
import { InsightsViewerPage } from './components/pages/shared/InsightsViewerPage';
import { SecurityUserNewPage, SecurityUserDetailPage } from './components/pages/admin/User/ViewPage';
import { SecurityRolePage } from './components/pages/admin/Role/IndexPage';
import { SecurityRoleNewPage, SecurityRoleDetailPage } from './components/pages/admin/Role/ViewPage';
import { SecurityGroupPage } from './components/pages/admin/Group/IndexPage';
import { SecurityGroupNewPage, SecurityGroupDetailPage } from './components/pages/admin/Group/ViewPage';
import { IntegrationDefinitionPage } from './components/pages/admin/IntegrationDefinition/IndexPage';
import { AdminAuditPage } from './components/pages/admin/Audit/IndexPage';
import { AuditDetailPage } from './components/pages/admin/Audit/ViewPage';
import {
  IntegrationDefinitionNewPage,
  IntegrationDefinitionDetailPage,
} from './components/pages/admin/IntegrationDefinition/ViewPage';
import { IntegrationProcessDetailPage } from './components/pages/admin/IntegrationProcess/ViewPage';
import { IntegrationProcessPage } from './components/pages/admin/IntegrationProcess/IndexPage';
import { createRoutes } from './config/Route';
import { createRoutesCustom } from './config/Route.custom';
import { apiConfig, APP_CONFIG } from './config/Config';
import { callJSMethod } from './components/helpers/Utils';

const mapState = (state: RootState) => ({
  profileStatus: state.security.profileStatus,
});
const mapDispatch = { getSecurityProfile };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const App = (props: PropsFromRedux) => {
  const { isAuthenticated, user, msalUserStatus, login, logout, getAccessToken } = useAuth();
  const navigate = useNavigate();
  const isProfileLoading =
    msalUserStatus === asyncStates.pending ||
    (msalUserStatus === asyncStates.resolved &&
      [asyncStates.idle, asyncStates.pending].includes(props.profileStatus as any));

  React.useEffect(() => {
    if (!isAuthenticated) return;
    props.getSecurityProfile(getAccessToken);
    setInterval(() => {
      getAccessToken(apiConfig.scopes).then((result) => {
        if (axios.defaults.headers) axios.defaults.headers.common['Authorization'] = `Bearer ${result}`;
        callJSMethod('setaccesstoken', result);
      });
    }, 300000);
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!isAuthenticated) return;
    callJSMethod('showSpinner', true);
  }, [navigate]);

  window['loadPageReact'] = (url) => {
    callJSMethod('clearKendo', true);
    navigate(url);
  };
  return (
    <div id="main" className="app">
      <div className="modal-root"></div>
      <AppNavBar isAuthenticated={isAuthenticated} user={user} authButtonMethod={isAuthenticated ? logout : login} />
      {isAuthenticated && !isProfileLoading ? (
        <>
          <Notifications isAuthenticated={isAuthenticated} getAccessToken={getAccessToken} />
          {APP_CONFIG.ENABLEMESSAGING && <Messages isAuthenticated={isAuthenticated} user={user} />}
          <Settings isAuthenticated={isAuthenticated} user={user} onSignOut={() => logout()} />
          <NavSidebar isAuthenticated={isAuthenticated} user={user} />
          <span id="NotificationContainer"></span>
          <div id="dialogContainer"></div>
          <span id="NotificationTemplateContainer"></span>
          <section id="breadcrumb-main" className="container-fluid">
            <div id="breadcrumb-toolbar"></div>
            <div id="mainmenu-container">
              <div id="mainmenu-toolbar"></div>
            </div>
          </section>
          <Routes>
            <Route path="/" element={<Dashboard isAuthenticated={isAuthenticated} user={user} />} />
            {createRoutesCustom(isAuthenticated)}
            {createRoutes(isAuthenticated)}
            <Route
              path="/Framework/User/Index"
              element={isAuthenticated ? <SecurityUserPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/User/IndexTree"
              element={isAuthenticated ? <SecurityUserTreePage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/User/New"
              element={isAuthenticated ? <SecurityUserNewPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/User/:id"
              element={isAuthenticated ? <SecurityUserDetailPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Role/Index"
              element={isAuthenticated ? <SecurityRolePage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Role/New"
              element={isAuthenticated ? <SecurityRoleNewPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Role/:id"
              element={isAuthenticated ? <SecurityRoleDetailPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Group/Index"
              element={isAuthenticated ? <SecurityGroupPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Group/New"
              element={isAuthenticated ? <SecurityGroupNewPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Group/:id"
              element={isAuthenticated ? <SecurityGroupDetailPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/IntegrationDefinition/Index"
              element={isAuthenticated ? <IntegrationDefinitionPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/IntegrationDefinition/New"
              element={isAuthenticated ? <IntegrationDefinitionNewPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/IntegrationDefinition/:id"
              element={isAuthenticated ? <IntegrationDefinitionDetailPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/IntegrationProcess/Index"
              element={isAuthenticated ? <IntegrationProcessPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/IntegrationProcess/:id"
              element={isAuthenticated ? <IntegrationProcessDetailPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/DocumentFile/Index"
              element={isAuthenticated ? <DocumentFilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/AppSettings/Index"
              element={isAuthenticated ? <AppSettingsPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/DataAdmin/Index"
              element={isAuthenticated ? <DataAdminPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/InsightsViewer/Index"
              element={isAuthenticated ? <InsightsViewerPage /> : <Navigate to="/" />}
            />
            <Route
              path="/Framework/Audit/:bcentity"
              element={isAuthenticated ? <AdminAuditPage /> : <Navigate to="/" />}
            />
            <Route path="/Admin/Audit/:id" element={isAuthenticated ? <AuditDetailPage /> : <Navigate to="/" />} />
            <Route path="/Report/Index/:area" element={isAuthenticated ? <ReportPage /> : <Navigate to="/" />} />
            <Route path="/Graph/Index/:area" element={isAuthenticated ? <GraphPage /> : <Navigate to="/" />} />
          </Routes>
        </>
      ) : !isAuthenticated && !isProfileLoading ? (
        <Routes>
          <Route path="/" element={<Welcome login={login} />} />
        </Routes>
      ) : (
        <GenericLoadingPanel />
      )}
    </div>
  );
};

export default connector(App);
