import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingCampaignNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingCampaign>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'Campaign');
  let updatedState: ITradingCampaign = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.StartDate = new Date().toString();
            updatedState.EndDate = new Date().toString();
            const date = new Date();
            const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
            const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
            updatedState.StartDate = startDate.toISOString();
            updatedState.EndDate = endDate.toISOString();
            updatedState.BreakCriteria = 3;
            getPreferredDetails("General", "Currency").then((result) => {
              const resultcurrency: any[] = result.Value;
              if (resultcurrency && resultcurrency.length > 0)
                updatedState = { ...updatedState, CurrencyId: resultcurrency[0].Id, CurrencyDisplayValue: resultcurrency[0].Display };
            getPreferredDetails("MediaTypes", "CampaignType").then((result) => {
              const resultcampaigntype: any[] = result.Value;
              if (resultcampaigntype && resultcampaigntype.length > 0)
                updatedState = { ...updatedState, CampaignTypeId: resultcampaigntype[0].Id, CampaignTypeDisplayValue: resultcampaigntype[0].Display };
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.Campaign.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/Campaign/View.js"], "initNewTradingCampaign", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
            });
            });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="Campaign" className="controls-container default block">
              <h3>Create a New <b>Campaigns</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CampaignCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CampaignLabelBlockedCode">Blocked</label>
				<input id="CampaignCheckBlockedCode" defaultChecked name="CampaignCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CampaignTypeId">Campaign Type</label><select className="" id="CampaignCampaignTypeId" name="CampaignTypeId" defaultValue={state.CampaignTypeId}  >{state.CampaignTypeId && <option value={state.CampaignTypeId}>{state.CampaignTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProductId">Product</label><select className="" id="CampaignProductId" name="ProductId" defaultValue={state.ProductId}  >{state.ProductId && <option value={state.ProductId}>{state.ProductDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="CampaignBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="CampaignAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="CampaignClientId" name="ClientId" defaultValue={state.ClientId}   required data-required-msg="Client is required"  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="CampaignMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="CampaignName">Campaign Name</label><input  required data-required-msg="Campaign Name is required"  id="CampaignCampaignName" name="CampaignName" type="text" defaultValue={state.CampaignName} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="CampaignBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="CampaignStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="CampaignEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="CampaignCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CampaignSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarCampaign">
                <div className="left">
                  <button id="cancelCampaignButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCampaignButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingCampaign {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  CampaignTypeId?: string,
  CampaignTypeDisplayValue?: string,
  ProductId?: string,
  ProductDisplayValue?: string,
  BuyingAgencyId?: string,
  BuyingAgencyDisplayValue?: string,
  AgencyId?: string,
  AgencyDisplayValue?: string,
  ClientId?: string,
  ClientDisplayValue?: string,
  MasterClientId?: string,
  MasterClientDisplayValue?: string,
  CampaignName?: string,
  BreakCriteria?: number,
  StartDate?: string,
  EndDate?: string,
  CurrencyId?: string,
  CurrencyDisplayValue?: string,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum { 'Month' = 3, 'Quarter' = 2, 'Half Annual' = 1, 'Annual' = 0 }

export function TradingCampaignDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingCampaign>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'Campaign');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "Campaign", id).then(result => {
        setState(result.Value[0] as ITradingCampaign);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.Campaign.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/Campaign/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.CampaignMediaPlan.en.js"
        ,"Areas/Trading/Scripts/Resources/en/res.CampaignMediaSpend.en.js"
        ,"Areas/Trading/Scripts/CampaignMediaPlan/View.js"
        ], "initDetailTradingCampaign", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingCampaignState'] = (data) => {
    setState(data.Value[0] as ITradingCampaign);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Campaign" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Campaigns: <span>{state.Display}</span><span id="CampaignToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.CampaignTypeId && <span className='element'><label>Campaign Type: <label className="strong">{state.CampaignTypeDisplayValue}</label></label></span>}
                  {state.ProductId && <span className='element'><label>Product: <label className="strong">{state.ProductDisplayValue}</label></label></span>}
                  {state.BuyingAgencyId && <span className='element'><label>Buying Agency: <label className="strong">{state.BuyingAgencyDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  {state.AgencyId && <span className='element'><label>Agency: <label className="strong">{state.AgencyDisplayValue}</label></label></span>}
                  {state.ClientId && <span className='element'><label>Client: <label className="strong">{state.ClientDisplayValue}</label></label></span>}
                  {state.MasterClientId && <span className='element'><label>Master Client: <label className="strong">{state.MasterClientDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  <span className='element'><label>Break Criteria: <label className="strong">{state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}</label></label></span>
                  <span className='element'><label>Start Date: <label className="strong">{formatDate(state.StartDate)}</label></label></span>
                  <span className='element'><label>End Date: <label className="strong">{formatDate(state.EndDate)}</label></label></span>
                </div>
                <div className='content-line'>
                  {state.CurrencyId && <span className='element'><label>Currency: <label className="strong">{state.CurrencyDisplayValue}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="CampaignCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="CampaignLabelBlockedCode">Blocked</label>
				<input id="CampaignCheckBlockedCode" defaultChecked name="CampaignCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CampaignTypeId">Campaign Type</label><select className="" id="CampaignCampaignTypeId" name="CampaignTypeId" defaultValue={state.CampaignTypeId}  >{state.CampaignTypeId && <option value={state.CampaignTypeId}>{state.CampaignTypeDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ProductId">Product</label><select className="" id="CampaignProductId" name="ProductId" defaultValue={state.ProductId}  >{state.ProductId && <option value={state.ProductId}>{state.ProductDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="BuyingAgencyId">Buying Agency</label><select className="" id="CampaignBuyingAgencyId" name="BuyingAgencyId" defaultValue={state.BuyingAgencyId}  >{state.BuyingAgencyId && <option value={state.BuyingAgencyId}>{state.BuyingAgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AgencyId">Agency</label><select className="" id="CampaignAgencyId" name="AgencyId" defaultValue={state.AgencyId}   required data-required-msg="Agency is required"  >{state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="ClientId">Client</label><select className="" id="CampaignClientId" name="ClientId" defaultValue={state.ClientId}   required data-required-msg="Client is required"  >{state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}</select></div>
		<div className="col-md-4 combobox  " ><label htmlFor="MasterClientId">Master Client</label><select className="" id="CampaignMasterClientId" name="MasterClientId" defaultValue={state.MasterClientId}  >{state.MasterClientId && <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="CampaignName">Campaign Name</label><input  required data-required-msg="Campaign Name is required"  id="CampaignCampaignName" name="CampaignName" type="text" defaultValue={state.CampaignName} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="BreakCriteria" className="required">Break Criteria</label>
      <select className="" id="CampaignBreakCriteria" name="BreakCriteria" value={state.BreakCriteria} required data-required-msg="Break Criteria is required" >
        <option value="3">Month</option>
        <option value="2">Quarter</option>
        <option value="1">Half Annual</option>
        <option value="0">Annual</option>
      </select>
    </div>
        <div className="clear"></div>
		<div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input  required data-required-msg="Start Date is required"  id="CampaignStartDate" name="StartDate"  type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input  required data-required-msg="End Date is required"  id="CampaignEndDate" name="EndDate"  type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
		<div className="col-md-4 combobox  " ><label htmlFor="CurrencyId">Currency</label><select className="" id="CampaignCurrencyId" name="CurrencyId" defaultValue={state.CurrencyId}   required data-required-msg="Currency is required"  >{state.CurrencyId && <option value={state.CurrencyId}>{state.CurrencyDisplayValue}</option>}</select></div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="CampaignSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="CampaignSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="CampaignSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarCampaign">
                <div className="left">
                  <button id="cancelCampaignButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveCampaignButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationCampaignCampaignMediaPlan">
              <div className="k-block grid-details">
                <h2>Media Plans</h2>
                <div id="CampaignMediaPlanGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


