import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingDealDefinitionInstanceCustomNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDealDefinitionInstance>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealDefinitionInstance');
  const updatedState: ITradingDealDefinitionInstance = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.DealManageById = currentSecurity.profile.UserId;
            updatedState.DealManageByDisplayValue = currentSecurity.profile.LoginName;
            updatedState.SubmitterId = currentSecurity.profile.UserId;
            updatedState.SubmitterDisplayValue = currentSecurity.profile.LoginName;
            updatedState.StartDate = new Date().toString();
            updatedState.EndDate = new Date().toString();
            updatedState.ForecastDate = new Date().toString();
            const date = new Date();
            const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
            const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
            updatedState.StartDate = startDate.toISOString();
            updatedState.EndDate = endDate.toISOString();
            updatedState.ContractStatus = 1;
            updatedState.TargetType = 0;
            updatedState.TargetScope = 4;
            updatedState.AutoInclude = 0;
            updatedState.ContributesToDealBook = 0;
            updatedState.AutoExcludeNewClient = 0;
            updatedState.ForceFiscalYear = 0;
            updatedState.EpdMet = 0;
            updatedState.DealInstanceNameBlocked = true;
            updatedState.NetworkSpend = 0;
            updatedState.AllocatedSpend = 0;
            updatedState.DealTargetSpend = 0;
            updatedState.VolumeGross = 0;
            updatedState.VolumeNet = 0;
            updatedState.RollOver = 0;
            updatedState.AdvancedMedia = 0;
            updatedState.ExcludedSpend = 0;
            updatedState.ShareRelief = 0;
            updatedState.ForecastAdjustment = 0;
            updatedState.EpdAgreed = 0;
            updatedState.EpdWeightingAgency = 0;
            updatedState.EpdWeightingClient = 0;
            updatedState.EpdMetActual = 0;
            updatedState.CarryOver = 0;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealDefinitionInstance.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        'Areas/Trading/Scripts/jquery.shared.custom.js',
        "Areas/Trading/Scripts/DealDefinitionInstance/DealTargetHelper.custom.js",
        "Areas/Trading/Scripts/DealDefinitionInstance/DealName.custom.js",
        "Areas/Trading/Scripts/Resources/en/res.DealInstanceCondition.en.js",
        "Areas/Trading/Scripts/DealInstanceCondition/View.custom.js",
        "Areas/Trading/Scripts/DealDefinitionInstance/InnerView.custom.js"], "initNewTradingDealDefinitionInstance", { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true, appSettings: currentSecurity.profile.AppSettings.Values });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="DealDefinitionInstance" className="controls-container default block">
              <h3>Create a New <b>Deal</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="DealDefinitionInstanceCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
                <div className="content-header">Target Definition</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealDefinitionId">Deal Template</label><select className="" id="DealDefinitionInstanceDealDefinitionId" name="DealDefinitionId" defaultValue={state.DealDefinitionId} required data-required-msg="Deal Template is required"  >{state.DealDefinitionId && <option value={state.DealDefinitionId}>{state.DealDefinitionDisplayValue}</option>}</select></div>
                <div className="clear"></div>

                <div className="col-md-4" ><label htmlFor="DealInstanceName">Deal Name</label><input required data-required-msg="Deal Name is required" id="DealDefinitionInstanceDealInstanceName" name="DealInstanceName" type="text" defaultValue={state.DealInstanceName} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2"><label htmlFor="DealInstanceNameBlocked">Blocked</label><input type="checkbox" id="DealDefinitionInstanceDealInstanceNameBlocked" name="DealInstanceNameBlocked" defaultChecked={state.DealInstanceNameBlocked} /></div>
                <div className="col-md-2" ><label htmlFor="SalesHouseDisplayValue">Sales House</label><input id="DealDefinitionInstanceSalesHouseDisplayValue" name="SalesHouseDisplayValue" type="text" defaultValue={''} className="k-input k-input-solid k-textbox disabled" readOnly disabled /></div>

                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="TargetType" className="required">Target Type</label>
                  <select className="" id="DealDefinitionInstanceTargetType" name="TargetType" value={state.TargetType} required data-required-msg="Target Type is required" >
                    <option value="0">Share</option>
                    <option value="1">Volume</option>
                  </select>
                </div>
                <div className="col-md-2" ><label htmlFor="DealValue">Deal Target</label><input required data-required-msg="Deal Target is required" data-val-number="The field Deal Target must be a number." id="DealDefinitionInstanceDealValue" name="DealValue" type="text" defaultValue={state.DealValue} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  rd-input" >
                  <label htmlFor="AgreementType" className="required">Agreement Type</label>
                  <select className="" id="DealDefinitionInstanceAgreementType" name="AgreementType" value={state.AgreementType} required data-required-msg="Agreement Type is required" disabled>
                    <option value="0">Pricing Agreement</option>
                    <option value="1">Share Agreement</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="ContractStatus" className="required">Deal Status</label>
                  <select className="" id="DealDefinitionInstanceContractStatus" name="ContractStatus" value={state.ContractStatus} required data-required-msg="Deal Status is required" >
                    <option value="0">Under Negotiation</option>
                    <option value="1">Negotiations Complete</option>
                    <option value="2">Contract Signed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Dummy</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist tv-input " >
                  <label htmlFor="ContributesToDealBook" className="required">Contributes to Deal Book</label>
                  <select className="" id="DealDefinitionInstanceContributesToDealBook" name="ContributesToDealBook" value={state.ContributesToDealBook} required data-required-msg="Contributes to Deal Book is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  tv-input" >
                  <label htmlFor="ForceFiscalYear" className="required">Force Fiscal Year</label>
                  <select className="" id="DealDefinitionInstanceForceFiscalYear" name="ForceFiscalYear" value={state.ForceFiscalYear} required data-required-msg="Force Fiscal Year is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 tv-input" ><label htmlFor="FiscalYear">Fiscal Year</label><input data-val-number="The field Fiscal Year must be a number." id="DealDefinitionInstanceFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoInclude" className="required">Auto-Include Spends</label>
                  <select className="" id="DealDefinitionInstanceAutoInclude" name="AutoInclude" value={state.AutoInclude} required data-required-msg="Auto-Include Spends is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoExcludeNewClient" className="required">Exclude New Clients</label>
                  <select className="" id="DealDefinitionInstanceAutoExcludeNewClient" name="AutoExcludeNewClient" value={state.AutoExcludeNewClient} required data-required-msg="Exclude New Clients is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 rd-input" ><label htmlFor="CarryOver">Carry-Over</label><input required data-required-msg="Carry-Over is required" data-val-number="The field Carry-Over must be a number." id="DealDefinitionInstanceCarryOver" name="CarryOver" type="text" defaultValue={state.CarryOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ShareRelief">Share Relief</label><input required data-required-msg="Share Relief is required" data-val-number="The field Share Relief must be a number." id="DealDefinitionInstanceShareRelief" name="ShareRelief" type="text" defaultValue={state.ShareRelief} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ContractorDiscount">Contractor Discount</label><input data-val-number="The field Contractor Discount must be a number." id="DealDefinitionInstanceContractorDiscount" name="ContractorDiscount" type="text" defaultValue={state.ContractorDiscount} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeGrossTarget">Volume Gross Target</label><input data-val-number="The field Volume Gross Target must be a number." id="DealDefinitionInstanceVolumeGrossTarget" name="VolumeGrossTarget" type="text" defaultValue={state.VolumeGrossTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeNetTarget">Volume Net Target</label><input data-val-number="The field Volume Net Target must be a number." id="DealDefinitionInstanceVolumeNetTarget" name="VolumeNetTarget" type="text" defaultValue={state.VolumeNetTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ForecastDate">Confirmed Forecast Date</label><input id="DealDefinitionInstanceForecastDate" name="ForecastDate" type="text" defaultValue={state.ForecastDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ThresholdAward">Threshold Award</label><input data-val-number="The field Threshold Award must be a number." id="DealDefinitionInstanceThresholdAward" name="ThresholdAward" type="text" defaultValue={state.ThresholdAward} className="k-input disabled k-textbox" readOnly disabled /></div>
                <div className="content-header">Fulfillment Period & Attribution</div>
                <div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input required data-required-msg="Start Date is required" id="DealDefinitionInstanceStartDate" name="StartDate" type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input required data-required-msg="End Date is required" id="DealDefinitionInstanceEndDate" name="EndDate" type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="field textareafield"><label htmlFor="DescriptionNotes">Deal Description</label><div><textarea className="k-input k-textbox" cols={20} id="DealDefinitionInstanceDescriptionNotes" name="DescriptionNotes" maxLength={2147483647} rows={5} defaultValue={state.DescriptionNotes}></textarea></div></div>
                <div className="content-header rd-container">Advanced Media Value</div>
                <div className="col-md-2 rd-input" ><label htmlFor="RollOver">Roll-Over</label><input required data-required-msg="Roll-Over is required" data-val-number="The field Roll-Over must be a number." id="DealDefinitionInstanceRollOver" name="RollOver" type="text" defaultValue={state.RollOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMedia">AMV</label><input required data-required-msg="AMV is required" data-val-number="The field AMV must be a number." id="DealDefinitionInstanceAdvancedMedia" name="AdvancedMedia" type="text" defaultValue={state.AdvancedMedia} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMediaTotal">AdvancedMediaTotal</label><input id="DealDefinitionInstanceAdvancedMediaTotal" name="AdvancedMediaTotal" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="content-header rd-container">Early Payment Discount</div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdAgreed">EPD Agreed</label><input required data-required-msg="EPD Agreed is required" data-val-number="The field EPD Agreed must be a number." id="DealDefinitionInstanceEpdAgreed" name="EpdAgreed" type="text" defaultValue={state.EpdAgreed} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingAgency">EPD Weighting GroupM</label><input required data-required-msg="EPD Weighting GroupM is required" data-val-number="The field EPD Weighting GroupM must be a number." id="DealDefinitionInstanceEpdWeightingAgency" name="EpdWeightingAgency" type="text" defaultValue={state.EpdWeightingAgency} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingClient">EPD Weighting Client</label><input required data-required-msg="EPD Weighting Client is required" data-val-number="The field EPD Weighting Client must be a number." id="DealDefinitionInstanceEpdWeightingClient" name="EpdWeightingClient" type="text" defaultValue={state.EpdWeightingClient} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdNet">EpdNet</label><input id="DealDefinitionInstanceEpdNet" name="EpdNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 dropdownlist  rd-input " >
                  <label htmlFor="EpdMet" className="required">EPD Met</label>
                  <select className="" id="DealDefinitionInstanceEpdMet" name="EpdMet" value={state.EpdMet} required data-required-msg="EPD Met is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetAgency">EpdMetAgency</label><input id="DealDefinitionInstanceEpdMetAgency" name="EpdMetAgency" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetClient">EpdMetClient</label><input id="DealDefinitionInstanceEpdMetClient" name="EpdMetClient" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>

                <div className="content-header rd-container">Forecast Adjustment</div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustment">Forecast Adjustment</label><input required data-required-msg="Forecast Adjustment is required" data-val-number="The field Forecast Adjustment must be a number." id="DealDefinitionInstanceForecastAdjustment" name="ForecastAdjustment" type="text" defaultValue={state.ForecastAdjustment} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustedPosition">ForecastAdjustedPosition</label><input id="DealDefinitionInstanceForecastAdjustedPosition" name="ForecastAdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdjustedPosition">AdjustedPosition</label><input id="DealDefinitionInstanceAdjustedPosition" name="AdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastManualAdjustments">ForecastManualAdjustments</label><input id="DealDefinitionInstanceForecastManualAdjustments" name="ForecastManualAdjustments" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ManualAdjustment">ManualAdjustment</label><input id="DealDefinitionInstanceManualAdjustment" name="ManualAdjustment" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGross">ForecastGross</label><input id="DealDefinitionInstanceForecastGross" name="ForecastGross" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGrossPercent">ForecastGrossPercent</label><input id="DealDefinitionInstanceForecastGrossPercent" name="ForecastGrossPercent" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastNet">ForecastNet</label><input id="DealDefinitionInstanceForecastNet" name="ForecastNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>

                <div className="content-header">Deal OwnerShip & Management</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealManageById">Managed By</label><select className="" id="DealDefinitionInstanceDealManageById" name="DealManageById" defaultValue={state.DealManageById} required data-required-msg="Managed By is required"  >{state.DealManageById && <option value={state.DealManageById}>{state.DealManageByDisplayValue}</option>}</select></div>
                <div className="col-md-4 combobox  " ><label htmlFor="SubmitterId">Submitter</label><select className="" id="DealDefinitionInstanceSubmitterId" name="SubmitterId" defaultValue={state.SubmitterId} disabled>{state.SubmitterId && <option value={state.SubmitterId}>{state.SubmitterDisplayValue}</option>}</select></div>

                <div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealDefinitionInstanceSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox" placeholder="SOURCE-REFERENCE" /></div>
		
                <div className="clear"></div>
                <div id="associationDealDefinitionInstanceDealInstanceCondition">
                  <div className="k-block grid-details">
                    <h2>Deal Instance Conditions <span style={{ color: "red", fontSize: "1rem", textTransform: "none" }}>(If an item below has been included, all other items are exlucded, and vice versa)</span></h2>
                    <div id="DealInstanceConditionGrid" className="grid-control"></div>
                  </div>
                </div>
		            </form>
              <section className="button-toolbar" id="ToolbarDealDefinitionInstance">
                <div className="left">
                  <button id="cancelDealDefinitionInstanceButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealDefinitionInstanceButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export function TradingDealDefinitionInstanceCustomDuplicatePage(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const sourceDealId = searchParams.get("sourceDealId") as string;
  const [state, setState] = useState<ITradingDealDefinitionInstance>({ Id: "", LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'DealDefinitionInstance');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      const fields = ["DealDefinition.MediaTypeId as MediaTypeId",
        "DealDefinition.ClientId as ClientId",
        "DealDefinition.MasterClientId as MasterClientId",
        "DealDefinition.ClientSetId as ClientSetId",
        "DealDefinition.AgencyId as AgencyId",
        "DealDefinition.BuyingAgencyId as BuyingAgencyId",
        "DealDefinition.MediaTypeId as MediaTypeId",
        "DealDefinition.CurrencyId as CurrencyId",
        "DealDefinition.SalesHouseId as SalesHouseId",
        "DealDefinition.DealType as DealType"];
      getEntityDetails("Trading", "DealDefinitionInstance", sourceDealId, fields).then(result => {
        const updatedState = result.Value[0] as ITradingDealDefinitionInstance;
        updatedState.Id = "";
        setState(updatedState);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealDefinitionInstance.en.js",
          "Areas/Trading/Scripts/Resources/en/Types.en.js",
          "Areas/Trading/Scripts/Enums.js",
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          "Areas/Trading/Scripts/DealDefinitionInstance/DealTargetHelper.custom.js",
          "Areas/Trading/Scripts/DealDefinitionInstance/DealName.custom.js",
          "Areas/Trading/Scripts/Resources/en/res.DealInstanceCondition.en.js",
          "Areas/Trading/Scripts/DealInstanceCondition/View.custom.js",
          "Areas/Trading/Scripts/DealDefinitionInstance/InnerView.custom.js"], "initNewTradingDealDefinitionInstance", { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true, appSettings: currentSecurity.profile.AppSettings.Values, sourceDealId: sourceDealId });
      });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealDefinitionInstance" className="controls-container default block">
              <h3>Create a New <b>Deal</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2" ><label>Code</label><input id="DealDefinitionInstanceCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled placeholder="AUTO-CODE" /></div>
                <div className="content-header">Target Definition</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealDefinitionId">Deal Template</label><select className="" id="DealDefinitionInstanceDealDefinitionId" name="DealDefinitionId" defaultValue={state.DealDefinitionId} required data-required-msg="Deal Template is required"  >{state.DealDefinitionId && <option value={state.DealDefinitionId}>{state.DealDefinitionDisplayValue}</option>}</select></div>
                <div className="clear"></div>

                <div className="col-md-4" ><label htmlFor="DealInstanceName">Deal Name</label><input required data-required-msg="Deal Name is required" id="DealDefinitionInstanceDealInstanceName" name="DealInstanceName" type="text" defaultValue={state.DealInstanceName} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2"><label htmlFor="DealInstanceNameBlocked">Blocked</label><input type="checkbox" id="DealDefinitionInstanceDealInstanceNameBlocked" name="DealInstanceNameBlocked" defaultChecked={state.DealInstanceNameBlocked} /></div>
                <div className="col-md-2" ><label htmlFor="SalesHouseDisplayValue">Sales House</label><input id="DealDefinitionInstanceSalesHouseDisplayValue" name="SalesHouseDisplayValue" type="text" defaultValue={''} className="k-input k-input-solid k-textbox disabled" readOnly disabled /></div>

                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="TargetType" className="required">Target Type</label>
                  <select className="" id="DealDefinitionInstanceTargetType" name="TargetType" value={state.TargetType} required data-required-msg="Target Type is required" >
                    <option value="0">Share</option>
                    <option value="1">Volume</option>
                  </select>
                </div>
                <div className="col-md-2" ><label htmlFor="DealValue">Deal Target</label><input required data-required-msg="Deal Target is required" data-val-number="The field Deal Target must be a number." id="DealDefinitionInstanceDealValue" name="DealValue" type="text" defaultValue={state.DealValue} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  rd-input" >
                  <label htmlFor="AgreementType" className="required">Agreement Type</label>
                  <select className="" id="DealDefinitionInstanceAgreementType" name="AgreementType" value={state.AgreementType} required data-required-msg="Agreement Type is required" disabled>
                    <option value="0">Pricing Agreement</option>
                    <option value="1">Share Agreement</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="ContractStatus" className="required">Deal Status</label>
                  <select className="" id="DealDefinitionInstanceContractStatus" name="ContractStatus" value={state.ContractStatus} required data-required-msg="Deal Status is required" >
                    <option value="0">Under Negotiation</option>
                    <option value="1">Negotiations Complete</option>
                    <option value="2">Contract Signed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Dummy</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist tv-input " >
                  <label htmlFor="ContributesToDealBook" className="required">Contributes to Deal Book</label>
                  <select className="" id="DealDefinitionInstanceContributesToDealBook" name="ContributesToDealBook" value={state.ContributesToDealBook} required data-required-msg="Contributes to Deal Book is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  tv-input" >
                  <label htmlFor="ForceFiscalYear" className="required">Force Fiscal Year</label>
                  <select className="" id="DealDefinitionInstanceForceFiscalYear" name="ForceFiscalYear" value={state.ForceFiscalYear} required data-required-msg="Force Fiscal Year is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 tv-input" ><label htmlFor="FiscalYear">Fiscal Year</label><input data-val-number="The field Fiscal Year must be a number." id="DealDefinitionInstanceFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoInclude" className="required">Auto-Include Spends</label>
                  <select className="" id="DealDefinitionInstanceAutoInclude" name="AutoInclude" value={state.AutoInclude} required data-required-msg="Auto-Include Spends is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoExcludeNewClient" className="required">Exclude New Clients</label>
                  <select className="" id="DealDefinitionInstanceAutoExcludeNewClient" name="AutoExcludeNewClient" value={state.AutoExcludeNewClient} required data-required-msg="Exclude New Clients is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 rd-input" ><label htmlFor="CarryOver">Carry-Over</label><input required data-required-msg="Carry-Over is required" data-val-number="The field Carry-Over must be a number." id="DealDefinitionInstanceCarryOver" name="CarryOver" type="text" defaultValue={state.CarryOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ShareRelief">Share Relief</label><input required data-required-msg="Share Relief is required" data-val-number="The field Share Relief must be a number." id="DealDefinitionInstanceShareRelief" name="ShareRelief" type="text" defaultValue={state.ShareRelief} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ContractorDiscount">Contractor Discount</label><input data-val-number="The field Contractor Discount must be a number." id="DealDefinitionInstanceContractorDiscount" name="ContractorDiscount" type="text" defaultValue={state.ContractorDiscount} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeGrossTarget">Volume Gross Target</label><input data-val-number="The field Volume Gross Target must be a number." id="DealDefinitionInstanceVolumeGrossTarget" name="VolumeGrossTarget" type="text" defaultValue={state.VolumeGrossTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeNetTarget">Volume Net Target</label><input data-val-number="The field Volume Net Target must be a number." id="DealDefinitionInstanceVolumeNetTarget" name="VolumeNetTarget" type="text" defaultValue={state.VolumeNetTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ForecastDate">Confirmed Forecast Date</label><input id="DealDefinitionInstanceForecastDate" name="ForecastDate" type="text" defaultValue={state.ForecastDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ThresholdAward">Threshold Award</label><input data-val-number="The field Threshold Award must be a number." id="DealDefinitionInstanceThresholdAward" name="ThresholdAward" type="text" defaultValue={state.ThresholdAward} className="k-input disabled k-textbox" readOnly disabled /></div>
                <div className="content-header">Fulfillment Period & Attribution</div>
                <div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input required data-required-msg="Start Date is required" id="DealDefinitionInstanceStartDate" name="StartDate" type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input required data-required-msg="End Date is required" id="DealDefinitionInstanceEndDate" name="EndDate" type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="field textareafield"><label htmlFor="DescriptionNotes">Deal Description</label><div><textarea className="k-input k-textbox" cols={20} id="DealDefinitionInstanceDescriptionNotes" name="DescriptionNotes" maxLength={2147483647} rows={5} defaultValue={state.DescriptionNotes}></textarea></div></div>
                <div className="content-header rd-container">Advanced Media Value</div>
                <div className="col-md-2 rd-input" ><label htmlFor="RollOver">Roll-Over</label><input required data-required-msg="Roll-Over is required" data-val-number="The field Roll-Over must be a number." id="DealDefinitionInstanceRollOver" name="RollOver" type="text" defaultValue={state.RollOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMedia">AMV</label><input required data-required-msg="AMV is required" data-val-number="The field AMV must be a number." id="DealDefinitionInstanceAdvancedMedia" name="AdvancedMedia" type="text" defaultValue={state.AdvancedMedia} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMediaTotal">AdvancedMediaTotal</label><input id="DealDefinitionInstanceAdvancedMediaTotal" name="AdvancedMediaTotal" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="content-header rd-container">Early Payment Discount</div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdAgreed">EPD Agreed</label><input required data-required-msg="EPD Agreed is required" data-val-number="The field EPD Agreed must be a number." id="DealDefinitionInstanceEpdAgreed" name="EpdAgreed" type="text" defaultValue={state.EpdAgreed} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingAgency">EPD Weighting GroupM</label><input required data-required-msg="EPD Weighting GroupM is required" data-val-number="The field EPD Weighting GroupM must be a number." id="DealDefinitionInstanceEpdWeightingAgency" name="EpdWeightingAgency" type="text" defaultValue={state.EpdWeightingAgency} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingClient">EPD Weighting Client</label><input required data-required-msg="EPD Weighting Client is required" data-val-number="The field EPD Weighting Client must be a number." id="DealDefinitionInstanceEpdWeightingClient" name="EpdWeightingClient" type="text" defaultValue={state.EpdWeightingClient} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdNet">EpdNet</label><input id="DealDefinitionInstanceEpdNet" name="EpdNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 dropdownlist  rd-input " >
                  <label htmlFor="EpdMet" className="required">EPD Met</label>
                  <select className="" id="DealDefinitionInstanceEpdMet" name="EpdMet" value={state.EpdMet} required data-required-msg="EPD Met is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetAgency">EpdMetAgency</label><input id="DealDefinitionInstanceEpdMetAgency" name="EpdMetAgency" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetClient">EpdMetClient</label><input id="DealDefinitionInstanceEpdMetClient" name="EpdMetClient" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>

                <div className="content-header rd-container">Forecast Adjustment</div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustment">Forecast Adjustment</label><input required data-required-msg="Forecast Adjustment is required" data-val-number="The field Forecast Adjustment must be a number." id="DealDefinitionInstanceForecastAdjustment" name="ForecastAdjustment" type="text" defaultValue={state.ForecastAdjustment} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustedPosition">ForecastAdjustedPosition</label><input id="DealDefinitionInstanceForecastAdjustedPosition" name="ForecastAdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdjustedPosition">AdjustedPosition</label><input id="DealDefinitionInstanceAdjustedPosition" name="AdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastManualAdjustments">ForecastManualAdjustments</label><input id="DealDefinitionInstanceForecastManualAdjustments" name="ForecastManualAdjustments" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ManualAdjustment">ManualAdjustment</label><input id="DealDefinitionInstanceManualAdjustment" name="ManualAdjustment" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGross">ForecastGross</label><input id="DealDefinitionInstanceForecastGross" name="ForecastGross" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGrossPercent">ForecastGrossPercent</label><input id="DealDefinitionInstanceForecastGrossPercent" name="ForecastGrossPercent" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastNet">ForecastNet</label><input id="DealDefinitionInstanceForecastNet" name="ForecastNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                
                <div className="content-header">Deal OwnerShip & Management</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealManageById">Managed By</label><select className="" id="DealDefinitionInstanceDealManageById" name="DealManageById" defaultValue={state.DealManageById} required data-required-msg="Managed By is required"  >{state.DealManageById && <option value={state.DealManageById}>{state.DealManageByDisplayValue}</option>}</select></div>
                <div className="col-md-4 combobox  " ><label htmlFor="SubmitterId">Submitter</label><select className="" id="DealDefinitionInstanceSubmitterId" name="SubmitterId" defaultValue={state.SubmitterId} disabled>{state.SubmitterId && <option value={state.SubmitterId}>{state.SubmitterDisplayValue}</option>}</select></div>

                <div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealDefinitionInstanceSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox" placeholder="SOURCE-REFERENCE" /></div>

                <div className="clear"></div>
                <div id="associationDealDefinitionInstanceDealInstanceCondition">
                  <div className="k-block grid-details">
                    <h2>Deal Instance Conditions <span style={{ color: "red", fontSize: "1rem", textTransform: "none" }}>(If an item below has been included, all other items are exlucded, and vice versa)</span></h2>
                    <div id="DealInstanceConditionGrid" className="grid-control"></div>
                  </div>
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDealDefinitionInstance">
                <div className="left">
                  <button id="cancelDealDefinitionInstanceButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
                <div className="right">
                  <button id="saveDealDefinitionInstanceButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
interface ITradingDealDefinitionInstance {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  DealDefinitionId?: string,
  DealDefinitionDisplayValue?: string,
  DealInstanceName?: string,
  DealReference?: string,
  DescriptionNotes?: string,
  AgreementType?: number,
  AutoExcludeNewClient?: number,
  AutoInclude?: number,
  ContractStatus?: number,
  ContributesToDealBook?: number,
  EpdMet?: number,
  ForceFiscalYear?: number,
  TargetScope?: number,
  TargetType?: number,
  StartDate?: string,
  EndDate?: string,
  ForecastDate?: string,
  DealManageById?: string,
  DealManageByDisplayValue?: string,
  SubmitterId?: string,
  SubmitterDisplayValue?: string,
  AdvancedMedia?: number,
  AllocatedSpend?: number,
  CarryOver?: number,
  ContractorDiscount?: number,
  DealTargetSpend?: number,
  DealValue?: number,
  EpdAgreed?: number,
  EpdMetActual?: number,
  EpdWeightingAgency?: number,
  EpdWeightingClient?: number,
  ExcludedSpend?: number,
  FiscalYear?: string,
  ForecastAdjustment?: number,
  NetworkSpend?: number,
  RollOver?: number,
  ShareRelief?: number,
  ThresholdAward?: number,
  VolumeGross?: number,
  VolumeGrossTarget?: number,
  VolumeNet?: number,
  VolumeNetTarget?: number,
  DealInstanceNameBlocked?: boolean,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
  AllocatedSpendPercent?: number,
  DealTargetSpendPercent?: number,
  Variance?: number,
  VarianceVolumeGross?: number,
  VarianceVolumeNet?: number,
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum { 'Yes' = 0, 'No' = 1 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealContractStatusEnum { 'Under Negotiation' = 0, 'Negotiations Complete' = 1, 'Contract Signed' = 2, 'Cancelled' = 3, 'Dummy' = 4 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealCalculationTypeEnum { 'Share' = 0, 'Volume' = 1 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum TargetLevelEnum { 'Major Sales House' = 0, 'Sales House' = 1, 'Channel' = 3, 'Media Vehicle Group' = 4, 'Media Vehicle' = 5 }
	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealAgreementTypeEnum { 'Pricing Agreement' = 0, 'Share Agreement' = 1 }

export function TradingDealDefinitionInstanceCustomDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDealDefinitionInstance>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'DealDefinitionInstance');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      const fields = ["DealDefinition.MediaTypeId as MediaTypeId",
        "DealDefinition.ClientId as ClientId",
        "DealDefinition.MasterClientId as MasterClientId",
        "DealDefinition.ClientSetId as ClientSetId",
        "DealDefinition.AgencyId as AgencyId",
        "DealDefinition.BuyingAgencyId as BuyingAgencyId",
        "DealDefinition.MediaTypeId as MediaTypeId",
        "DealDefinition.CurrencyId as CurrencyId",
        "DealDefinition.SalesHouseId as SalesHouseId",
        "DealDefinition.DealType as DealType"];
      getEntityDetails("Trading", "DealDefinitionInstance", id, fields).then(result => {
        setState(result.Value[0] as ITradingDealDefinitionInstance);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.DealDefinitionInstance.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
          "Areas/Trading/Scripts/Enums.js",
          "Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js",
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          "Areas/Trading/Scripts/DealDefinitionInstance/DealTargetHelper.custom.js",
          "Areas/Trading/Scripts/DealDefinitionInstance/DealName.custom.js",
        "Areas/Trading/Scripts/DealDefinitionInstance/InnerView.custom.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealCandidate.en.js"
        ,"Areas/Trading/Scripts/DealCandidate/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealBreakdown.en.js"
        ,"Areas/Trading/Scripts/DealBreakdown/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.DealInstanceCondition.en.js"
          , "Areas/Trading/Scripts/DealInstanceCondition/View.custom.js"
          ,"Areas/Trading/Scripts/DealDefinitionInstance/ClientList.custom.js"
        ], "initDetailTradingDealDefinitionInstance", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id, appSettings: currentSecurity.profile.AppSettings.Values });
      });
    }
  }, []);
  window['reloadTradingDealDefinitionInstanceState'] = (data) => {
    setState(data.Value[0] as ITradingDealDefinitionInstance);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealDefinitionInstance" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Deal: <span>{state.Display}</span><span id="DealDefinitionInstanceToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.DealDefinitionId && <span className='element'><label>Deal Template: <label className="strong">{state.DealDefinitionDisplayValue}</label></label></span>}
                  <span className='element'><label>Start Date: <label className="strong">{formatDate(state.StartDate)}</label></label></span>
                  <span className='element'><label>End Date: <label className="strong">{formatDate(state.EndDate)}</label></label></span>
                </div>
                <div className='content-line'>
                  <span className='element'><label>Confirmed Forecast Date: <label className="strong">{formatDate(state.ForecastDate)}</label></label></span>
                  {state.DealManageById && <span className='element'><label>Managed By: <label className="strong">{state.DealManageByDisplayValue}</label></label></span>}
                  {state.SubmitterId && <span className='element'><label>Submitter: <label className="strong">{state.SubmitterDisplayValue}</label></label></span>}
                </div>
                <div className='content-line'>
                  <span className='element'><label>Fiscal Year: <label className="strong">{state.FiscalYear}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
                <div className="col-md-2" ><label>Code</label><input id="DealDefinitionInstanceCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled placeholder="AUTO-CODE" /></div>
                <div className="content-header">Target Definition</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealDefinitionId">Deal Template</label><select className="" id="DealDefinitionInstanceDealDefinitionId" name="DealDefinitionId" defaultValue={state.DealDefinitionId} required data-required-msg="Deal Template is required"  >{state.DealDefinitionId && <option value={state.DealDefinitionId}>{state.DealDefinitionDisplayValue}</option>}</select></div>
                <div className="clear"></div>

                <div className="col-md-4" ><label htmlFor="DealInstanceName">Deal Name</label><input required data-required-msg="Deal Name is required" id="DealDefinitionInstanceDealInstanceName" name="DealInstanceName" type="text" defaultValue={state.DealInstanceName} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2"><label htmlFor="DealInstanceNameBlocked">Blocked</label><input type="checkbox" id="DealDefinitionInstanceDealInstanceNameBlocked" name="DealInstanceNameBlocked" defaultChecked={state.DealInstanceNameBlocked} /></div>
                <div className="col-md-2" ><label htmlFor="SalesHouseDisplayValue">Sales House</label><input id="DealDefinitionInstanceSalesHouseDisplayValue" name="SalesHouseDisplayValue" type="text" defaultValue={''} className="k-input k-input-solid k-textbox disabled" readOnly disabled /></div>

                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="TargetType" className="required">Target Type</label>
                  <select className="" id="DealDefinitionInstanceTargetType" name="TargetType" value={state.TargetType} required data-required-msg="Target Type is required" >
                    <option value="0">Share</option>
                    <option value="1">Volume</option>
                  </select>
                </div>
                <div className="col-md-2" ><label htmlFor="DealValue">Deal Target</label><input required data-required-msg="Deal Target is required" data-val-number="The field Deal Target must be a number." id="DealDefinitionInstanceDealValue" name="DealValue" type="text" defaultValue={state.DealValue} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  rd-input" >
                  <label htmlFor="AgreementType" className="required">Agreement Type</label>
                  <select className="" id="DealDefinitionInstanceAgreementType" name="AgreementType" value={state.AgreementType} required data-required-msg="Agreement Type is required" disabled>
                    <option value="0">Pricing Agreement</option>
                    <option value="1">Share Agreement</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="ContractStatus" className="required">Deal Status</label>
                  <select className="" id="DealDefinitionInstanceContractStatus" name="ContractStatus" value={state.ContractStatus} required data-required-msg="Deal Status is required" >
                    <option value="0">Under Negotiation</option>
                    <option value="1">Negotiations Complete</option>
                    <option value="2">Contract Signed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Dummy</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  tv-input " >
                  <label htmlFor="ContributesToDealBook" className="required">Contributes to Deal Book</label>
                  <select className="" id="DealDefinitionInstanceContributesToDealBook" name="ContributesToDealBook" value={state.ContributesToDealBook} required data-required-msg="Contributes to Deal Book is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  tv-input " >
                  <label htmlFor="ForceFiscalYear" className="required">Force Fiscal Year</label>
                  <select className="" id="DealDefinitionInstanceForceFiscalYear" name="ForceFiscalYear" value={state.ForceFiscalYear} required data-required-msg="Force Fiscal Year is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 tv-input" ><label htmlFor="FiscalYear">Fiscal Year</label><input data-val-number="The field Fiscal Year must be a number." id="DealDefinitionInstanceFiscalYear" name="FiscalYear" type="text" defaultValue={state.FiscalYear} className="k-input k-textbox" /></div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoInclude" className="required">Auto-Include Spends</label>
                  <select className="" id="DealDefinitionInstanceAutoInclude" name="AutoInclude" value={state.AutoInclude} required data-required-msg="Auto-Include Spends is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="AutoExcludeNewClient" className="required">Exclude New Clients</label>
                  <select className="" id="DealDefinitionInstanceAutoExcludeNewClient" name="AutoExcludeNewClient" value={state.AutoExcludeNewClient} required data-required-msg="Exclude New Clients is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 rd-input" ><label htmlFor="CarryOver">Carry-Over</label><input required data-required-msg="Carry-Over is required" data-val-number="The field Carry-Over must be a number." id="DealDefinitionInstanceCarryOver" name="CarryOver" type="text" defaultValue={state.CarryOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ShareRelief">Share Relief</label><input required data-required-msg="Share Relief is required" data-val-number="The field Share Relief must be a number." id="DealDefinitionInstanceShareRelief" name="ShareRelief" type="text" defaultValue={state.ShareRelief} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ContractorDiscount">Contractor Discount</label><input data-val-number="The field Contractor Discount must be a number." id="DealDefinitionInstanceContractorDiscount" name="ContractorDiscount" type="text" defaultValue={state.ContractorDiscount} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeGrossTarget">Volume Gross Target</label><input data-val-number="The field Volume Gross Target must be a number." id="DealDefinitionInstanceVolumeGrossTarget" name="VolumeGrossTarget" type="text" defaultValue={state.VolumeGrossTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeNetTarget">Volume Net Target</label><input data-val-number="The field Volume Net Target must be a number." id="DealDefinitionInstanceVolumeNetTarget" name="VolumeNetTarget" type="text" defaultValue={state.VolumeNetTarget} className="k-input k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ForecastDate">Confirmed Forecast Date</label><input id="DealDefinitionInstanceForecastDate" name="ForecastDate" type="text" defaultValue={state.ForecastDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="ThresholdAward">Threshold Award</label><input data-val-number="The field Threshold Award must be a number." id="DealDefinitionInstanceThresholdAward" name="ThresholdAward" type="text" defaultValue={state.ThresholdAward} className="k-input disabled k-textbox" readOnly disabled /></div>
                <div className="content-header">Fulfillment Period & Attribution</div>
                <div className="col-md-2" ><label htmlFor="StartDate">Start Date</label><input required data-required-msg="Start Date is required" id="DealDefinitionInstanceStartDate" name="StartDate" type="text" defaultValue={state.StartDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="col-md-2" ><label htmlFor="EndDate">End Date</label><input required data-required-msg="End Date is required" id="DealDefinitionInstanceEndDate" name="EndDate" type="text" defaultValue={state.EndDate} className="k-input k-input-solid k-textbox" /></div>
                <div className="field textareafield"><label htmlFor="DescriptionNotes">Deal Description</label><div><textarea className="k-input k-textbox" cols={20} id="DealDefinitionInstanceDescriptionNotes" name="DescriptionNotes" maxLength={2147483647} rows={5} defaultValue={state.DescriptionNotes}></textarea></div></div>
                <div className="content-header">Deal Status</div>
                <div className="col-md-2" ><label htmlFor="NetworkSpend">NetworkSpend</label><input id="DealDefinitionInstanceNetworkSpend" name="NetworkSpend" type="text" defaultValue={state.NetworkSpend} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2" ><label htmlFor="AllocatedSpend">AllocatedSpend</label><input id="DealDefinitionInstanceAllocatedSpend" name="AllocatedSpend" type="text" defaultValue={state.AllocatedSpend} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ExcludedSpend">ExcludedSpend</label><input id="DealDefinitionInstanceExcludedSpend" name="ExcludedSpend" type="text" defaultValue={state.ExcludedSpend} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2" ><label htmlFor="DealTargetSpend">DealTargetSpend</label><input id="DealDefinitionInstanceDealTargetSpend" name="DealTargetSpend" type="text" defaultValue={state.DealTargetSpend} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 tv-input rd-input" ><label htmlFor="AllocatedSpendPercent">AllocatedSpendPercent</label><input id="DealDefinitionInstanceAllocatedSpendPercent" name="AllocatedSpendPercent" type="text" defaultValue={state.AllocatedSpendPercent} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 tv-input" ><label htmlFor="DealTargetSpendPercent">DealTargetSpendPercent</label><input id="DealDefinitionInstanceDealTargetSpendPercent" name="DealTargetSpendPercent" type="text" defaultValue={state.DealTargetSpendPercent} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 tv-input cn-input" ><label htmlFor="Variance">Variance</label><input id="DealDefinitionInstanceVariance" name="Variance" type="text" defaultValue={state.Variance} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeGross">VolumeGross</label><input id="DealDefinitionInstanceVolumeGross" name="VolumeGross" type="text" defaultValue={state.VolumeGross} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VolumeNet">VolumeNet</label><input id="DealDefinitionInstanceVolumeNet" name="VolumeNet" type="text" defaultValue={state.VolumeNet} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VarianceVolumeGross">VarianceVolumeGross</label><input id="DealDefinitionInstanceVarianceVolumeGross" name="VarianceVolumeGross" type="text" defaultValue={state.VarianceVolumeGross} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 cn-input" ><label htmlFor="VarianceVolumeNet">VarianceVolumeNet</label><input id="DealDefinitionInstanceVarianceVolumeNet" name="VarianceVolumeNet" type="text" defaultValue={state.VarianceVolumeNet} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="content-header rd-container">Advanced Media Value</div>
                <div className="col-md-2 rd-input" ><label htmlFor="RollOver">Roll-Over</label><input required data-required-msg="Roll-Over is required" data-val-number="The field Roll-Over must be a number." id="DealDefinitionInstanceRollOver" name="RollOver" type="text" defaultValue={state.RollOver} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMedia">AMV</label><input required data-required-msg="AMV is required" data-val-number="The field AMV must be a number." id="DealDefinitionInstanceAdvancedMedia" name="AdvancedMedia" type="text" defaultValue={state.AdvancedMedia} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdvancedMediaTotal">AdvancedMediaTotal</label><input id="DealDefinitionInstanceAdvancedMediaTotal" name="AdvancedMediaTotal" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="content-header rd-container">Early Payment Discount</div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdAgreed">EPD Agreed</label><input required data-required-msg="EPD Agreed is required" data-val-number="The field EPD Agreed must be a number." id="DealDefinitionInstanceEpdAgreed" name="EpdAgreed" type="text" defaultValue={state.EpdAgreed} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingAgency">EPD Weighting GroupM</label><input required data-required-msg="EPD Weighting GroupM is required" data-val-number="The field EPD Weighting GroupM must be a number." id="DealDefinitionInstanceEpdWeightingAgency" name="EpdWeightingAgency" type="text" defaultValue={state.EpdWeightingAgency} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdWeightingClient">EPD Weighting Client</label><input required data-required-msg="EPD Weighting Client is required" data-val-number="The field EPD Weighting Client must be a number." id="DealDefinitionInstanceEpdWeightingClient" name="EpdWeightingClient" type="text" defaultValue={state.EpdWeightingClient} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdNet">EpdNet</label><input id="DealDefinitionInstanceEpdNet" name="EpdNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input dropdownlist  " >
                  <label htmlFor="EpdMet" className="required">EPD Met</label>
                  <select className="" id="DealDefinitionInstanceEpdMet" name="EpdMet" value={state.EpdMet} required data-required-msg="EPD Met is required" >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetAgency">EpdMetAgency</label><input id="DealDefinitionInstanceEpdMetAgency" name="EpdMetAgency" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="EpdMetClient">EpdMetClient</label><input id="DealDefinitionInstanceEpdMetClient" name="EpdMetClient" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>

                <div className="content-header rd-container">Forecast Adjustment</div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustment">Forecast Adjustment</label><input required data-required-msg="Forecast Adjustment is required" data-val-number="The field Forecast Adjustment must be a number." id="DealDefinitionInstanceForecastAdjustment" name="ForecastAdjustment" type="text" defaultValue={state.ForecastAdjustment} className="k-input k-textbox" /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastAdjustedPosition">ForecastAdjustedPosition</label><input id="DealDefinitionInstanceForecastAdjustedPosition" name="ForecastAdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="AdjustedPosition">AdjustedPosition</label><input id="DealDefinitionInstanceAdjustedPosition" name="AdjustedPosition" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastManualAdjustments">ForecastManualAdjustments</label><input id="DealDefinitionInstanceForecastManualAdjustments" name="ForecastManualAdjustments" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ManualAdjustment">ManualAdjustment</label><input id="DealDefinitionInstanceManualAdjustment" name="ManualAdjustment" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGross">ForecastGross</label><input id="DealDefinitionInstanceForecastGross" name="ForecastGross" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastGrossPercent">ForecastGrossPercent</label><input id="DealDefinitionInstanceForecastGrossPercent" name="ForecastGrossPercent" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>
                <div className="col-md-2 rd-input" ><label htmlFor="ForecastNet">ForecastNet</label><input id="DealDefinitionInstanceForecastNet" name="ForecastNet" type="text" defaultValue={0} className="k-input k-textbox disabled" readOnly disabled /></div>

                <div className="content-header">Deal OwnerShip & Management</div>
                <div className="col-md-4 combobox  " ><label htmlFor="DealManageById">Managed By</label><select className="" id="DealDefinitionInstanceDealManageById" name="DealManageById" defaultValue={state.DealManageById} required data-required-msg="Managed By is required"  >{state.DealManageById && <option value={state.DealManageById}>{state.DealManageByDisplayValue}</option>}</select></div>
                <div className="col-md-4 combobox  " ><label htmlFor="SubmitterId">Submitter</label><select className="" id="DealDefinitionInstanceSubmitterId" name="SubmitterId" defaultValue={state.SubmitterId} disabled >{state.SubmitterId && <option value={state.SubmitterId}>{state.SubmitterDisplayValue}</option>}</select></div>

                <div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="DealDefinitionInstanceSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox" placeholder="SOURCE-REFERENCE" /></div>
		            <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="DealDefinitionInstanceSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="DealDefinitionInstanceSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarDealDefinitionInstance">
                <div className="left">
                  <button id="cancelDealDefinitionInstanceButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveDealDefinitionInstanceButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="DealDefinitionInstanceTabstrip">
            <ul>
              <li id="DealDefinitionInstanceDealInstanceCondition" className="k-active"><i className="fas fa-sitemap"></i><span className="labeltext">Deal Instance Conditions</span></li>

              <li id="DealDefinitionInstanceDealCandidate"><i className="fas fa-calendar-alt"></i><span className="labeltext">Candidates Spends</span></li>
              <li id="DealDefinitionInstanceDealBreakdown"><i className="fas fa-calculator"></i><span className="labeltext">Breakdown</span></li>
              <li id="DealDefinitionClientList"><i className="fas fa-user-friends"></i><span className="labeltext">Client List</span></li>
            </ul>
            <div>
              <div id="associationDealDefinitionInstanceDealInstanceCondition">
                <div className="k-block grid-details">
                  <h2>Deal Instance Conditions <span style={{ color: "red", fontSize: "1rem", textTransform: "none" }}>(If an item below has been included, all other items are exlucded, and vice versa)</span></h2>
                  <div id="DealInstanceConditionGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
                <div>
                    <div id="associationDealDefinitionInstanceDealCandidate">
                      <div className="k-block grid-details">
                        <h2>Candidates Spends</h2>
                        <div id="DealCandidateGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
                <div>
                    <div id="associationDealDefinitionInstanceDealBreakdown">
                      <div className="k-block grid-details">
                        <h2>Breakdown</h2>
                        <div id="DealBreakdownGrid" className="grid-control"></div>
                      </div>
                    </div>
                </div>
            <div>
              <div id="associationDealDefinitionInstanceClientList">
                <div className="k-block grid-details">
                  <h2>Client List</h2>
                  <div id="DealDefaultClientListGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            </div>
        </section>
      </section>
    </div>
  );
}


