import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationTriangle,
  faSignInAlt,
  faLifeRing,
  faBell,
  faEnvelope,
  faSignOutAlt,
  faHome,
  faCalculator,
  faSlidersH,
  faFileAlt,
  faTable,
  faUsers,
  faBroadcastTower,
  faWrench,
  faFolderTree,
  faListOl,
  faChartPie,
  faCheckCircle,
  faUser,
  faChartBar,
  faBoxes,
  faFileText,
  faSitemap,
  faAddressBook,
  faFlag,
  faShoppingCart,
  faShoppingBasket,
  faShoppingBag,
  faGlobe,
  faDollarSign,
  faSignLanguage,
  faWifi,
  faRss,
  faHandshake,
  faChalkboard,
  faChartLine,
  faHandPaper,
  faCalendarCheck,
  faBookmark,
  faBalanceScale,
  faSignal,
  faFileExcel,
  faUniversalAccess,
  faChessBoard,
  faUserCircle,
  faCloud,
  faTachometerAlt,
  faBinoculars,
  faSearch,
  faPrint,
  faCompass,
  faCity,
  faWarehouse,
  faArchive,
  faBusinessTime,
  faChalkboardTeacher,
  faUserTie,
  faImages,
  faChartArea,
  faLaptop,
  faFolder,
  faCubes,
  faAsterisk,
  faCar,
  faBriefcase,
  faBullhorn,
  faInfo,
  faMapSigns,
  faRoad,
  faStreetView,
  faMapPin,
  faMap,
  faEuroSign,
  faPassport,
  faDesktop,
  faCog,
  faInfoCircle,
  faExclamationCircle
}
from '@fortawesome/free-solid-svg-icons';

import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
const loadedIcons: string[] = [];
const iconMap = {
  'exclamation-triangle': faExclamationTriangle,
  'sign-in-alt': faSignInAlt,
  'life-ring': faLifeRing,
  bell: faBell,
  envelope: faEnvelope,
  'far,bell-slash': faBellSlash,
  'sign-out-alt': faSignOutAlt,
  home: faHome,
  calculator: faCalculator,
  'sliders-h': faSlidersH,
  'file-alt': faFileAlt,
  table: faTable,
  users: faUsers,
  'broadcast-tower': faBroadcastTower,
  wrench: faWrench,
  'folder-tree': faFolderTree,
  'list-ol': faListOl,
  'chart-pie': faChartPie,
  'check-circle': faCheckCircle,
  user: faUser,
  'chart-bar': faChartBar,
  boxes: faBoxes,
  'file-text': faFileText,
  sitemap: faSitemap,
  'address-book': faAddressBook,
  'flag': faFlag,
  'shopping-cart': faShoppingCart,
  'shopping-basket': faShoppingBasket,
  'shopping-bag': faShoppingBag,
  'globe': faGlobe,
  'dollar-sign': faDollarSign,
  'sign-language': faSignLanguage,
  'wifi': faWifi,
  'rss': faRss,
  handshake             :faHandshake,
  chalkboard            :faChalkboard,
  'chart-line'           :faChartLine,
  'hand-paper'          :faHandPaper,
  'calendar-check'      :faCalendarCheck,
  bookmark              :faBookmark,
  'balance-scale'       :faBalanceScale,
  signal                :faSignal,
  'file-excel'          :faFileExcel,
  'universal-access'    :faUniversalAccess,
  'chess-board'         :faChessBoard,
  'user-circle'         :faUserCircle,
  cloud                 :faCloud,
  'tachometer-alt'      :faTachometerAlt,
  binoculars            :faBinoculars,
  search: faSearch,
  'print': faPrint,
  'compass': faCompass,
  'city': faCity,
  'warehouse': faWarehouse,
  'archive': faArchive,
  'business-time': faBusinessTime,
  'chalkboard-teacher': faChalkboardTeacher,
  'user-tie': faUserTie,
  'images': faImages,
  'chart-area': faChartArea,
  'laptop': faLaptop,
  'folder': faFolder,
  'cubes': faCubes,
  'asterisk': faAsterisk,
  'car': faCar,
  'briefcase': faBriefcase,
  'bullhorn': faBullhorn,
  'info': faInfo,
  'map-signs': faMapSigns,
  'road': faRoad,
  'street-view': faStreetView,
  'map-pin': faMapPin,
  'map': faMap,
  'euro-sign': faEuroSign,
  'passport': faPassport,
  'desktop': faDesktop,
  'cog': faCog,
  'info-circle': faInfoCircle,
  'exclamation-circle': faExclamationCircle
};

const loadIcon = (name: string) => {
  if (!loadedIcons.includes(name)) {
    if (!Object.keys(iconMap).includes(name)) {
      console.log(`Tried to load unsupported icon ${name}`);
      return;
    }

    library.add(iconMap[name]);
    loadedIcons.push(name);
  }
};

const OnDemandIcon = ({ icon, ...faProps }) => {
  loadIcon(icon.toString());
  return <FontAwesomeIcon icon={icon} {...faProps} />;
};

export { OnDemandIcon };
